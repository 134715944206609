<template>
    <div class="form-group">
        <label v-if="label">{{ label }}</label>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "EeFormGroup",
    props: {
        label: String,
    }
}
</script>

<style scoped>

</style>