<template>
  <div class="card bg-white premium-component-popup border shadow">
    <div class="card-header d-flex justify-content-between align-items-center py-2 px-3">
      <div class="d-flex align-items-center">
        <h5>{{ translate(blockName, 'page-builder') }}</h5>
        <span class="badge badge-pill badge-primary align-items-center ml-2 py-1">
          {{ 'Premium' | translate('page-builder') }}
        </span>
      </div>
<!--      <button type="button" class="close" @click="$emit('close')">-->
      <button type="button" class="close">
        <span aria-hidden="true">x</span>
      </button>
    </div>
    <div class="card-body py-2 px-3">
      <span>{{ translate(`The {component} tool or one of our other premium components are only available in our plus and premium plans or can be bought as individual tool from our store.`, 'page-builder', { component: translate(blockName, 'page-builder')}) }}</span>
    </div>
    <div class="card-footer py-3 px-3">
      <button @click="$modal.show('contact_sales')" class="btn btn-primary rounded-pill">{{ 'Contact our sales team' | translate('page-builder') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PremiumComponentPopup',
  props: {
    blockName: {
      required: true,
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.premium-component-popup {
  width: 300px;
  text-align: start;

  h5 {
    font-size: 1rem;
    line-height: 1.5;
  }

  .card-body {
    font-size: 12px;
  }
}
</style>