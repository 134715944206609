<template>
  <div class="cms-block cms-block-whitespace"></div>
</template>

<script>

export default {
  craft: {
    defaultProps:{
      elementStyle: {
        "height": '50px',
      }
    }
  },
};
</script>