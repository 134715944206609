<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab selected>
        <div class="row">
          <div class="col-12">
            <craft-select
              :options="layoutOptions"
              :value="getSetting('layout')"
              label="Choose layout"
              @input="setSetting('layout', $event)"
            />
          </div>
          <transition name="fade">
            <div class="col-12" v-if="getSetting('layout') === 'widget-with-comments'">
              <craft-select
                :options="columnsOptions"
                :value="getSetting('columns')"
                label="Columns"
                @input="setSetting('columns', $event)"
              />
            </div>
          </transition>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>

<script>
import CraftSelect from "@/modules/pagebuilder/components/craft-inputs/CraftSelect.vue"
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"

export default {
  name: "GoogleReviewsSettings",
  components: { CraftSelect },
  mixins: [CraftSettingsMixin],
  computed: {
    layoutOptions() {
      return [
        { key: 'widget', label: 'Widget' },
        { key: 'widget-with-comments', label: 'Widget with comments' },
        { key: 'carousel', label: 'Carousel' },
      ]
    },
    columnsOptions() {
      return [
        { key: '1', label: '1' },
        { key: '2', label: '2' },
        { key: '3', label: '3' },
      ]
    },
  },
}
</script>
