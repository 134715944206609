<template>
  <div class="cms-block cms-block-accordion">

    <component-style :value="componentStyle"/>

    <div :id="accordionId" :style="wrapperStyle">

      <div v-for="(item, itemIndex) in getSetting('items')" class="card mb-3">
        <div class="card-header" :id="headingId(itemIndex)">
          <h5 class="mb-0">
            <button class="btn accordion-button d-flex align-items-center w-100" data-toggle="collapse" :data-target="'#'+collapseId(itemIndex)" :aria-expanded="!item.collapsed === 1 ? 'true' : 'false'" :aria-controls="collapseId(itemIndex)" :class="{collapsed: item.collapsed === 1}">
                <template v-if="getSetting('icon') && getSetting('icon_alignment') === 'left'">
                  <i class="mr-auto fa" :class="iconClassUncollapsed"></i>
                  <i class="mr-auto fa collapsed" :class="iconClassCollapsed"></i>
                </template>
                <div :class="`text-${getSetting('title_alignment')}`">{{ item.title }}</div>
                <template v-if="getSetting('icon') && getSetting('icon_alignment') === 'right'">
                  <i class="ml-auto fa" :class="iconClassUncollapsed"></i>
                  <i class="ml-auto fa collapsed" :class="iconClassCollapsed"></i>
                </template>
            </button>
          </h5>
        </div>

        <div :id="collapseId(itemIndex)" class="collapse" :aria-labelledby="headingId(itemIndex)" :data-parent="'#'+accordionId" :class="{show: item.collapsed === 0}">
          <div class="card-body" v-html="item.content"></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import ComponentStyle from "@/modules/pagebuilder/components/components/elements/components/ComponentStyle";

export default {
  name: 'AccordionElement',
  components: {ComponentStyle},
  mixins:[
    CraftSettingsMixin,
  ],
  inject: ['node'],
  craft: {
    defaultProps:{
      settings:{
        items: [
          {
            title: 'Lorem ipsum',
            content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
            collapsed: 1,
            position: 1
          },
          {
            title: 'Lorem ipsum',
            content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
            collapsed: 1,
            position: 2
          }
        ],

        // Layout
        type: 'rounded',
        width: '100%',
        alignment: 'left',
        title_alignment: 'left',
        icon_alignment: 'right',

        // Styling
        background_color: '',
        color: '',
        font_size: '',
        border_style: '',
        border_width: '',
        border_color: '',
        icon: 'chevron',
        icon_color: ''

      },
      elementStyle: {
        "padding-top": '15px',
        "padding-bottom": '15px',
      }
    }
  },
  data(){
    return {
      iconsMap: {
        'chevron':        {'collapsed': 'fa-chevron-down', 'uncollapsed': 'fa-chevron-up'},
        'chevron-circle': {'collapsed': 'fa-chevron-circle-down', 'uncollapsed': 'fa-chevron-circle-up'},
        'arrow':          {'collapsed': 'fa-arrow-down', 'uncollapsed': 'fa-arrow-up'},
        'arrow-circle':   {'collapsed': 'fa-arrow-circle-down', 'uncollapsed': 'fa-arrow-circle-up'},
        'plus-minus':     {'collapsed': 'fa-plus', 'uncollapsed': 'fa-minus'},
      }
    }
  },
  computed: {
    accordionId(){
      return 'accordion-'+this.node.uuid
    },
    wrapperStyle(){
      let margin, width = ''

      if(this.getSetting('alignment') === 'left'){
        margin = "0 auto 0 0"
      }else if(this.getSetting('alignment') === 'center'){
        margin = "0 auto"
      } else if(this.getSetting('alignment') === 'right'){
        margin = "0 0 0 auto"
      }

      width = this.getSetting('width')
      return {
        width: width,
        margin: margin,
      }
    },
    iconClassUncollapsed(){
      return this.iconsMap[this.getSetting('icon')]['uncollapsed']
    },
    iconClassCollapsed(){
      return this.iconsMap[this.getSetting('icon')]['collapsed']
    },
    componentStyle(){
      let styles = '';
      // type !important in place because of frontend .card-header:first-child { border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;}
      if(this.getSetting('type') === 'rounded'){
        styles += `#${this.accordionId} .card-header {border-radius: 0.25rem!important}`
      } else if(this.getSetting('type') === 'square'){
        styles += `#${this.accordionId} .card-header {border-radius: 0!important}`
      } else if(this.getSetting('type') === 'round'){
        styles += `#${this.accordionId} .card-header {border-radius: 50rem!important}`
      }

      if(this.getSetting('background_color')){
        styles += `#${this.accordionId} .card-header {background-color: ${this.getSetting('background_color')}}`
      }
      if(this.getSetting('color')){
        styles += `#${this.accordionId} .card-header button {color: ${this.getSetting('color')}}`
      }
      if(this.getSetting('font_size')){
        styles += `#${this.accordionId} .card-header button {font-size: ${this.getSetting('font_size')}}`
      }

      if(this.getSetting('border_style') && this.getSetting('border_width', 'px')){
        styles += `#${this.accordionId} .card-header {border-style: ${this.getSetting('border_style')}}`
        styles += `#${this.accordionId} .card-header {border-width: ${this.getSetting('border_width')}}`
      }

      if(this.getSetting('border_color')){
        styles += `#${this.accordionId} .card-header {border-color: ${this.getSetting('border_color')}}`
      }

      if(this.getSetting('icon_color')){
        styles += `#${this.accordionId} .card-header i.fa {color: ${this.getSetting('icon_color')}}`
      }

      if(this.getSetting('icon')){
        styles += `#${this.accordionId} .card-header .accordion-button:not(.collapsed) i.collapsed {display: none}`
        styles += `#${this.accordionId} .card-header .accordion-button.collapsed i:not(.collapsed) {display: none}`
      }

      return styles
    },
  },
  methods: {
    headingId(itemIndex){
      return 'heading-'+this.node.uuid+'-'+itemIndex
    },
    collapseId(itemIndex){
      return 'collapse-'+this.node.uuid+'-'+itemIndex
    },

    itemId(itemIndex){
      return this.node.uuid+'-'+itemIndex
    },
  }
};
</script>