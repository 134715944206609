<template>
  <component is="style" v-if="styleComputed" type="text/css">
    {{ styleComputed }}
  </component>
</template>

<script>
import {prefixCss} from "@/modules/pagebuilder/utilities/helpers";

export default {
  name: 'ComponentStyle',
  inject: ['editor', 'node'],
  props: {
    value: {
      required: true
    }
  },
  computed: {
    styleComputed(){
      if(this.value){
        return this.editor.enabled ? prefixCss(this.value) : this.value
      }
      return null
    }
  }
};
</script>