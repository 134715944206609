<template>
  <!-- table_select || table_multiselect -->

  <div>
      <table class="table table-bordered">
        <tbody>
          <tr v-for="option in data">
            <td>

              <div class="d-flex justify-content-between">
                <label :for="`attribute_enabled-${fieldProps.name}-${option.key}`">{{ entityLabel(option.value) }}</label>

                <div class="custom-control custom-switch">
                  <input
                      type="checkbox"
                      name="attribute_enabled"
                      :id="`attribute_enabled-${fieldProps.name}-${option.key}`"
                      class="custom-control-input"
                      :disabled="isDisabled"
                      :checked="valueData && valueData.includes(option.key)"
                      @change="toggleOptionValue(option.key)"
                  />

                  <label :for="`attribute_enabled-${fieldProps.name}-${option.key}`" class="custom-control-label"></label>
                </div>
              </div>

            </td>
          </tr>
        </tbody>
        <tfoot v-if="fieldProps.default != '1' && fieldProps.create_new_data_option == '1' && fieldProps.data_type === 'custom'">
          <tr>
            <td colspan="2">
              <a href="javascript:;" @click="canWrite ? createNewOptions(fieldProps) : null">
                <i class="fa fa-plus"></i> <strong>{{ translate('Create new', 'entities') }}</strong>
              </a>
            </td>
          </tr>
        </tfoot>

      </table>
  </div>

</template>

<script>

import { isVoidValue } from '@/utilities/helper'
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import FormSelectMixin from "@/modules/erp_entities/mixins/FormSelectMixin"

export default {
  name: 'TableSelectField',
  mixins: [FormFieldMixin, FormSelectMixin],
  data() {
    return {
      valueData: null,
    }
  },
  computed: {
    isDisabled(){
      return !this.canWrite
    },
  },
  created(){
    // check of type, in product variations it is already an object
    this.valueData = typeof this.value === 'string' ? JSON.parse(this.value) : this.value

    if (isVoidValue(this.valueData)) {
      this.valueData = []
    }
  },
  methods: {
    toggleOptionValue(key) {
      if (this.valueData.includes(key)) {
        this.valueData.splice(this.valueData.indexOf(key), 1);
      } else {
        this.valueData.push(key)
      }

      this.$emit('input', this.valueData)
    },
  },
}
</script>

