import ErpModel from "@/modules/erp_framework/services/ErpModel"
import { prefixCss } from "@/modules/pagebuilder/utilities/helpers"
import axios from "axios"
import { getWebsiteSettings } from "@/utilities/helpers/websiteSettings"

export default {
  data() {
    return {
      // todo array of styles
      appStyles: null,
      designOptionsStyles: null,
      // END todo array of styles
      websiteStyles: null,
      themeStyles: null,
    }
  },
  async created() {
    // _settings
    // -styles
    // this.designOptionsStyles = await this.fetchStyles({styleName: '-styles'});
    let website = await ErpModel.find('cms-websites', this.$store.state.system.scope.value)
    if(website) {
      const version = Date.now()

      // 1. APP
      const res1 = await axios.get(`https://${website.data.url}/css/app.css?v=${version}`)
      this.appStyles = prefixCss(res1.data)

      // 2. Website Design
      const res2 = await axios.get(`https://${website.data.url}/css/${website.data.url}-styles.css?v=${version}`)
      this.designOptionsStyles = prefixCss(res2.data)

      // 3. Theme
      let res3 = false
      const websiteTheme = (await getWebsiteSettings('general-cmswebsite/page_layout'))['page_layout.theme_name']
      if (websiteTheme) {
        try {
          const res3 = await axios.get(`https://${website.data.url}/css/${websiteTheme}.css?v=${version}`)
        } catch (err) {}
      }
      if(res3) {
        this.themeStyles = prefixCss(res3.data)
      }

      // 4. Custom
      this.websiteStyles = prefixCss(this.$store.state.builder.website.objectData.css)
    }
  },
  watch: {
    '$store.state.builder.website.objectData.css':{
      handler(){
        this.websiteStyles = prefixCss(this.$store.state.builder.website.objectData.css)
      },
      deep:true,
    },
  },
}
