<template>
    <div class="container mb-3" v-if="payment_providers">
        <page-title :page-title="translate('Edit ' + payment_providers.provider, 'payments')" buttons="/payment-providers">
          <template v-slot:actions>
            <button class="btn btn-primary rounded-pill" @click="save">
                {{ translate('Save changes', 'global') }}
            </button>
          </template>
        </page-title>
        <div class="card rounded bg-white mt-5">
            <div class="card-header">
                <h5>{{ payment_providers.provider }}</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>{{ translate('Name', 'payments') }}</label>
                            <input v-model="payment_providers.name" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ translate('Active for all countries', 'payments') }}:</label>
                            <div class="d-flex justify-content-between py-2">
                                <div class="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        name="enable_ship_to_all_countries"
                                        id="enable_ship_to_all_countries"
                                        class="custom-control-input"
                                        true-value="1"
                                        false-value="0"
                                        v-model="payment_providers.pay_in_all_countries"
                                    />
                                    <label for="enable_ship_to_all_countries" class="custom-control-label"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ translate('Status', 'payments') }}</label>
                            <div class="d-flex justify-content-between py-2">
                                <div class="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        name="status"
                                        id="status"
                                        class="custom-control-input"
                                        true-value="1"
                                        false-value="0"
                                        v-model="payment_providers.status"
                                    />
                                    <label for="status" class="custom-control-label"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Provider specific fields -->

                <!-- Mollie  -->
                <div class="row" v-if="payment_providers.provider === 'Mollie'">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ translate('Mollie API key', 'payments') }}</label>
                            <input class="form-control" v-model="payment_providers.mollie_api_key">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ translate('Mollie profile id', 'payments') }}</label>
                            <input class="form-control" v-model="payment_providers.mollie_profile_id">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <a href="https://www.mollie.com/dashboard/signup/11009947?lang=nl" target="_blank">{{ translate('Get Mollie credentials', 'payments') }}</a>
                    </div>
                </div>

                <!-- CmPayment  -->
                <div class="row" v-if="payment_providers.provider === 'CmPayment'">
					<div class="col-md-12">
                        <div class="form-group">
                            <label>{{ translate('CmPayment API key', 'payments') }}</label>
							<input class="form-control" v-model="payment_providers.merchant_key">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ translate('CmPayment user', 'payments') }}</label>
							<input class="form-control" v-model="payment_providers.cm_user">
                        </div>
                    </div>
					<div class="col-md-6">
                        <div class="form-group">
                            <label>{{ translate('CmPayment password', 'payments') }}</label>
							<input class="form-control" v-model="payment_providers.cm_password">
                        </div>
                    </div>
				</div>

                <!-- Bank Transfer -->
                <div class="row" v-if="payment_providers.provider === 'BankTransfer'">
                  <div class="col-md-6">
                    <ee-form-group
                        :label="translate('IBAN', 'payments')">
                      <ee-form-input v-model="payment_providers.IBAN"/>
                    </ee-form-group>
                  </div>
                  <div class="col-md-12">
                    <ee-form-group
                      :label="translate('Instructions', 'payments')">
                      <ee-form-textarea-simple v-model="payment_providers.instructions" :field-props="{translatable: 1}"/>
                    </ee-form-group>
                  </div>
                </div>
                <!-- END Provider specific fields -->

            </div>
        </div>

        <div class="card rounded bg-white" v-show="payment_providers.pay_in_all_countries == '0'">
            <div class="card-header">
                <h5>{{ translate('Countries', 'payments') }}</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <countries v-model="payment_providers.countries"></countries>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-white rounded-pill" v-if="$route.path !== '/webshops/payment-settings'"
                    @click="$router.push('/payment-providers')">
                {{ translate('Go back', 'global') }}
            </button>
            <slot v-else></slot>
            <button class="btn btn-primary rounded-pill" @click="save">
                {{ translate('Save changes', 'global') }}
            </button>
        </div>
        <div class="card bg-white rounded mt-4" v-if="paymentSlug">

              <div class="">
                <div class="list-group bg-white">
                  <div class="m-0">
                    <div class="card-header">
                      <h6 class="text-bold">{{ translate('Activate your payment methods', 'payments') }}</h6>
                    </div>
                    <ul class="list-group">

                      	<li
							v-for="(method, mi) in payment_methods" :key="mi"
							class="list-group-item general-settings-item"
							:class="mi"
						>
                        <div class="row">
                          <div class="col-md-9 payment-method-texts">
                            <img class="payment-method-img" :src="method.image.size2x">
                            <b class="payment-method-name">{{ method.name }}</b>
                          </div>
                          <div class="payment-method-switch-parent col-md-3 text-right">
                            <div class="payment-method-switch custom-control custom-switch">
                              <input type="checkbox"
                                     class="custom-control-input"
                                     :name="mi"
                                     :value="parseInt(method.status)"
                                     :id="mi"
                                     v-model="method.status"
                                     @input="updatePaymentMethod(method.id,method.status)">
                              <label class="custom-control-label" :for="mi"></label>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
        </div>
    </div>
</template>

<script>
import Countries from "../../../blockz/components/Countries"
import {EeFormGroup, EeFormInput, EeFormTextareaSimple} from "@/modules/erp_entities/components/forms";

export default {
    name: "EditPaymentMethod",
    components: {
      Countries,
      EeFormGroup, EeFormInput, EeFormTextareaSimple
    },
	  props: {
      // only in onboarding
      value: {
          required: false,
      },
      // END only in onboarding
    },
    data() {
        return {
            payment_providers: {},
            selected_condition: false,
            payment_methods: []
        }
    },
    computed: {
        conditions() {
            return [
              { key: 'package_weight', value: this.translate('Package weight', 'entities') },
              { key: 'package_value', value: this.translate('Package value', 'entities') },
              { key: 'package_qty', value: this.translate('Package quantity', 'entities') },
            ]
        },
		paymentSlug() {
			if(this.payment_providers.provider === 'Mollie')
				return 'mollie'
			if(this.payment_providers.provider === 'CmPayment')
				return 'cm-payments'
			return false
		},
    },
	async created() {
      let payment_providers = await this.erp.ext.request.axiosInstance.get(`/modules/payment-providers/${this.$route.params.id ? this.$route.params.id : this.value}`)

      this.payment_providers = payment_providers.data.data
		await this.getPaymentMethods();
    },
    methods: {
		async getPaymentMethods() {
          this.$store.state.system.isLoading = true
          this.payment_methods = []
          if(this.paymentSlug) {
            const methodsResponse = await this.erp.ext.request.axiosInstance.get(`/modules/payments/${this.paymentSlug}/get-methods`).catch(() => false)
            if(methodsResponse) {
              // todo on error data contain error message on prop=message but not array of methods
              if(!methodsResponse.data.message){
                this.payment_methods = methodsResponse.data
                this.payment_methods.map((item) => {
                  return item.status = item.status === '1' ? 1 : 0
                })
              }
            }
          }
          this.$store.state.system.isLoading = false
		},
        save() {
            this.$store.state.system.isLoading = true
            this.erp.ext.request.axiosInstance.put(`/modules/payment-providers/${this.$route.params.id}`, this.payment_providers).then(res => {
              this.$toast.requestSuccess('put', 'Payment provider')
				this.getPaymentMethods();
            }).catch(e => {
                this.$toast.error('Payment method not saved successfully!')
            })
            this.$store.state.system.isLoading = false
        },
        async updatePaymentMethod(method, oldStatus){
            const newStatus = (!oldStatus) === true ? '1' : '0'
            const methodsResponse = await this.erp.ext.request.axiosInstance.post(`/modules/payments/${this.paymentSlug}/methods/change-status/${method}/${newStatus}`)
            this.$toast.requestSuccess('put', 'Payment method') // explicit put
        },
        getInfoText(paymentMethodName){
          switch (paymentMethodName){

            case 'Credit card':
              return 'Most widely used method in the world';
              break;

            case 'iDEAL':
              return 'Most populat method in the Netherlands';
              break;

            case 'Bancontact':
              return 'Most populat method in the Netherlands';
              break;

            case 'PayPal':
              return 'The biggest online wallet worldwide';
              break;

            default:
              return '';
          }
        },
    },
}
</script>

<style scoped>
.text-bold {
  font-weight: bold;
}

.payment-method-info {
  color: #636363 !important;
  margin-bottom: 0;
}
.payment-method-img{
  margin-top: 0.2rem;
  height: 35px;
  padding-right: 15px;
}
.list-group-item{
  padding: 0.5rem 1.5rem;
}
.payment-method-switch{
  margin-right: -15px;
  top: 0.8rem;
}
.payment-method-switch-parent{
  display: table-cell;
  vertical-align: middle;
}
.payment-method-texts{
  display: table-cell;
  vertical-align: middle;
  padding-top: 0.5rem;
}
</style>
