<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true">
        <div class="row">
          <div class="col-12">
            <craft-select
                :label="'Section container'"
                :options="containerOptions"
                :value="getSetting('container_class')"
                @input="setSetting('container_class', $event)"
            />
          </div>

          <div class="col-12">
            <craft-input
                :label="'Height (leave empty for auto)'"
                :input-type="'number'"
                :suffix="'PX'"
                :value="getStyle('height', 'px')"
                @input="setStyle('height', $event, 'px')"
            />
          </div>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import CraftStyleSettingsMixin from "@/modules/pagebuilder/mixins/CraftStyleSettingsMixin";
import {CraftInput, CraftSelect} from "@/modules/pagebuilder/components/craft-inputs";

export default {
    name: 'SectionSettings',
    inject: ['node'],
    mixins: [ComponentSettingsMixin, CraftSettingsMixin, CraftStyleSettingsMixin],
    components: {
      CraftInput, CraftSelect
    },
    computed: {
      containerOptions() {
        return [
          {key: 'container', label: 'Box'},
          {key: 'container-fluid', label: 'Full width'}
        ]
      }
    }

}
</script>