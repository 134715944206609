<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true" :name="translate('Image', 'page-builder')">

        <div class="row">
          <div class="col-12">
            <craft-image-picker
                :label="'Add image'"
                :value="getAttribute('src', '')"
                @input="setImageAttributes($event)"
            />
          </div>

          <div class="col-12">
            <craft-input
              label="Size"
              input-type="range"
              min="1"
              max="100"
              :value="getStyle('width', '%')"
              @input="setStyle('width', $event, '%')"
              suffix="%"
            />
          </div>

          <div class="col-12">
            <craft-tab-pills
                :label="'Alignment'"
                :value="getStyle('margin')"
                @input="setStyle('margin', $event)"
                :options="alignmentOptions"
            />
          </div>

          <div class="col-12">
            <craft-tab-pills
              label="Layout"
              :options="imageLayoutOptions"
              :value="getSetting('imageBorderType')"
              @input="setSetting('imageBorderType', $event)"
            />
          </div>

          <transition-expand>
            <div v-if="getSetting('imageBorderType') === 'rounded'" class="col-12">
              <craft-input
                label="Border radius"
                input-type="range"
                suffix="PX"
                :min="0"
                :max="50"
                :value="getSetting('imageBorderRadius', 'px')"
                @input="setSetting('imageBorderRadius', $event, 'px')"
              />
            </div>
          </transition-expand>

          <div class="col-12">
            <craft-toggle
                :label="'Keep aspect ratio'"
                :id="'object-fit'"
                :value="getStyle('object-fit')"
                :value-on="'contain'"
                :value-off="'unset'"
                @input="setStyle('object-fit', $event)"
            />
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('SEO', 'page-builder')">

        <div class="row">
          <div class="col-12">
            <craft-input
              :label="'Title'"
              :value="getAttribute('title')"
              @input="setAttribute('title', $event)"
            />
          </div>

          <div class="col-12">
            <craft-input
                :label="'Alt Text'"
                :value="getAttribute('alt')"
                @input="setAttribute('alt', $event)"
            />
          </div>
        </div>

      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('link', 'page-builder')">
        <div class="row">

          <div class="col-12">
            <craft-input
                :label="'Link'"
                :value="getSetting('link')"
                @input="setSetting('link', $event)"
            />
          </div>


          <div class="col-md-12">
            <craft-tab-pills
                :label="'Link behavior'"
                :value="getSetting('link_target')"
                @input="setSetting('link_target', $event)"
                :options="generalLinkTargetOptions"
            />
          </div>

        </div>
      </element-settings-content-tab>

    </element-settings-content-tabs>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftStyleSettingsMixin from "@/modules/pagebuilder/mixins/CraftStyleSettingsMixin";
import CraftClassSettingsMixin from "@/modules/pagebuilder/mixins/CraftClassSettingsMixin";
import CraftAttributesMixin from "@/modules/pagebuilder/mixins/CraftAttributesMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import {CraftTabPills, CraftImagePicker, CraftToggle, CraftInput} from "@/modules/pagebuilder/components/craft-inputs";
import { basename, extname } from "path"

export default {
  mixins: [
    ComponentSettingsMixin,
    CraftStyleSettingsMixin,
    CraftClassSettingsMixin,
    CraftAttributesMixin,
    CraftSettingsMixin
  ],
  inject: ['editor'],
  name: "ImageSettings",
  components: {
    CraftInput,
    CraftToggle,
    CraftImagePicker,
    CraftTabPills
  },
  computed: {
    alignmentOptions() {
      return [
        {key: '0 auto 0 0', label: 'Left'},
        {key: '0 auto 0 auto', label: 'Center'},
        {key: '0 0 0 auto', label: 'Right'},
      ]
    },
    imageLayoutOptions(){
      return [
        { key: 'default', label: 'Default' },
        { key: 'square', label: 'Square' },
        { key: 'rounded', label: 'Rounded' },
        { key: 'round', label: 'Round' },
      ]
    },
  },
  created(){
    // required because of imageBorderRadius
    this.normalizeComponentSettings()
  },
  methods: {
    setImageAttributes(event) {
      // extracts only the filename without the extension
      const fileName = basename(event, extname(event))

      this.setAttribute('src', event)
      this.setAttribute('alt', fileName)
      this.setAttribute('title', fileName)
    },
  },
}
</script>