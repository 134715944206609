/*
 * BuilderMixin is required when using this mixin
 */

import { mapState, mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import { hasManyTranslationLocales, isEntityLocked } from "@/utilities/helper"
import { getInitialContent } from "@/modules/pagebuilder/utilities/helpers"

export default {
  async created(){
    this.showGlobalLoader()

    await this.loadCategories()

    const website = this.$store.state.system.scope.value

    await this.fetchWebsite(website)

    await this.fetchEntityCategories({entity: this.builder.meta.entity})

    await this.fetchTemplateCategories()

    await this.loadData()

    this.$store.state.system.currentEntity = this.entities?.entity_slug || null

    if(!this.isCreateMode){
      await this.loadEntity()
    } else {
      this.setInitialValues()
    }
  },
  data(){
    return {
      categories: [],
      hasListener: false,
      lockInterval: null,
    }
  },
  methods: {
    ...mapActions('builder', [
      'fetchEntity',
      'fetchEntityCategories',
      'fetchTemplateCategories',
      'fetchEntities',
      'fetchWebsite',
      'saveEntity',
      'showGlobalLoader',
    ]),
    setInitialValues() {
      // ALL Entities - Pages, Sections, Blogs, Portfolio, Sliders, Templates
      this.entity.objectData = { ...this.defaultData }
      this.entity.objectData.website_id = this.$store.state.system.scope.value

      // Pages, Sections, Blogs, Portfolios, Templates
      if(['cms-pages', 'cms-sections', 'blogs', 'portfolios', 'cms-templates'].includes(this.builder.meta.entity)){
        if(!['cms-templates'].includes(this.builder.meta.entity)){
          // Locale
          this.entity.objectData.locale = this.$store.state.system.translation_locale
        }

        // Slug, on translate page we pass the slug from the page that we want to translate
        if(typeof this.$route.query.slug !== 'undefined' && this.$route.query.slug){
          this.entity.objectData.slug = this.$route.query.slug
        }

        // this.entity.objectData.content_json can contain data if we are duplicating existing page to new one
        this.entity.objectData.content_json = this.entity.objectData.content_json || getInitialContent()
      }

      // Blogs only
      if(['blogs'].includes(this.builder.meta.entity)){
        this.entity.objectData.author = this.$store.state.system.authData.user.id
      }

      // ALL Entities - Pages, Sections, Blogs, Portfolio, Sliders, Templates
      // triggers the watcher $store.state.builder.originalState and then
      // in Sliders just hideGlobalLoader(), in the others Builder.vue->importEditorContent() which at the end calls hideGlobalLoader()
      this.$store.state.builder.originalState = this.entity.objectData
    },

    async loadEntity() {
      await this.fetchEntity({ entity: this.builder.meta.entity, id: this.builder.meta.entityId })

      if (isEntityLocked(this.entity.data)) {
        this.$toast.error({
          title: 'This {entity} is already being edited by another user.',
          translateArgs: { entity: this.entityNameTranslated },
        })
        this.$router.push(`/entity/${this.builder.meta.entity}`)
      }

      this.manageEntityLock(this.entity.data.id, true)

      // Locks the entity every 5 minutes
      this.lockInterval = setInterval(() => {
        this.manageEntityLock(this.entity.data.id, true)
      }, 5 * 60 * 1000) // 5 minutes
    },

    async manageEntityLock(entityId, lock) {
      if (typeof entityId === 'undefined') {
        return
      }

      const route = `/modules/${this.builder.meta.entity}/${entityId}`

      const data = {
        locked_by: lock ? this.$store.state.system.authData.user_id : null,
        locked_until: lock ? Date.now() + (10 * 60 * 1000) : null, // 10 minutes
      }

      await this.erp.ext.request.axiosInstance.put(route, { options: data })
        .catch(err => err)
    },

    // REQUIRES DATA OBJECT IN STATE OF COMPONENT USED
    async loadCategories(){
      // this.builder comes from BuilderMixin.js
      let categories = await this.erp.ext.request.get(
        'modules/categories',
        this.erp.ext.query()
          .where('entity', '=', this.builder.meta.entity)
          .where('type', '=', 'categories')
          .toString(),
      )
      this.categories = categories.objectData
    },
  },
  computed: {
    ...mapState('builder', ['entity', 'entities', 'autoSave']),
    ...mapGetters('builder', ['unsavedChanges']),
  },
  watch: {
    async '$route' (to, from) {
      this.showGlobalLoader()
      this.$store.state.layout.preview = false

      await this.loadData()
      if (to.params.id) {
        await this.loadEntity()
      } else {
        this.setInitialValues()
      }
    },

    /* old way, just for reference
    'builder.meta.entityId': function() {
        if(!this.$route.fullPath.includes('create')) {
        //if(!this.headerUtilities.createMode) {
            this.fetchEntity({
                entity: this.builder.meta.entity,
                id: this.builder.meta.entityId
            })
        } else {
            this.entity.objectData = {...this.defaultData}
            this.$store.state.builder.originalState = {...this.defaultData}
        }
    },*/


    'builder.meta.entity': async function () {
      await this.loadCategories()
    },
  },
  beforeRouteUpdate: beforeLeaveBuilderEntity,
  beforeRouteLeave: beforeLeaveBuilderEntity,
}

async function beforeLeaveBuilderEntity (to, from, next) {

  // todo refactor #100014
  if(this.unsavedChanges){
    if (this.autoSave) {
      await this.saveEntity()
    } else {
      const confirmResult = await this.$alert.confirmSaveChanges()
      if(confirmResult.isDismissed){
        return
      } else if(confirmResult.isConfirmed){
        await this.saveEntity()
      }
    }
  }
  // END todo refactor #100014

  if(!this.isCreateMode && !isEntityLocked(this.entity.data)){
    clearInterval(this.lockInterval)
    if (this.entity?.data?.id) {
      await this.manageEntityLock(this.entity.data.id, false)
    }
  }

  return next()
}