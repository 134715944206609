<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :name="'General' | translate('page-builder')" :selected="true">
        <div class="row">
          <div class="col-12">
            <craft-toggle
              id="enable_instagram_logo"
              label="Enable instagram logo"
              :value-off="0"
              :value-on="1"
              :value="getSetting('enable_instagram_logo')"
              @input="setSetting('enable_instagram_logo', $event)"
            />
          </div>
        </div>
        <transition name="fade">
          <div v-if="getSetting('enable_instagram_logo')" class="row">
            <div class="col-12">
              <craft-toggle
                id="show_logo_top"
                label="Show logo on top"
                :value-off="0"
                :value-on="1"
                :value="getSetting('show_logo_top')"
                @input="setSetting('show_logo_top', $event)"
              />
            </div>
          </div>
        </transition>
        <div class="row">
          <div class="col-12">
            <craft-toggle
              id="show_follow_us_button"
              label="Show follow us button"
              :value-off="0"
              :value-on="1"
              :value="getSetting('show_follow_us_button')"
              @input="setSetting('show_follow_us_button', $event)"
            />
          </div>
        </div>
        <transition name="fade">
          <div v-if="getSetting('show_follow_us_button')">
            <div class="row">
              <div class="col-12">
                <craft-input
                  label="Follow us button text"
                  :value="getSetting('follow_button_text')"
                  @input="setSetting('follow_button_text', $event)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <craft-color-picker
                  label="Background color"
                  :value="getSetting('follow_button_background_color')"
                  @input="setSetting('follow_button_background_color', $event)"
                />
              </div>
              <div class="col-6">
                <craft-color-picker
                  label="Text color"
                  :value="getSetting('follow_button_text_color')"
                  @input="setSetting('follow_button_text_color', $event)"
                />
              </div>
            </div>
          </div>
        </transition>
        <div class="row">
          <div class="col-12">
            <craft-toggle
              id="show_load_more_button"
              label="Show load more button"
              :value-off="0"
              :value-on="1"
              :value="getSetting('show_load_more_button')"
              @input="setSetting('show_load_more_button', $event)"
            />
          </div>
        </div>
        <transition name="fade">
          <div v-if="getSetting('show_load_more_button')">
            <div class="row">
              <div class="col-12">
                <craft-input
                  label="Load more button text"
                  :value="getSetting('load_more_button_text')"
                  @input="setSetting('load_more_button_text', $event)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <craft-color-picker
                  label="Background color"
                  :value="getSetting('load_more_button_background_color')"
                  @input="setSetting('load_more_button_background_color', $event)"
                />
              </div>
              <div class="col-6">
                <craft-color-picker
                  label="Text color"
                  :value="getSetting('load_more_text_color')"
                  @input="setSetting('load_more_text_color', $event)"
                />
              </div>
            </div>
          </div>
        </transition>
      </element-settings-content-tab>
      <element-settings-content-tab :name="'Layout' | translate('page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-select
              label="Choose layout"
              :options="layoutOptions"
              :value="getSetting('layout')"
              @input="setSetting('layout', $event)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <craft-input
              input-type="range"
              label="Number of items"
              min="1"
              max="40"
              :value="getSetting('items_count')"
              @input="setSetting('items_count', $event)"
            />
          </div>
          <div v-if="getSetting('layout') === 'wall'" class="col-12">
            <craft-input
              input-type="range"
              label="Number of columns"
              min="1"
              max="10"
              :value="getSetting('columns_count')"
              @input="setSetting('columns_count', $event)"
            />
          </div>
          <div class="col-12">
            <craft-input
              input-type="range"
              min="0"
              max="100"
              label="Column gap"
              suffix="PX"
              :value="getSetting('column_gap', 'px')"
              @input="setSetting('column_gap', $event, 'px')"
            />
          </div>
        </div>
        <div v-if="getSetting('layout') === 'carousel'" class="row">
          <div class="col-12">
            <craft-toggle
              id="autoplay"
              label="Autoplay"
              :value-off="0"
              :value-on="1"
              :value="getSetting('autoplay')"
              @input="setSetting('autoplay', $event)"
            />
          </div>
          <div class="col-12">
            <craft-toggle
              id="enable_navigaiton"
              label="Enable navigaiton"
              :value-off="0"
              :value-on="1"
              :value="getSetting('enable_navigation')"
              @input="setSetting('enable_navigation', $event)"
            />
          </div>
          <transition name="fade">
            <div v-if="getSetting('enable_navigation')" class="col-12">
              <craft-select
                label="Navigation type"
                :value="getSetting('navigation_type')"
                :options="navigationOptions"
                @input="setSetting('navigation_type', $event)"
              />
            </div>
          </transition>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>

<script>
import { CraftToggle, CraftInput, CraftColorPicker, CraftSelect } from "@/modules/pagebuilder/components/craft-inputs"
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import ElementSettingsContentTab from "@/modules/pagebuilder/components/element-settings/tabs/ElementSettingsContentTab.vue"
import ElementSettingsContentTabs from "@/modules/pagebuilder/components/element-settings/tabs/ElementSettingsContentTabs.vue"

export default {
  name: "InstagramFeedSettings",
  components: { CraftColorPicker, CraftInput, CraftSelect, CraftToggle, ElementSettingsContentTab, ElementSettingsContentTabs },
  mixins: [CraftSettingsMixin],
  computed: {
    layoutOptions() {
      return [
        { key: 'wall', label: 'Wall' },
        { key: 'carousel', label: 'Carousel' },
      ]
    },
    navigationOptions() {
      return [
        { key: 'arrows', label: 'Arrow' },
        { key: 'bullets', label: 'Bullets' },
      ]
    },
  },
}
</script>
