/**
 * Navigation
 */

// generate Design Navigation From Entity Settings
import { erp } from "@/modules/erp_framework"

const max_nav_items_in_column = 4

async function generateNavDesign(entity) {
  let entityData = await erp.ext.request.axiosInstance.get(`/modules/settings-groups/${entity}/design-settings`)
  let currentColumn = ['column', '1']
  let navigationObject = {
    image: "icon-erp-design",
    title: "Design",
    nav: {
      column_1: {},
    },
  }

  if (store.state.system.scope && store.state.system.scope.value) {
    entityData.data.data.forEach(setting => {
      if (Object.keys(navigationObject.nav[currentColumn.join('_')]).length === max_nav_items_in_column) {
        currentColumn[1]++
        navigationObject.nav[currentColumn.join('_')] = {}
      }

      if (!navigationObject.nav[currentColumn.join('_')][setting.name]) {
        navigationObject.nav[currentColumn.join('_')][setting.name] = { title: null, nav: [] }
      }

      Object.values(setting.groups).forEach(group => {
        navigationObject.nav[currentColumn.join('_')][setting.name].title = humanReadable(setting.name)

        navigationObject.nav[currentColumn.join('_')][setting.name].nav.push({
          title: app.translate(humanReadable(group), 'settings'),
          url: `/entity/${entity}/settings/${setting.slug}/${group}`,
        })
      })

      navigationObject.nav.column_1.design = {
        title: 'Sections',
        nav: [
          can('access', 'cms-section') && {
            title: "All sections",
            url: '/entity/cms-sections',
          },

          /* OLD WAY, TODO completely remove
          {
            title: "Footer builder",
            url: `/design/footer`
          },
          {
            title: "Banner builder",
            url: `/design/banner`
          }*/
        ],
      }

      navigationObject.nav.column_1.skitle = {
        title: 'Skitle Templates',
        nav: [
          can('access', 'cms-template') && {
            title: "Saved templates",
            url: '/entity/cms-templates',
          },
          canAll(['create', 'access', 'update', 'delete'], 'cms-template') && {
            title: 'Template Categories',
            url: '/entity/cms-templates/categories/crud',
          },
        ],
      }
    })

    // This is needed to make sure import export is always last item
    if (Object.keys(navigationObject.nav[currentColumn.join('_')]).length === max_nav_items_in_column) {
      currentColumn[1]++
      navigationObject.nav[currentColumn.join('_')] = {}
    }

    navigationObject.nav[currentColumn.join('_')]['Import / Export'] = {
      title: 'Import / Export',
      nav: [
        {
          title: 'Import Settings',
          url: `/design/import`,
        },
        {
          title: 'Export Settings',
          url: `/design/export`,
        },
      ],
    }
  }

  return navigationObject
}

// generate Apps Navigation
function generateNavApps() {

  let currentColumn = ['column', '1']
  let navigationObject = {
    image: store.state.system.theme === 'trucks' ? "icon-trucks-apps" : "icon-erp-apps",
    title: "Apps",
    nav: {
      column_1: {
        'apps': {
          title: 'Appstore',
          nav: [
            {
              title: 'Go to App Store',
              url: "/extensions", // in development
            },
            {
              title: 'Your subscription',
              url: '/apps', // in development
            },
          ],
        },
      },
    },
  }

  // hot fix because on first load auth data is not available yet
  if (store.state.system.authData === null) {
    return navigationObject
  }
  // end hot fix

  const apps = store.state.system.authData.apps

  apps.forEach(app => {
    if (app) { // check because backend return null on some companies
      let app_nav = getAppNav(app)
      if (app_nav) { // just in case check if the app exists
        if (Object.keys(navigationObject.nav[currentColumn.join('_')]).length === max_nav_items_in_column) {
          currentColumn[1]++
          navigationObject.nav[currentColumn.join('_')] = {}
        }
        navigationObject.nav[currentColumn.join('_')][app] = app_nav
      }
    }
  })

  return navigationObject
}

/*
 * Shared Navigation Items
 */
function getAppNav(app) {
  switch (app) {
  case 'Blog':
    return can('access', 'blog-post') && {
      title: 'Blog',
      app: 'Blog',
      nav: [
        {
          title: "All Blog posts",
          url: `/entity/blogs`,
        },
        can('create', 'blog-post') && {
          title: "Create new",
          url: `/builder/blogs/create`,
        },
        canAll(['create', 'access', 'update', 'delete'], 'blog-post') && {
          title: "Blog Categories",
          url: "/entity/blogs/categories/crud",
        },
        // {
        //     title: "Settings",
        //     url: "/entity/blogs/settings/0"
        // },
        canAll(['create', 'access', 'update', 'delete'], 'blog-post') && {
          title: "Settings",
          url: `/entity/cms-websites/settings/blogs-settings-cmswebsite/blogs`,
        },
      ],
    }
  case 'Portfolio':
    return can('access', 'portfolio') && {
      title: 'Portfolio',
      app: 'Portfolio',
      nav: [
        {
          title: "All Portfolio pages",
          url: `/entity/portfolios`,
        },
        can('create', 'portfolio') && {
          title: "Create new",
          url: `/builder/portfolios/create`,
        },
        canAll(['create', 'access', 'update', 'delete'], 'portfolio') && {
          title: "Portfolio Categories",
          url: "/entity/portfolios/categories/crud",
        },
        // {
        //     title: "Settings",
        //     url: "/entity/portfolios/settings/0"
        // },
        canAll(['create', 'access', 'update', 'delete'],'portfolio') && {
          title: "Settings",
          url: `/entity/cms-websites/settings/portfolios-settings-cmswebsite/portfolios`,
        },
      ],
    }
  case 'Testimonial':
    return can('access', 'testimonial') && {
      title: 'Testimonials',
      app: 'Testimonial',
      nav: [
        {
          title: "All Testimonials",
          url: `/entity/testimonials`,
        },
        can('create', 'testimonial') && {
          title: "Create new",
          url: `/builder/testimonials/create`,
        },
        canAll(['create', 'access', 'update', 'delete'], 'testimonial') && {
          title: "Testimonials Categories",
          url: "/entity/testimonials/categories/crud",
        },
        // {
        //     title: "Testimonials tags",
        //     url: "/entity/testimonials/tags/crud"
        // },
        // {
        //     title: "Settings",
        //     url: "/entity/testimonials/settings/0"
        // },
      ],
    }
  case 'BusinessToBusiness':
    return {
      title: 'Business To Business',
      app: 'BusinessToBusiness',
      nav: [
        {
          title: 'Settings',
          url: `/entity/cms-websites/settings/b2b-settings-cmswebsite`,
        },
      ],
    }
  case 'Translations':
    return {
      title: 'Translations',
      //app: 'Translations', todo show only if the app is bought
      nav: [
        {
          title: 'Settings',
          url: `/translations/settings`,
        },
      ],
    }
  case 'RequestQuote':
    return can('access', 'quote-request') && {
      title: 'Request Quote',
      nav: [
        {
          title: 'All request quotes',
          url: `/request-quotes/`,
        },
        can('access', 'quote-request') && {
          title: 'Settings',
          url: `/entity/cms-websites/settings/request-quote-settings-cmswebsite/`,
        },
      ],
    }
  case 'Contact':
    return {
      title: 'Contact',
      nav: [
        {
          title: 'Call us button',
          url: `/entity/cms-websites/settings/call-us-button-settings-cmswebsite/call_us_button`,
        },
        {
          title: 'Floating contact buttons',
          url: `/entity/cms-websites/settings/call-us-button-settings-cmswebsite/floating_contact_buttons`,
        },
      ],
    }
  case 'Sendcloud':
    return {
      title: 'Sendcloud',
      nav: [
        {
          title: 'Connect your shop',
          url: `/entity/cms-websites/settings/sendcloud-settings/sendcloud_credentials`,
        },
      ],
    }
  case 'Provision':
    return {
      title: 'Provisions',
      // app: 'Provision',
      nav: [
        {
          title: 'Settings',
          url: `/provision-settings`,
        },
      ],
    }
  case 'Slider':
    return can('access', 'slider') && {
      title: 'Sliders',
      nav: [
        {
          title: "All Sliders",
          url: `/entity/sliders`,
        },
        can('create', 'slider') && {
          title: "Create new",
          url: `/builder/sliders/create`,
        },
      ],
    }
  case 'AbandonedCart':
    return can('access', 'abandoned-cart') && {
      title: 'Abandoned cart',
      nav: [
        {
          title: 'Settings',
          url: `/entity/cms-websites/settings/financial/abandoned-carts`,
        },
      ],
    }
  case 'Brochure':
    return {
      title: 'Brochure',
      nav: [
        {
          title: 'Settings',
          url: `/entity/cms-websites/settings/brochure-settings-cmswebsite`,
        },
      ],
    }
  case 'EffectConnect':
    return {
      title: 'Effect Connect',
      nav: [
        {
          title: 'Settings',
          url: `/entity/cms-websites/settings/effect-connect-settings`,
        },
      ],
    }
  case 'GoogleShopping':
    return {
      title: 'Google Shopping',
      nav: [
        {
          title: 'Settings',
          url: `/entity/cms-websites/settings/google-shopping-settings-cmswebsite`,
        },
      ],
    }
  default:
    return false
  }
}

function generateNewsletterNav(){
  return {
    image: "icon-erp-newsletter",
    title: "Newsletter",
    nav: {
      column_1: {
        'newsletter': can('access', 'newsletter') && {
          title: 'Newsletter',
          nav: [
            {
              title: 'Subscribers',
              url: '/entity/newsletter',
            },
            can('create', 'newsletter') && {
              title: 'Create subscriber',
              url: '/entity/newsletter/create',
            },
            canAll(['create', 'access', 'update', 'delete'], 'newsletter') && {
              title: 'Settings',
              url: '/entity/cms-websites/settings/newsletter-settings/newsletter',
            },
          ],
        },
      },
    },
  }
}

/*
 * Sitecart Navication
 */
import store from "@/store"
import app from "@/main"
import { can, canAll, humanReadable } from "@/utilities/helper"

export async function getSitecartNavigation() {
  let nav_apps = generateNavApps()
  let nav_design = await generateNavDesign('cms-websites')
  let nav_newsletter = generateNewsletterNav()

  /* if you want to show menu item for specific app add "app: 'Portfolio'" for example after title */
  let menu = [
    {
      image: "icon-erp-dashboard",
      title: "Dashboard",
      url: "/",
    },
    {
      image: "icon-erp-files-and-folders",
      title: "Media Library",
      url: "/media-library",
    },
    {
      image: "icon-erp-tshirt",
      title: "Products",
      nav: {
        column_1: {
          'products': can('access', 'product') && {
            title: 'Products',
            nav: [
              {
                title: "All products",
                url: "/products",
              },
              can('create', 'product') &&
              {
                title: "Create product",
                url: "/products/create",
              },
              // {
              //     title: "Settings",
              //     // TODO: the id 55 is hardcoded we need it from somewhere
              //     url: `/entity/inventory/settings/product-page-inventory/general_info`
              // },
              canAll(['create', 'access', 'update', 'delete'], 'category') &&
              {
                title: "Product Categories",
                url: "/entity/products/categories/crud",
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-erp-finances",
      title: "Financial",
      nav: {
        column_1: {
          'financial': {
            title: 'Financial',
            nav: [
              {
                title: 'Orders',
                url: "/webshop-orders",
              },
              {
                title: 'Invoices',
                url: "/webshop-invoices",
              },
              can('access', 'abandoned-cart') &&
              {
                app: 'AbandonedCart',
                title: "Abandoned cart",
                url: "/entity/abandoned-carts",
              },
              {
                title: 'Export orders',
                url: "/export/orders",
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-erp-customers",
      title: "Customers",
      nav: {
        column_1: {
          'customers': can('access', 'customer') && {
            title: 'Customers',
            nav: [
              {
                title: "All customers",
                url: "/webshop-customers",
              },
              can('create', 'customer') && {
                title: "Create new customer",
                url: "/webshop-customers/create",
              },
            ],
          },
          'customer-groups': {
            title: 'Customer groups',
            nav: [
              {
                title: "All customer groups",
                url: "/webshop-customer-groups",
              },
              {
                title: "Create new customer group",
                url: "/webshop-customer-groups/create",
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-erp-pages",
      title: "Pages",
      nav: {
        column_1: {
          'pages': can('access', 'cms-page') && {
            title: 'Pages',
            nav: [
              {
                title: "All Pages",
                url: '/entity/cms-pages',
              },
              can('create', 'cms-page') &&
              {
                title: "Create new page",
                url: `/builder/cms-pages/create`,
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-erp-create-menu",
      title: "Menu",
      url: "/navigation",
    },
    nav_apps,
    {
      image: "icon-erp-shout",
      title: "Marketing",
      nav: {
        column_1: {
          'coupons': {
            title: 'Coupons',
            nav: [
              {
                title: 'All coupons',
                url: '/entity/coupons',
              },
              {
                title: 'Create Coupon',
                url: '/entity/coupons/create',
              },
            ],
          },
          'integrations': {
            title: 'Integrations',
            nav: [
              {
                title: 'Integrations',
                url: '/integrations',
              },
            ],
          },
          'seo': {
            title: 'Sitemap',
            nav: [
              {
                title: 'Sitemaps',
                url: '/seo-sitemaps',
              },
            ],
          },
          'default_seo_tags': {
            title: 'Default SEO tags',
            nav: [
              {
                title: 'Default SEO tags',
                url: '/entity/cms-websites/settings/default-seo-tags-settings',
              },
            ],
          },
          'seo_robot_text': {
            title: 'SEO',
            nav: [
              {
                title: 'Robots TXT',
                url: `/entity/cms-websites/settings/marketing-integrations-cmswebsite/robots_seo`,
              },
            ],
          },
        },
      },
    },
    can('access', 'user') && {
      image: "icon-erp-users",
      roles: [{ "key": 1, "value": "admin" }, { "key": 2, "value": "company_manager" }],
      title: "Users",
      url: "/users",
    },
    nav_design,
    {
      image: "icon-erp-cogs",
      title: "Settings",
      nav: {
        column_1: {
          'webshop': {
            title: 'Webshop',
            nav: [
              {
                title: 'General settings',
                url: `/settings/general`,
              },
              can('access', 'shipping') && {
                title: 'Shipping',
                url: '/webshop-settings/shipping/list',
              },
              {
                title: 'Accounts & Privacy',
                url: '/entity/cms-websites/settings/accounts-and-privacy-settings-cmswebsite',
              },
              {
                title: 'Webshop & Localization',
                url: `/settings/localization`,
              },
              {
                title: 'Webshop Settings',
                url: `/webshop-settings/`,
              },
            ],
          },
          'attributes': {
            title: 'Attributes',
            nav: [
              {
                title: 'Product attributes',
                url: "/product-attributes",
              },
              {
                title: 'Attribute sets',
                url: "/product-attributes-sets",
              },
            ],
          },
          // Trucks only for now
          'api-connections': store.state.system.theme === 'trucks' && {
            title: 'API Connection',
            nav: [
              {
                title: 'API Connection',
                url: `/entity/cms-websites/settings/api-connections/connect_with_apis`,
              },
            ],
          },
          'cart_checkout': {
            title: 'Cart & Checkout',
            nav: [
              {
                title: 'Checkout',
                url: `/entity/cms-websites/settings/cart-n-checkout-settings-cmswebsite/checkout`,
              },
            ],
          },
        },
        column_2: {
          'financial': {
            title: 'Financial',
            nav: [
              {
                title: 'Payment providers',
                url: '/payment-providers',
              },
            ],
          },
          'vat-settings': (can('access', 'vat-type') || can('access', 'vat-rate')) && {
            title: 'VAT',
            nav: [
              (canAll(['access', 'update'], 'vat-type') && canAll(['access', 'update'], 'vat-rate')) && {
                title: "VAT settings",
                url: `/entity/cms-websites/settings/vat-settings-cmswebsite/vat_settings`,
              },
              can('access', 'vat-type') && {
                title: "VAT groups",
                url: "/vat-types",
              },
              can('access', 'vat-rate') && {
                title: "VAT rates",
                url: "/vat-rates",
              },
            ],
          },
          'email': {
            title: 'Email',
            nav: [
              {
                title: "SMTP settings",
                url: `/entity/cms-websites/settings/smtp-settings-cmswebsite/smtp_settings`,
              },
            ],
          },
          'forms': can('access', 'formentry') && {
            title: 'Forms',
            nav: [
              {
                title: "Form submissions",
                url: `/entity/form-entries`,
              },
            ],
          },
        },
      },
    },
  ]

  if(store.state.system.authData.apps.includes('Newsletter')){
    menu.splice(4, 0, nav_newsletter)
  }

  return menu
}

export async function getSkitleNavigation() {

  /* if you want to show menu item for specific app add "app: 'Portfolio'" after the title */
  let nav_apps = generateNavApps()
  let nav_design = await generateNavDesign('cms-websites')
  let nav_newsletter = generateNewsletterNav()

  let menu = [
    {
      image: "icon-erp-dashboard",
      title: "Dashboard",
      url: "/",
    },
    {
      image: "icon-erp-files-and-folders",
      title: "Media Library",
      url: "/media",
    },
    {
      image: "icon-erp-pages",
      title: "Pages",
      nav: {
        column_1: {
          'pages': can('access', 'cms-page') && {
            title: 'Pages',
            nav: [
              {
                title: "All Cms pages",
                url: '/entity/cms-pages',
              },
              can('create', 'cms-page') &&
              {
                title: "Create new",
                url: `/builder/cms-pages/create`,
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-erp-create-menu",
      title: "Menu",
      url: "/navigation",
    },
    nav_apps,
    can('access', 'user') && {
      image: "icon-erp-users",
      roles: [{ "key": 1, "value": "admin" }, { "key": 2, "value": "company_manager" }],
      title: "Users",
      url: "/users",
    },
    {
      image: "icon-erp-shout",
      title: "Marketing",
      nav: {
        column_1: {
          'integrations': {
            title: 'Integrations',
            nav: [
              {
                title: 'Integrations',
                url: '/integrations',
              },
            ],
          },
          'seo': {
            title: 'Sitemap',
            nav: [
              {
                title: 'Sitemaps',
                url: '/seo-sitemaps',
              },
            ],
          },
          'default_seo_tags': {
            title: 'Default SEO tags',
            nav: [
              {
                title: 'Default SEO tags',
                url: '/entity/cms-websites/settings/default-seo-tags-settings',
              },
            ],
          },
          'seo_robot_text': {
            title: 'SEO',
            nav: [
              {
                title: 'Robots TXT',
                url: `/entity/cms-websites/settings/marketing-integrations-cmswebsite/robots_seo`,
              },
            ],
          },
        },
      },
    },
    nav_design,
    {
      image: "icon-erp-cogs",
      title: "Settings",
      nav: {
        column_1: {
          'settings': {
            title: 'settings',
            nav: [
              {
                title: 'General settings',
                url: `/settings/general`,
              },
              {
                title: 'Accounts & Privacy',
                url: '/entity/cms-websites/settings/accounts-and-privacy-settings-cmswebsite',
              },
              {
                title: 'Website & Localization',
                url: `/settings/localization`,
              },
            ],
          },
          'email': {
            title: 'Email',
            nav: [
              {
                title: "SMTP settings",
                url: `/entity/cms-websites/settings/smtp-settings-cmswebsite/smtp_settings`,
              },
            ],
          },
          'forms': can('access', 'formentry') && {
            title: 'Forms',
            nav: [
              {
                title: "Form submissions",
                url: `/entity/form-entries`,
              },
            ],
          },
        },
      },
    },
  ]

  if(store.state.system.authData.apps.includes('Newsletter')){
    menu.splice(4, 0, nav_newsletter)
  }

  return menu
}

export async function getRockNavigation() {
  return [
    {
      image: "icon-rock-dashboard",
      title: "Dashboard",
      url: "/",
    },
    {
      image: "icon-rock-customer",
      title: "Citizens",
      nav: {
        column_1: {
          'citizens': can('access', 'citizen') && {
            title: 'Citizens',
            nav: [
              {
                title: 'All citizens',
                url: `/entity/citizens`,
              },
              can('create', 'citizen') &&
              {
                title: 'Add new citizen',
                url: '/entity/citizens/create',
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-rock-footprints",
      title: "Trajects",
      nav: {
        column_1: {
          'trajects': can('access', 'traject') && {
            title: 'Trajects',
            nav: [
              {
                title: 'All Trajects',
                url: `/entity/trajects`,
              },
              can('create', 'traject') &&
              {
                title: 'Create new Traject',
                url: '/entity/trajects/create',
              },
            ],
          },
          'traject-types': can('access', 'trajectstype') && {
            title: 'Trajecttypes',
            nav: [
              {
                title: 'All Trajecttypes',
                url: `/entity/traject-types`,
              },
              can('create', 'trajectstype') &&
              {
                title: 'Create new Trajectstypes',
                url: '/entity/traject-types/create',
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-rock-documents",
      title: "Contracts",
      nav: {
        column_1: {
          "contracts": can('access', 'contract') && {
            title: "Contracts",
            nav: [
              {
                title: "All contracts",
                url: "/entity/contracts",
              },
              can('create', 'contract') &&
              {
                title: "Add new contract",
                url: "/entity/contracts/create",
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-rock-building",
      title: "Partners",
      nav: {
        column_1: {
          'partners': can('access', 'partner') && {
            title: 'Partners',
            nav: [
              {
                title: 'All partners',
                url: `/entity/partners`,
              },
              can('create', 'partner') &&
              {
                title: 'Add new partner',
                url: '/entity/partners/create',
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-rock-calendar",
      title: "Agenda",
      nav: {
        column_1: {
          'agenda': {
            title: 'Agenda',
            nav: [
              {
                title: 'Agenda',
                url: `/rock/agenda`,
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-rock-stopwatch",
      title: "Contact Moments",
      nav: {
        column_1: {
          'contact-moment': can('access', 'contact-moment') && {
            title: 'Contact Moments',
            nav: [
              {
                title: 'All contact moments',
                url: `/entity/contact-moments`,
              },
              can('create', 'contact-moment') && {
                title: 'Create new contact moment',
                url: '/entity/contact-moments/create',
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-rock-data-management",
      title: "Reports",
      nav: {
        column_1: {
          'reports': {
            title: 'Reports',
            nav: [
              {
                title: 'All reports',
                url: `/data-management`,
              },
              {
                title: 'Create new report',
                url: '/data-management',
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-rock-euro-bill",
      title: "Invoices",
      nav: {
        column_1: {
          'invoices': {
            title: 'Invoices',
            nav: [
              {
                title: 'All Invoices',
                url: `/rock-invoices`,
              },
              {
                title: 'Create new Invoice',
                url: '/rock-invoices',
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-rock-portfolio",
      title: "Human Resource",
      nav: {
        column_1: {
          'employees': can('access', 'user') && {
            title: 'Employees',
            nav: [
              {
                title: 'All employees',
                url: "/users",
              },
              can('create', 'user') && {
                title: 'Create new employee',
                url: "/users/create",
              },
            ],
          },
          'roles': can('access', 'role') && {
            title: 'Roles',
            nav: [
              {
                title: 'All roles',
                url: `/roles`,
              },
              can('access', 'role') && {
                title: 'Create new role',
                url: '/roles/create',
              },
            ],
          },
          'authentication-logs': can('access', 'authentication-log') && {
            title: 'Authentication logs',
            nav: [
              {
                title: 'Authentication logs',
                url: '/entity/authentication-logs',
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-erp-files-and-folders",
      title: "Documents",
      nav: {
        column_1: {
          'documents': can('access', 'document') && {
            title: 'Documents',
            nav: [
              {
                title: 'All documents',
                url: `/entity/documents`,
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-rock-settings",
      title: "Settings",
      nav: {
        column_1: {
          'settings': {
            title: 'Settings',
            nav: [
              {
                title: "Dropdowns",
                url: "/dropdowns",
              },
              canAll(['create', 'access', 'update', 'delete'], 'entity') && {
                title: "Entities",
                url: "/entities",
              },
            ],
          },
          'translations': {
            title: 'Translations',
            nav: [
              {
                title: 'Translations manager',
                url: `/manager`,
              },
            ],
          },
        },
      },
    },
  ]

}

export async function getBlockzNavigation() {
  return [
    {
      image: "icon-erp-speedometer",
      title: "Dashboard",
      url: "/",
    },
    {
      image: "icon-erp-users",
      title: "Customers",
      nav: {
        column_1: {
          'Clients': {
            title: 'Clients',
            nav: [
              {
                title: "All clients",
                url: "/entity/clients",
              },
              {
                title: "Create new client",
                url: "/entity/clients/create",
              },
            ],
          },
          'Companies': {
            title: 'Companies',
            nav: [
              {
                title: "All companies",
                url: "/entity/companies",
              },
              {
                title: "Create new company",
                url: "/entity/companies/create",
              },
            ],
          },
          'Websites': {
            title: 'Websites',
            nav: [
              {
                title: "All websites",
                url: "/entity/cms-websites",
              },
            ],
          },
        },
      },
    },
    {
      title: "Orders",
      image: "icon-erp-send",
      nav: {
        column_1: {
          'orders': {
            title: 'Orders',
            nav: [
              {
                title: "All Orders",
                url: "/orders"
              },
              {
                title: "Create Order Plan",
                url: "/blockz-order/create/choose-plan"
              },
              {
                title: "Create Order Extension",
                url: "/blockz-order-extension/create"
              }
            ],
          }
        }
      }
    },
    {
      image: "icon-erp-subscription",
      title: "Subscriptions",
      url: "/subscriptions"
    },
    {
      title: "Financial",
      image: "icon-erp-payment",
      nav: {
        column_1: {
          'financial': {
            title: 'Financial',
            nav: [
              {
                title: "All invoices",
                url: "/invoices"
              },
              {
                title: "Create new invoice",
                url: "/invoice-create"
              },
            ]
          }
        }
      }
    },
    {
      image: "icon-erp-marketplace",
      title: "Products",
      url: "/entity/inventory"
    },
    {
      image: "icon-erp-marketplace",
      title: "Marketplace",
      nav: {
        column_1: {
          'marketplace': {
            title: 'Marketplace',
            nav: [
              {
                title: "Extension",
                url: "/extensions"
              },
              {
                title: "Extension create",
                url: "/extensions-create"
              },
              {
                title: "Modules create",
                url: "/extensions-create"
              }
            ]
          }
        }
      }
    },
    {
      title: "Plans",
      image: "icon-erp-pricing",
      nav: {
        column_1: {
          'plans': {
            title: 'Plans',
            nav: [
              {
                title: 'All Plans',
                url: "/plans"
              },
              {
                title: 'Create plans',
                url: "/plans/create/basics"
              }
            ]
          }
        }
      }
    },
    {
      image: "icon-erp-staff",
      title: "Users",
      nav: {
        column_1: {
          'users': {
            title: 'Users',
            nav: [
              {
                title: 'All users',
                url: "/users",
              },
              {
                title: 'Create new user',
                url: "/users/create",
              },
            ],
          },
          'roles': {
            title: 'Roles',
            nav: [
              {
                title: 'All roles',
                url: `/roles`,
              },
              {
                title: 'Create new role',
                url: '/roles/create',
              },
            ],
          },
        },
      },
    },
    {
      image: "icon-erp-rocket",
      title: "Settings",
      nav: {
        column_1: {
          'general_settings': {
            title: 'General settings',
            nav: [
              {
                title: "General settings",
                url: "/settings/general",
              },
              {
                title: "Company settings",
                url: "/entity/companies/1",
              },
              // {
              // 	title: "Financial settings",
              // 	url: "/"
              // },
              // {
              // 	title: "Notifications",
              // 	url: "/settings/notifications-and-emails"
              // },
              // {
              // 	title: "Email template",
              // 	url: "/"
              // },
            ]
          },
        },
      },
    },
    {
      image: "icon-erp-marketplace",
      roles: [{"key": 1, "value": "admin"}, {"key": 2, "value": "company_manager"}],
      title: "ERP Management",
      nav: {
        column_1: {
          'settings': {
            title: 'Settings',
            nav: [
              {
                title: "Dropdowns",
                url: "/dropdowns"
              },
              {
                title: "Entities",
                url: "/entities",
              },
              {
                title: "Website settings",
                url: "/company"
              },
            ]
          },
          'cms-blocks': {
            title: 'CMS Blocks',
            nav: [
              {
                title: "All CMS Blocks",
                url: "/entity/cms-blocks"
              },
            ]
          }
        }
      }
    },
    {
      image: "icon-erp-marketplace",
      roles: [{"key": 1, "value": "admin"}, {"key": 2, "value": "company_manager"}],
      title: "Quote request",
      nav: {
        column_1: {
          'request': {
            title: 'Request',
            nav: [
              {
                title: "Requests",
                url: "/entity/tickets"
              },
            ]
          }
        }
      }
    },
    {
      image: "icon-erp-marketplace",
      roles: [{"key": 1, "value": "admin"}, {"key": 2, "value": "company_manager"}],
      title: "Tools",
      nav: {
        column_1: {
          'cms-content-generator': {
            title: 'Cms Content',
            nav: [
              {
                title: "Content Generator",
                url: "/tools/content-generator",
              },
            ],
          },
        },
      },
    },

  ]
}

export async function getNavigation(){
  switch (store.state.system.interface) {
    case "rock":
      return await getRockNavigation()
    case "blockz":
      return await getBlockzNavigation()
    case "sitecart":
      return await getSitecartNavigation()
    case "skitle":
      return await getSkitleNavigation()
    default:
      return []
  }
}