<template>
  <div class="row">
    <div class="col-md-12">
      <craft-chained-inputs
          :firstValue="getStyle('border-top-width', 'px')"
          :secondValue="getStyle('border-bottom-width', 'px')"
          @inputFirstValue="setBorderTopWidth($event)"
          @inputSecondValue="setBorderBottomWidth($event)"
      >
        <template v-slot:input-first>
          <craft-input
              :label="'Border top width'"
              :input-type="'number'"
              :suffix="'PX'"
              :value="getStyle('border-top-width', 'px')"
              @input="setBorderTopWidth($event)"
          />
        </template>

        <template v-slot:input-second>
          <craft-input
              :label="'Border bottom width'"
              :input-type="'number'"
              :suffix="'PX'"
              :value="getStyle('border-bottom-width', 'px')"
              @input="setBorderBottomWidth($event)"
          />
        </template>

      </craft-chained-inputs>
    </div>

    <div class="col-md-12">
      <craft-chained-inputs
          :firstValue="getStyle('border-left-width', 'px')"
          :secondValue="getStyle('border-right-width', 'px')"
          @inputFirstValue="setBorderLeftWidth($event)"
          @inputSecondValue="setBorderRightWidth($event)"
      >
        <template v-slot:input-first>
          <craft-input
              :label="'Border left width'"
              :input-type="'number'"
              :suffix="'PX'"
              :value="getStyle('border-left-width', 'px')"
              @input="setBorderLeftWidth($event)"
          />
        </template>

        <template v-slot:input-second>
          <craft-input
              :label="'Border right width'"
              :input-type="'number'"
              :suffix="'PX'"
              :value="getStyle('border-right-width', 'px')"
              @input="setBorderRightWidth($event)"
          />
        </template>

      </craft-chained-inputs>
    </div>

    <div class="col-md-12">
      <craft-color-picker
          :label="'Border color'"
          :value="getStyle('border-color')"
          @input="setStyle('border-color', $event)"
      />
    </div>

    <div class="col-md-6">
      <craft-select
          :label="'Border style'"
          :options="borderStyleOptions"
          :value="getBorderStyle()"
          @input="setBorderStyles($event)"
          :clearable="true"
      />
    </div>

    <div class="col-md-6">
      <craft-input
          :label="'Border radius'"
          :input-type="'number'"
          :suffix="'PX'"
          :value="getStyle('border-radius', 'px')"
          @input="setStyle('border-radius', $event, 'px')"
      />
    </div>
  </div>
</template>

<script>
import TransitionExpand from '../../../../TransitionExpand'
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin"
import CraftStyleSettingsMixin from "@/modules/pagebuilder/mixins/CraftStyleSettingsMixin"
import {CraftSelect, CraftInput, CraftColorPicker, CraftChainedInputs} from "@/modules/pagebuilder/components/craft-inputs"

export default {
  name: "Border",
  mixins: [ComponentSettingsMixin, CraftStyleSettingsMixin],
  inject: ['editor'],
  components: {
    CraftColorPicker,
    CraftInput,
    CraftSelect,
    TransitionExpand,
    CraftChainedInputs,
  },
  data() {
    return {
      key: 0,
      styling: {
        background: false,
        margin: false,
        devices: false,
        advanced: false,
      },
      img: "",
    };
  },
  methods: {
    setBorderTopWidth(value){
      this.setStyle('border-top-width', value, 'px')
      this.updateBorderStyles() // on change border width update border styles
    },
    setBorderBottomWidth(value){
      this.setStyle('border-bottom-width', value, 'px')
      this.updateBorderStyles() // on change border width update border styles
    },
    setBorderLeftWidth(value){
      this.setStyle('border-left-width', value, 'px')
      this.updateBorderStyles() // on change border width update border styles
    },
    setBorderRightWidth(value){
      this.setStyle('border-right-width', value, 'px')
      this.updateBorderStyles() // on change border width update border styles
    },

    getBorderStyle(){
      return this.getStyle('border-top-style') || this.getStyle('border-bottom-style') ||
          this.getStyle('border-left-width') || this.getStyle('border-right-style') || ''
    },

    setBorderStyles(style){
      this.setStyle('border-top-style', this.getStyle('border-top-width') ? style : '')
      this.setStyle('border-bottom-style', this.getStyle('border-bottom-width') ? style : '')
      this.setStyle('border-left-style', this.getStyle('border-left-width') ? style : '')
      this.setStyle('border-right-style', this.getStyle('border-right-width') ? style : '')
    },
    updateBorderStyles(){
      this.setBorderStyles(this.getBorderStyle())
    },
  },
  created() {
    // backward compatibility border width
    if(this.getStyle('border-width', 'px')){
      let borderWidth = this.getStyle('border-width', 'px')
      this.setStyle('border-width', '') // remove old values
      this.setStyle('border-top-width', borderWidth, 'px')
      this.setStyle('border-bottom-width', borderWidth, 'px')
      this.setStyle('border-left-width', borderWidth, 'px')
      this.setStyle('border-right-width', borderWidth, 'px')
    }

    // backward compatibility border style
    if(this.getStyle('border-style')){
      let borderStyle = this.getStyle('border-style')
      this.setStyle('border-style', '') // remove old values
      this.setBorderStyles(borderStyle)
    }
  }
};
</script>