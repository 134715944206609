<template>
  <!-- TEMPLATE IS IDENTICAL TO Widgets.vue -->
    <div class="list-group">
        <div class="list-group-item bg-primary text-white border-0">
            <div class="row align-items-center">
                <h6 class="text-white">{{ translate('Supported types', 'entities') }}</h6>
            </div>
        </div>
        <div class="list-group-item" v-for="(inputType, ti) in inputTypes" :key="ti">
            <div class="row align-items-center">
                <div class="col ml-n2">
                    <p class="card-text text-sm mb-0">{{ translate(inputType.label, 'entities') }}</p>
                </div>
                <div class="col-auto">
                    <button type="button" v-if="typeof inputType.subInputTypes === 'undefined'" @click="$emit('addProp', ti)" class="btn btn-xs btn-secondary btn-icon">
                        <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
                        <span class="btn-inner--text">{{ translate('Add', 'entities') }}</span>
                    </button>
                    <v-select v-else :options="inputTypesOptions(inputType.subInputTypes)" :reduce="value => value.key" @input="i => $emit('addProp', i)" class="w-1 select2-form-pickers"></v-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {inputTypes} from "../../utilities/helper";

    export default {
        name: "InputTypes",
        computed: {
            inputTypes() {
                return inputTypes;
            },
        },
        methods: {
          inputTypesOptions(inputTypes){
            let options = []
            for (const [key, value] of Object.entries(inputTypes)) {
              options.push({
                key: key,
                label: value.label
              })
            }
            return options
          }
        }
    }
</script>

<style lang="scss" scoped>
    .list-group-item {
        margin-bottom: 0;
    }
    .list-group-item:hover {
      background: $secondary;
    }
</style>
