<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true" :name="translate('General', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <span class="add-new-item" @click.prevent="addItem"><i class="fa icon-erp-add mr-2"></i>{{ translate('Add new slide', 'page-builder') }}</span>

            <draggable
              v-model="node.children"
              @start="drag=true"
              @end="dragEnd"
              :draggable="'.draggable-item'">
              <div v-for="(item, itemIndex) in node.children" :key="itemIndex" class="card draggable-item">
                <h6 class="card-head d-flex align-items-center" @click="selectAndGoToItem(itemIndex)" :class="{'selected' : selectedItem === itemIndex }">
                  <i class="fa icon-erp-drag-and-drop"></i>

                  <span>{{ itemLabel(item, itemIndex) }}</span>
                  <i
                    class="fa ml-auto fa-chevron-down chevron"
                    :class="{'chevron-rotate': visible[itemIndex]}"
                  ></i>
                </h6>

                <transition-expand>
                  <div v-show="visible[itemIndex]">
                    <div class="card-body pt-0" :class="{'snippets-holder' : visible[itemIndex]}">
                      <div class="app-page-snippets d-flex flex-column">
                        <craft-input
                          label="Title"
                          :value="item.props.settings.title"
                          @input="e => updateTitle(item, e)"
                        />

<!--                        <craft-input-->
<!--                          :label="'Title'"-->
<!--                          :value="item.title"-->
<!--                          @input="e => {item.title = e}"-->
<!--                        />-->


  <!--                      <craft-text-editor-->
  <!--                        :label="'Content'"-->
  <!--                        :value="item.content"-->
  <!--                        @input="e => {item.content = e}"-->
  <!--                      />-->

  <!--                      <craft-toggle-->
  <!--                        :label="'Put accordion open'"-->
  <!--                        :id="'collapsed-'+itemIndex"-->
  <!--                        :value="item.collapsed"-->
  <!--                        :value-on="0"-->
  <!--                        :value-off="1"-->
  <!--                        @input="item.collapsed = $event"-->
  <!--                      />-->

                        <div class="row">
                          <div class="col-6">
                            <button class="btn btn-secondary rounded-pill w-100" @click.prevent="duplicateItem(itemIndex)"><i class="icon-erp-duplicate"></i> {{ translate('Duplicate slide', 'page-builder') }}</button>
                          </div>
                          <div class="col-6">
                            <button class="btn btn-secondary rounded-pill w-100" @click.prevent="removeItem(itemIndex)"><i class="icon-erp-trash"></i> {{ translate('Remove slide', 'page-builder') }}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition-expand>
              </div>
            </draggable>
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Layout', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-input
              input-type="range"
              label="Slides per view"
              min="1"
              max="6"
              :value="getSetting('slides_per_view')['mobile']"
              @input="setSetting('slides_per_view', { mobile: $event, tablet_portrait: $event, tablet_landscape: $event, desktop: $event } )"
            />
          </div>

<!--          <div class="col-md-12">-->
<!--            <craft-tab-pills-->
<!--              label="Direction"-->
<!--              :options="generalOrientationOptions"-->
<!--              :value="getSetting('direction')"-->
<!--              @input="setSetting('direction', $event)"-->
<!--            />-->
<!--          </div>-->

          <!--          <div class="col-12">-->
          <!--            <craft-input-->
          <!--              input-type="range"-->
          <!--              label="Slides per group"-->
          <!--              min="1"-->
          <!--              max="6"-->
          <!--              :value="getSetting('slides_per_group')"-->
          <!--              @input="setSetting('slides_per_group', $event)"-->
          <!--            />-->
          <!--          </div>-->

          <div class="col-12">
            <craft-toggle
              :id="`show-bullets-${node.uuid}`"
              label="Show pagination"
              :value="getSetting('pagination')"
              :value-on="true"
              :value-off="false"
              @input="setSetting('pagination', $event)"
            />
          </div>

          <div class="col-12">
            <craft-toggle
              :id="`show-navigation-${node.uuid}`"
              label="Show navigation"
              :value="getSetting('navigation')"
              :value-on="true"
              :value-off="false"
              @input="setSetting('navigation', $event)"
            />
          </div>

          <!--          <transition name="fade">-->
          <!--            <div class="col-md-12" v-if="getSetting('navigation')">-->
          <!--              <craft-tab-pills-->
          <!--                label="Navigation position"-->
          <!--                :options="generalVerticalAlignmentOptions"-->
          <!--                :value="getSetting('navArrowsVerticalAlign')"-->
          <!--                @input="setSetting('navArrowsVerticalAlign', $event)"-->
          <!--              />-->
          <!--            </div>-->
          <!--          </transition>-->

          <div class="col-12">
            <craft-input
              :label="'Height (leave empty for auto)'"
              :input-type="'number'"
              :suffix="'PX'"
              :value="getSetting('container_height', 'px')"
              @input="setSetting('container_height', $event, 'px')"
            />
          </div>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import CraftStyleSettingsMixin from "@/modules/pagebuilder/mixins/CraftStyleSettingsMixin";
import {CraftInput, CraftSelect, CraftToggle, CraftTabPills} from "@/modules/pagebuilder/components/craft-inputs";
import { createNodeFromComponentName } from "@/modules/pagebuilder/craft/utils/createNodeFromVNode"
import DraggableMixin from "@/mixins/DraggableMixin"

export default {
  name: 'ContentSliderSettings',
  components: {
    CraftTabPills,
    CraftToggle,
    CraftInput,
    CraftSelect,
  },
  mixins: [DraggableMixin, ComponentSettingsMixin, CraftSettingsMixin, CraftStyleSettingsMixin],
  inject: ['editor', 'node'],
  data() {
    return {
      childComponentName: 'ContentSliderSlideElement',
      selectedItem: null,
      items: [],
      visible: {}, // active or not active accordion
    }
  },
  computed: {
    newItem(){
      return createNodeFromComponentName(this.editor, this.childComponentName)
    },
    containerOptions() {
      return [
        { key: 'container', label: 'Box' },
        { key: 'container-fluid', label: 'Full width' }
      ]
    },
  },

  watch: {
    items: {
      deep: true,
      handler() {
        this.setSetting('items', this.items)
      },
    },
  },

  async mounted() {
    this.items = this.getSetting('items') || []
    const swiperEl = document.querySelector(`div[data-uuid="${this.node.uuid}"] swiper-container`)
    this.selectedItem = swiperEl.swiper.realIndex

    console.log('swiperEl.swiper.realIndex')
    console.log(swiperEl.swiper.realIndex)

    // swiperEl.swiper.slideTo(index)
  },

  methods: {
    selectAndGoToItem(index){
      this.selectedItem = index
      this.goToItem(index)
      this.toggleVisibility(index, true)
    },

    // from the mixin
    toggleVisibility(visibilityKey, collapseAll = false){
      if(collapseAll){
        Object.keys(this.visible).map(([key]) => {
          if (parseInt(key) !== visibilityKey){
            this.visible[key] = false
          }
        })
      }

      // check if exists
      if(typeof this.visible[visibilityKey] === 'undefined'){
        this.$set(this.visible, visibilityKey, true)
      } else {
        this.visible[visibilityKey] = !this.visible[visibilityKey]
      }
    },

    dragEnd(){
      // this.drag = false
      // this.items.forEach((item,i) => {
      //   item.position = i
      // })
    },

    addItem() {
      const node = createNodeFromComponentName(this.editor, this.childComponentName)
      this.node.append(node)
      this.$nextTick(() => {
        this.selectAndGoToItem(this.node.children.length-1)
      })
    },

    duplicateItem(index){
      const duplicatedNode = this.node.children[index].duplicate()
      duplicatedNode.props.settings.title += ' ' + this.translate('duplicated', 'global')
      this.node.insertAtIndex(duplicatedNode, index+1)

      this.$nextTick(() => {
        this.selectAndGoToItem(index+1)
      })
    },

    async removeItem(index){
      const confirmDelete = await this.$alert.confirmDelete()
      if(!confirmDelete.isConfirmed) return

      this.visible[index] = false
      this.selectedItem = 0
      this.node.children.splice(index, 1)
    },

    // END from the mixin

    goToItem(index) {
      const swiperEl = document.querySelector(`div[data-uuid="${this.node.uuid}"] swiper-container`)
      swiperEl.swiper.slideTo(index)

      // // console.log('awesome')
      // const swiperEl = document.querySelector(`div[data-uuid="${this.node.uuid}"] swiper-container`)
      //
      // console.log('swiperEl')
      // console.log(swiperEl)
      //
      // console.log('this.getSetting(\'pagination\')')
      // console.log(this.getSetting('pagination'))
      //
      // swiperEl.setAttribute('slides-per-view', this.getSetting('slides_per_view'))
      // swiperEl.setAttribute('pagination', this.getSetting('pagination'))
      // swiperEl.setAttribute('navigation', this.getSetting('navigation'))
      // swiperEl.initialize()
      //

      // swiperRef.current.swiper.slideTo(0);
    },

    updateTitle(item, value) {
      this.$set(item.props.settings, 'title', value)
    },
    itemLabel(item, itemIndex){
      if(item.props.settings.title !== ''){
        return item.props.settings.title
      } else {
        let number = itemIndex + 1
        return this.translate("Slide {number}", 'page-builder', { number: number })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .card-head.selected {
    > span {
      color: $primary!important;
    }
  }
</style>