import moment from 'moment/moment'
import store from '../../../store/index'

export const applyShortcodes = value => {
  const shortcodesMap = {
    '{website_id}': store.state.system.scope.value,
    '{primary_locale}': store.state.system.scope.obj.website_primary_locale,
    '{current_datetime}': moment().format('YYYY-MM-DD HH:mm:ss'),
    '{current_datetime_iso}': new Date().toISOString(),
  }

  Object.entries(shortcodesMap).forEach(([shortcode, replacement]) => {
    const arrayPassed = value[0] === '[' && value[value.length - 1] === ']'

    // If array is passed for default value it must be cast to string
    value = value.replace(shortcode, arrayPassed ? `"${replacement}"` : replacement)
  })

  return value
}