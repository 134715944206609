<template>
  <div
    class="cms-block"
    :class="'cms-block-'+blockName"
    :data-component="blockName"
  >
    <template v-if="editor.enabled">
      <div
        v-if="resData"
        v-html="resData.output"
      />
      <vs-skeleton
        v-else
        primary-color="#ebebeb"
        secondary-color="#f2f2f2"
        class="cms-placeholder"
        data-placeholder="Google reviews"
      >
        <vs-line
          height="250"
          class="mt-1"
          b-radius="0"
        />
      </vs-skeleton>
    </template>
  </div>
</template>

<script>
import DynamicComponentMixin from "@/modules/pagebuilder/mixins/DynamicComponentMixin"

export default {
  mixins: [DynamicComponentMixin],
  inject: ['editor'],
  craft: {
    defaultProps: {
      settings: {
        layout: 'widget',
        columns: '1',
      },
    },
  },
  data() {
    return {
      blockName: 'google-review',
      renderUrl: 'google-review',
    }
  },
}
</script>
