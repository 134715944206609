<template>
  <div class="left-sidebar-content settings-sidebar nav-scrollbar">
    <div class="left-sidebar-content--container settings-sidebar--container nav-scrollbar">
      <div class="left-sidebar-content-header d-flex align-items-center justify-content-between">
        <div>
          <h6>{{ translate('Settings', 'page-builder') }}</h6>
          <p><small class="info mt-1">{{ translate('Settings', 'page-builder') }}</small></p>
        </div>
        <div class="close-sidebar">
          <a @click="closeLeftSidebar">
            <i class="icon-erp-plus icon-erp-rotate-45 text-bold"></i>
          </a>
        </div>
      </div>

      <div v-if="value">

        <template v-if="['cms-pages', 'cms-sections', 'cms-templates', 'blogs', 'portfolios'].includes(builder.meta.entity)">
          <!-- Locale -->
          <ee-form-group>
            <LanguagePicker
              :lang="value.locale"
              :label="translate('Locale', 'page-builder')"
              :customLabelClasses="['mt-3', 'font-weight-700', 'font-12']"
              @changeLocale="changeLocale"
            />
          </ee-form-group>
        </template>

        <!-- Title (Sections and Templates only) -->
        <template v-if="['cms-sections', 'cms-templates'].includes(builder.meta.entity)">
          <ee-form-group
            :label="translate('Title', 'page-builder')">
            <input type="text" name="title" v-model="value.name" class="form-control input">
          </ee-form-group>
        </template>

        <!-- Description (Templates only) -->
        <template v-if="['cms-templates'].includes(builder.meta.entity)">
          <ee-form-group :label="translate('Description', 'page-builder')">
            <ee-form-textarea-simple
              v-model="value.description"
              :placeholder="translate('Description', 'page-builder')"
            >
            </ee-form-textarea-simple>
          </ee-form-group>
        </template>

        <!-- Location (Sections only) -->
        <template v-if="['cms-sections'].includes(builder.meta.entity)">
          <ee-form-group
            :label="translate('Location', 'page-builder')">
            <select-field
              v-model="value.slug"
              :field-props="form.slug"
            />
          </ee-form-group>
        </template>

        <!-- Relation (Pages, Sections, Blogs, Portfolios) -->
        <template v-if="primaryCmsContents && ['cms-pages', 'cms-sections', 'blogs', 'portfolios'].includes(builder.meta.entity) && hasManyTranslationLocales && value.locale !== $store.state.system.scope.obj.website_primary_locale">
          <ee-form-group
            :label="translate('This {entity} is translation of', 'page-builder', { entity: entityNameTranslated })">
            <select-field
              v-model="relation"
              :field-props="{ data: primaryCmsContents }"/>
          </ee-form-group>
        </template>

        <!-- Blogs, Portfolios, and CMS Templates -->
        <template v-if="['blogs', 'portfolios', 'cms-templates'].includes(builder.meta.entity)">

          <div class="form-group">
            <label>{{ translate('Choose categories', 'page-builder') }}</label>
            <ee-form-treeselect
              v-if="$store.state.builder.categories"
              :multisite="true"
              v-model="value.categories"
              :field-props="{ data: $store.state.builder.categories }"
              :placeholder="translate('Choose categories', 'page-builder')"
              :key="renderKey"
            />
            <a href="javascript:;" v-if="['blogs', 'portfolios', 'cms-templates'].includes(builder.meta.entity)"
               class="d-flex justify-content-end" @click="createNewCategory">
              {{ translate('Create new', 'entities') }}
            </a>
          </div>

          <div class="form-group" v-if="!isAdvancedBuilder && form && form.layout">
            <label>{{ translate('Layout', 'page-builder') }}</label>
            <field
              :form="true"
              :key="form.layout.name"
              v-model="value.layout"
              :field-props="form.layout"
              :show-label="false"
              :entity="builder.meta.entity"
            />
          </div>
        </template>

        <!-- Blogs and Portfolios -->
        <template v-if="['blogs', 'portfolios'].includes(builder.meta.entity)">

          <div class="form-group">
            <label>{{ translate('Excerpt', 'page-builder') }}</label>
            <ee-form-textarea-simple
              v-model="value.excerpt"
              :placeholder="translate('Excerpt', 'page-builder')"
            >
            </ee-form-textarea-simple>
          </div>

          <div class="form-group">
            <label>{{ translate('Enable featured image', 'page-builder') }}</label>
            <div class="form-group d-flex">
              <div class="switch-field" v-for="(option, optionIndex) in visibility" :key="optionIndex" >
                <input :id="`option-${option.key}`" name="visibility-option"
                       type="radio" :value="option.value" v-model.number="enable_image_as_cover">
                <label :for="`option-${option.key}`">{{option.key}}</label>
              </div>
            </div>
          </div>
          <field
            v-if="form && form.image && enable_image_as_cover"
            v-model="value.image"
            :field-props="form.image"
            :show-label="false"
          />

          <!-- Using <field> prevents date-picker-field default value emit, must be fixed before we can use field here -->
          <ee-form-group
            :label="translate('Publish at', 'entities')">
            <date-picker-field
              v-model="value.publish_at"
              :field-props="form.publish_at"
            />
          </ee-form-group>



          <!-- DISABLED FOR NOW
          <template v-if="builder.meta.entity === 'blogs'">
            <div class="form-group">
              <label>{{ translate('Reading time', 'page-builder') }}</label>
              <div class='read-time'>
                <input
                    type="number"
                    name="read_time"
                    v-model="value.read_time"
                    class="form-control input"
                >
                <span>{{translate('Mins', 'page-builder')}}</span>
              </div>
            </div>
            <div class="form-group">
              <label>{{ translate('Author', 'page-builder') }}</label>
              <ee-form-treeselect
                  v-if="authors"
                  v-model="value.author"
                  :placeholder="translate('Choose Author', 'page-builder')"
                  :field-props="{ data: authors }"
                  :multiple="false"
              />
            </div>
          </template>
          -->
        </template>

      </div>
    </div>

    <!-- Create category modal-->
    <modal name="create_category"
           :pivot-x="0.5"
           :pivot-y="0"
           overlayTransition="wait"
           height="400px"
           width="500px"
           transition="slide-top"
           classes="mt-5 remove-modal-height"
    >
      <create-category-modal
        :entity-name="builder.meta.entity"
        :entity-type="'categories'"
        @refreshCategories="refreshCategories()"
      />
    </modal>
    <!-- End create category modal-->
  </div>
</template>

<script>
import BuilderMixin from "@/modules/pagebuilder/mixins/BuilderMixin";
import PagesMixin from "@/modules/pagebuilder/mixins/PagesMixin"; // required for findCmsContentBySlug
import LanguagePicker from "@/components/LanguagePicker";
import { EeFormGroup, EeFormTreeselect, EeFormTextareaSimple } from "@/modules/erp_entities/components/forms";
import SelectField from '@/modules/erp_entities/components/page/form/fields/SelectField.vue'
import field from '@/modules/erp_entities/components/page/form/InputEntityProps'
import {changeTranslationLocale, hasManyTranslationLocales, isVoidValue} from "@/utilities/helper";
import {mapActions} from "vuex";
import store from "@/store";
import uniqid from "uniqid";
import busEvent from "@/utilities/eventBus";
import CreateCategoryModal from "@/modules/pagebuilder/components/CreateCategoryModal";
import {groupFormFieldsByName} from "@/modules/erp_entities/utilities/helper";
import DatePickerField from "@/modules/erp_entities/components/page/form/fields/DatePickerField.vue"

export default {
  name: 'Settings',
  props: {
    value: {
      required: true,
    },
  },
  mixins: [BuilderMixin, PagesMixin],
  components: {
    DatePickerField,
    field,
    SelectField,
    EeFormGroup, EeFormTreeselect, EeFormTextareaSimple,
    LanguagePicker,
    CreateCategoryModal,
  },
  data() {
    return {
      form: null,
      enable_image_as_cover: true,
      authors: '',
      entity: '',
      initialSlug: '',
      primaryCmsContents: null,
      renderKey: 1,
    }
  },

  computed: {
    hasManyTranslationLocales,
    visibility() {
      return [
        { key: 'NO', value: false },
        { key: 'Yes', value: true },
      ]
    },
    relation: {
      get() {
        const slugExists = this.primaryCmsContents.some((CmsContent) => CmsContent.key == this.value.slug);
        return slugExists ? this.value.slug : null
      },
      async set(v) {
        if(v === null){
          /* on clear set initial slug if the page is not a translation,
             otherwise generate new slug so it gets disconnected */
          const slugExists = this.primaryCmsContents.some((CmsContent) => CmsContent.key == this.initialSlug)
          this.value.slug = !slugExists ? this.initialSlug : uniqid()
        } else {
          let translatedPage = await this.findCmsContentBySlug(this.builder.meta.entity, v, this.value.locale)
          if(translatedPage && translatedPage.id !== this.value.id){
            this.alertCmsContentRelationExists()
            return false
          }

          this.value.slug = v;
        }
      }
    }
  },
  async mounted() {
    if(['blogs', 'portfolios'].includes(this.builder.meta.entity)){
      this.enable_image_as_cover = !!this.value.image
    }

    if(['blogs'].includes(this.builder.meta.entity)){
      const users = await this.erp.ext.request.axiosInstance.get('/users').catch(() => false);
      if (users) {
        this.authors = users.data.data.map(u => {
          return {
            key: u.id,
            value: u.first_name + ' ' + u.last_name
          }
        });
      }

      // deselect the author if it not exsits
      const authorExists = this.authors.some((author) => author.key == this.value.author);
      if(!authorExists){
        this.value.author = null
      }
    }

  },

  methods: {
    ...mapActions('builder', [
      'fetchEntities', 'getEntities', 'fetchEntityCategories'
    ]),
    closeLeftSidebar() {
      this.$emit('closeLeftSidebar')
    },

    async changeLocale(locale){

      // on update check if translation exists
      if(this.value.id){
        let translatedPage = await this.findCmsContentBySlug(this.builder.meta.entity, this.value.slug, locale)
        if(translatedPage && translatedPage.id !== this.value.id){
          this.alertCmsContentRelationExists()
          return false
        }
        /* update locale immediatelly, if needed
        let data = {
          locale: locale
        }
        await this.erp.ext.request.axiosInstance.put(`modules/${this.builder.meta.entity}/${this.value.id}`, {options: data})
          .then((res) => {
            this.$toast.requestSuccess('put', 'Locale')
            this.value.locale = locale
            // todo update header locale
          })
          .catch(error => {
            this.$toast.error(error)
          });
          */
      }

      changeTranslationLocale(locale)
      this.value.locale = locale
      this.fetchEntities({entity: this.builder.meta.entity, locale: locale})
    },
    createNewCategory(){
      this.$modal.show('create_category')
    },
    async refreshCategories(){
      await this.fetchEntityCategories({entity: this.builder.meta.entity})
      this.renderKey++
    }
  },
  async created(){
    this.form = groupFormFieldsByName(this.$store.state.builder.entities.columns)

    this.initialSlug = this.value.slug
    if(['cms-pages', 'cms-sections', 'blogs', 'portfolios'].includes(this.builder.meta.entity)){

      // todo cache this in builder.js
      let entities = await this.getEntities({entity: this.builder.meta.entity, locale: this.$store.state.system.scope.obj.website_primary_locale})
      if(entities.objectData.length){

        this.primaryCmsContents = entities.objectData
          .filter(entity => !isVoidValue(entity.slug)) // hot fix skip content without slug
          .map(entity => {
            return {
              key: entity.slug,
              value: entity.name
            }
          });

        // this.primaryCmsContents = entities.objectData.reduce((options, { name, slug }) => {
        //   options.push({ key: slug, value: name })
        //   return options
        // }, [])
      } else {
        this.primaryCmsContents = []
      }
    }
  },
}
</script>

<style scoped lang="scss">
.text-sm {
  font-size: 12px;
}

.read-time {
  position: relative;

  input + span {
    background-color: #fff;
    border-left: 1px solid #ccc;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    font-weight: bold;
    height: 37px;
    line-height: 40px;
    padding: 0 1rem;
    position: absolute;
    right: 1px;
    top: 1px;
  }
}
</style>
