<template>
  <div class="row">

    <div class="col-md-12">
      <craft-chained-inputs
          :firstValue="getStyle('padding-top', 'px')"
          :secondValue="getStyle('padding-bottom', 'px')"
          @inputFirstValue="setStyle('padding-top', $event, 'px')"
          @inputSecondValue="setStyle('padding-bottom', $event, 'px')"
      >
        <template v-slot:input-first>
          <craft-input
              :label="'Padding top'"
              :input-type="'number'"
              :suffix="'PX'"
              :value="getStyle('padding-top', 'px')"
              @input="setStyle('padding-top', $event, 'px')"
          />
        </template>

        <template v-slot:input-second>
          <craft-input
              :label="'Padding bottom'"
              :input-type="'number'"
              :suffix="'PX'"
              :value="getStyle('padding-bottom', 'px')"
              @input="setStyle('padding-bottom', $event, 'px')"
          />
        </template>
      </craft-chained-inputs>
    </div>

    <div class="col-md-12">
      <craft-chained-inputs
          :firstValue="getStyle('padding-left', 'px')"
          :secondValue="getStyle('padding-right', 'px')"
          @inputFirstValue="setStyle('padding-left', $event, 'px')"
          @inputSecondValue="setStyle('padding-right', $event, 'px')"
      >
        <template v-slot:input-first>
          <craft-input
              :label="'Padding left'"
              :input-type="'number'"
              :suffix="'PX'"
              :value="getStyle('padding-left', 'px')"
              @input="setStyle('padding-left', $event, 'px')"
          />
        </template>

        <template v-slot:input-second>
          <craft-input
              :label="'Padding right'"
              :input-type="'number'"
              :suffix="'PX'"
              :value="getStyle('padding-right', 'px')"
              @input="setStyle('padding-right', $event, 'px')"
          />
        </template>
      </craft-chained-inputs>
    </div>

    <div class="col-md-12">
      <craft-chained-inputs
          :firstValue="getStyle('margin-top', 'px')"
          :secondValue="getStyle('margin-bottom', 'px')"
          @inputFirstValue="setStyle('margin-top', $event, 'px')"
          @inputSecondValue="setStyle('margin-bottom', $event, 'px')"
      >
        <template v-slot:input-first>
          <craft-input
              :label="'Margin top'"
              :input-type="'number'"
              :suffix="'PX'"
              :value="getStyle('margin-top', 'px')"
              @input="setStyle('margin-top', $event, 'px')"
          />
        </template>

        <template v-slot:input-second>
          <craft-input
              :label="'Margin bottom'"
              :input-type="'number'"
              :suffix="'PX'"
              :value="getStyle('margin-bottom', 'px')"
              @input="setStyle('margin-bottom', $event, 'px')"
          />
        </template>
      </craft-chained-inputs>
    </div>

    <div class="col-md-12">
      <craft-chained-inputs
          :firstValue="getStyle('margin-left', 'px')"
          :secondValue="getStyle('margin-right', 'px')"
          @inputFirstValue="setStyle('margin-left', $event, 'px')"
          @inputSecondValue="setStyle('margin-right', $event, 'px')"
      >
        <template v-slot:input-first>
          <craft-input
              :label="'Margin left'"
              :input-type="'number'"
              :suffix="'PX'"
              :value="getStyle('margin-left', 'px')"
              @input="setStyle('margin-left', $event, 'px')"
          />
        </template>

        <template v-slot:input-second>
          <craft-input
              :label="'Margin right'"
              :input-type="'number'"
              :suffix="'PX'"
              :value="getStyle('margin-right', 'px')"
              @input="setStyle('margin-right', $event, 'px')"
          />
        </template>
      </craft-chained-inputs>
    </div>

  </div>
</template>

<script>
import TransitionExpand from '../../../../TransitionExpand'
import CraftStyleSettingsMixin from "@/modules/pagebuilder/mixins/CraftStyleSettingsMixin";
import {CraftChainedInputs, CraftInput} from "@/modules/pagebuilder/components/craft-inputs";

export default {
  name: "Spacing",
  mixins: [CraftStyleSettingsMixin],
  inject: ['editor'],
  components: {
    CraftChainedInputs,
    CraftInput,
    TransitionExpand,
  },
};
</script>

<style lang="scss" scoped>
</style>
