<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true" :name="translate('General', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-select
                :label="'Choose form type'"
                :options="formTypes"
                :value="getFormType(form.type)"
                @input="changeFormType($event)"
                :clearable="false"
            />
            <craft-input
                v-if="isContactForm"
                :label="'Form name'"
                :value="form.name"
                @input="form.name = $event"
            />
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab v-if="isContactForm" :name="translate('Form', 'page-builder')">
       <div class="row">
         <div class="col-12">
              <div class="dropdown dropdown-animate" data-toggle="hover">
                <a href="javascript:;" role="button" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false">
                  <span class="add-new-field"><i class="fa icon-erp-add mr-2"></i>{{ translate('Add field', 'page-builder') }}</span>
                </a>
                <div class="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-arrow mr-0">
                  <div class="menu-dropdown-items">
                    <div class="d-flex" v-for="(fieldType, fieldTypeKey) in fieldTypes">
                      <div class="dropdown-item btn" @click.prevent="addField(fieldTypeKey)">
                        <span><i class="d-inline-block mr-1" :class="fieldType.icon"></i> {{fieldType.label}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            <draggable
                 v-model="form.default_properties"
                 @start="drag=true"
                 @end="dragEnd"
                 :draggable="'.draggable-item'">
              <div v-for="(field, fieldId) in form.default_properties" :key="fieldId" class='card draggable-item'>
                  <h6 class="card-head d-flex align-items-center" @click="$set(selectedInputTabs, fieldId , !selectedInputTabs[fieldId])">
                      <i class="fa icon-erp-drag-and-drop"></i>

                      <span>{{fieldTitle(field)}}</span>
                      <i
                          class="fa ml-auto fa-chevron-down chevron"
                          :class="{'chevron-rotate': selectedInputTabs[fieldId]}"
                      ></i>
                  </h6>

                  <transition-expand>
                      <div v-show="selectedInputTabs[fieldId]">
                          <div class="card-body pt-0" :class="{'snippets-holder' : selectedInputTabs[fieldId]}">
                            <form-field :value="field" :fieldId="fieldId" @removeField="e => removeField(e)"/>
                          </div>
                      </div>
                  </transition-expand>
              </div>
            </draggable>
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Button', 'page-builder')">
        <row class="row">
          <div class="col-12">
            <craft-input
                :label="'Button text'"
                :value="getFormSettings('button', 'text')"
                @input="setFormSettings('button', 'text', $event)"
            />
            <craft-tab-pills
                :label="'Button Alignment'"
                :value="getFormSettings('button', 'text-align')"
                @input="setFormSettings('button', 'text-align', $event)"
                :options="buttonAlignmentOptions"
            />
            <craft-tab-pills
                :label="'Button size'"
                :value="getFormSettings('button', 'width')"
                @input="setFormSettings('button', 'width', $event)"
                :options="buttonSizeOptions"
            />
            <craft-tab-pills
                :label="'Button behavior'"
                :value="getFormSettings('form', 'button_behavior')"
                @input="setFormSettings('form', 'button_behavior', $event)"
                :options="buttonBehaviorOptions"
            />
            <craft-textarea
                v-if="getFormSettings('form', 'button_behavior') ===  'message'"
                :label="'Message'"
                :placeholder="'Thank you for your message'"
                :field-props="{errors: errors.button ? errors.button.thankyou_message : false}"
                :value="getFormSettings('button', 'thankyou_message')"
                @input="setFormSettings('button', 'thankyou_message', $event)"
            />
            <craft-input
                v-else-if="getFormSettings('form', 'button_behavior') ===  'link'"
                :label="'Link'"
                :field-props="{errors: errors.redirect ? errors.redirect.redirect_link : false}"
                :value="getFormSettings('redirect', 'redirect_link')"
                @input="setFormSettings('redirect', 'redirect_link', $event)"
            />
          </div>
        </row>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Styling', 'page-builder')" :has-padding="false">
        <element-settings-content-tabs>
          <element-settings-content-tab :selected="true" :name="translate('Form', 'page-builder')">
            <div class="row">
              <div class="col-12">
                <craft-tab-pills
                    :label="'Form layout'"
                    :value="getFormSettings('form', 'form_layout')"
                    @input="setFormSettings('form', 'form_layout', $event)"
                    :options="layoutOptions"
                />
                <div class="row">
                  <div class="col-md-6">
                    <craft-input
                        :label="'Label font size'"
                        :input-type="'number'"
                        :suffix="'PX'"
                        :value="getFormSettings('label', 'font-size').split('').slice(0, -2).join('')"
                        @input="value => setFormSettings('label', 'font-size', value+'px')"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker
                        :label="'Label font color'"
                        :value="getFormSettings('label', 'color')"
                        @input="setFormSettings('label', 'color', $event)"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-input
                        :label="'Field font size'"
                        :input-type="'number'"
                        :suffix="'PX'"
                        :value="getFormSettings('field', 'font-size').split('').slice(0, -2).join('')"
                        @input="value => setFormSettings('field', 'font-size', value+'px')"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker
                        :label="'Field font color'"
                        :value="getFormSettings('field', 'color')"
                        @input="setFormSettings('field', 'color', $event)"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker
                        :label="'Field background color'"
                        :value="getFormSettings('field', 'background-color')"
                        @input="setFormSettings('field', 'background-color', $event)"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-input
                        :label="'Field border width'"
                        :input-type="'number'"
                        :suffix="'PX'"
                        :value="getFormSettings('field', 'border-width').split('').slice(0, -2).join('')"
                        @input="value => setFormSettings('field', 'border-width', value+'px')"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker
                        :label="'Field border color'"
                        :value="getFormSettings('field', 'border-color')"
                        @input="setFormSettings('field', 'border-color', $event)"
                    />
                  </div>

                </div>
              </div>
            </div>
          </element-settings-content-tab>

          <element-settings-content-tab :name="translate('Button', 'page-builder')">
            <div class="row">
              <div class="col-12">
                <craft-tab-pills
                    :label="'Button layout'"
                    :value="getFormSettings('form', 'button_layout')"
                    @input="setFormSettings('form', 'button_layout', $event)"
                    :options="layoutOptions"
                />
                <div class="row">
                  <div class="col-md-6">
                    <craft-input
                        :label="'Font size'"
                        :input-type="'number'"
                        :suffix="'PX'"
                        :value="getFormSettings('button', 'font-size').split('').slice(0, -2).join('')"
                        @input="value => setFormSettings('button', 'font-size', value+'px')"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker
                        :label="'Font color'"
                        :value="getFormSettings('button', 'color')"
                        @input="setFormSettings('button', 'color', $event)"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker
                        :label="'Background color'"
                        :value="getFormSettings('button', 'background-color')"
                        @input="setFormSettings('button', 'background-color', $event)"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-input
                        :label="'Border width'"
                        :input-type="'number'"
                        :suffix="'PX'"
                        :value="getFormSettings('button', 'border-width').split('').slice(0, -2).join('')"
                        @input="value => setFormSettings('button', 'border-width', value+'px')"
                    />
                  </div>
                  <div class="col-md-6">
                    <craft-color-picker
                        :label="'Border color'"
                        :value="getFormSettings('button', 'border-color')"
                        @input="setFormSettings('button', 'border-color', $event)"
                    />
                  </div>

                </div>
              </div>
            </div>
          </element-settings-content-tab>
        </element-settings-content-tabs>
      </element-settings-content-tab>

      <element-settings-content-tab v-if="isContactForm" :name="translate('Receivers', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-select
                :label="'Email receivers'"
                :options="[]"
                :value="getFormSettings('email', 'receivers') ? JSON.parse(getFormSettings('email', 'receivers')) : []"
                @input="ev => setFormSettings('email', 'receivers', JSON.stringify(ev))"
                :clearable="false"
                :taggable="true"
                :multiple="true"
                :reduce-data="false"
            >
              <template v-slot:no-options="{ search, searching }">
                {{translate('Type an email and hit enter.', 'page-builder')}}
              </template>
            </craft-select>

              <!-- <v-select multiple :options="users" label="email" taggable push-tags
                  :reduce="user => user.email"
                  @input="ev => setFormSettings('email', 'receivers', JSON.stringify(ev))"
                  :value="getFormSettings('email', 'receivers') ? JSON.parse(getFormSettings('email', 'receivers')) : []"
              /> -->
            <craft-toggle
                :label="'Enable auto reply'"
                id="enableAutoreply"
                :value="parseInt(getFormSettings('email', 'autoreply_enabled'))"
                @input="setFormSettings('email', 'autoreply_enabled', $event)"
            />
          </div>

          <div class="col-12">
              <transition name="fade">
                  <div v-show="parseInt(getFormSettings('email', 'autoreply_enabled')) === 1">
                    <craft-textarea
                        :label="'Auto reply text'"
                        :field-props="{errors: errors.email ? errors.email.autoreply_text : false}"
                        :value="getFormSettings('email', 'autoreply_text')"
                        @input="setFormSettings('email', 'autoreply_text', $event)"
                    />
                  </div>
              </transition>
          </div>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>

    <div class="text-right my-4">
        <!-- <a @click="$emit('closeSettingsPopup')" class="btn btn-cancel rounded-pill mr-2">{{ translate('Cancel', 'page-builder') }}</a> -->
        <a
            @click.prevent="saveForm"
            href="#"
            class="btn btn-primary rounded-pill"
        >{{ translate('Save changes', 'global') }}</a>
    </div>

        <!-- OLD settings left for reference
        <dropdown-heading/> =
         <div>
            <h5 class="node-styling--title d-flex align-items-center justify-content-between">
                <template v-if="!container">
                    {{ title }}
                    <i class="fa fa-chevron-down chevron"></i>
                </template>
                <div v-else class="container d-flex">
                    <p class="font-weight-bold m-0">{{title}}</p>
                    <i class="fa ml-auto fa-chevron-down chevron"></i>
                </div>
            </h5>
        </div>

        -->
        <!-- <dropdown-heading
            :title="translate('General', 'page-builder')"
            @click.native="showSettings.general = !showSettings.general"
        />
        <transition-expand>
            <div v-show="showSettings.general">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ translate('Action', 'page-builder') }}</label>
                                <input type="text" class="form-control" v-model="form.action" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition-expand>

        <dropdown-heading
            :title="translate('Email layout', 'page-builder')"
            @click.native="showSettings.emailLayout = !showSettings.emailLayout"
        />
        <transition-expand>
            <div v-show="showSettings.emailLayout">
                <div class="card-body">
                    <div class="mb-4">
                        <h5>{{ translate('Use following shortcodes to build up your contact form', 'page-builder') }}</h5>
                        <p>[shortcode-id-1] [shortcode-id-2] [shortcode-id-3]</p>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="formFromInput">{{ translate('From', 'page-builder') }}:</label>
                                <input
                                    type="text"
                                    id="formFromInput"
                                    class="form-control"
                                    @input="ev => setFormSettings('email', 'from', ev.target.value)"
                                    :value="getFormSettings('email', 'from')"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label
                                    for="emailFromInput"
                                >{{ translate('Send from email leave empty to send from website server', 'page-builder') }}</label>
                                <input
                                    type="text"
                                    id="emailFromInput"
                                    class="form-control"
                                    @input="ev => setFormSettings('email', 'from_email', ev.target.value)"
                                    :value="getFormSettings('email', 'from_email')"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="subjectInput">{{ translate('Subject', 'page-builder') }}</label>
                                <input
                                    type="text"
                                    id="subjectInput"
                                    class="form-control"
                                    @input="ev => setFormSettings('email', 'subject', ev.target.value)"
                                    :value="getFormSettings('email', 'subject')"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <textarea
                                    class="form-control"
                                    id="autoReplyTextInput"
                                    cols="30"
                                    rows="10"
                                    :value="formTemplate"
                                    @input="ev => setFormSettings('email', 'form_template', ev.target.value)"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition-expand>

        <dropdown-heading
            :title="translate('Email And Forwarders', 'page-builder')"
            @click.native="showSettings.emailAndForwarders = !showSettings.emailAndForwarders"
        />
        <transition-expand>
            <div v-show="showSettings.emailAndForwarders">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="receiversInput">{{ translate('Receivers (separated with comma)', 'page-builder') }}</label>
                                <v-select multiple :options="users" label="email" taggable push-tags
                                    :reduce="user => user.email"
                                    @input="ev => setFormSettings('email', 'receivers', JSON.stringify(ev))"
                                    :value="getFormSettings('email', 'receivers') ? JSON.parse(getFormSettings('email', 'receivers')) : []"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <div class="d-flex justify-content-between py-2">
                                    <label>{{ translate('Enable auto reply', 'page-builder') }}:</label>
                                    <div class="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            id="enableAutoreply"
                                            :checked="getFormSettings('email', 'autoreply_enabled')"
                                            class="custom-control-input"
                                            @input="ev => setFormSettings('email', 'autoreply_enabled', ev.target.checked)"
                                        />
                                        <label for="enableAutoreply" class="custom-control-label"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <transition name="fade">
                                <div v-show="getFormSettings('email', 'autoreply_enabled')">
                                    <div class="form-group">
                                        <label for="autoReplyTextInput">{{ translate('Auto reply text', 'page-builder') }}:</label>
                                        <textarea
                                            class="form-control"
                                            id="autoReplyTextInput"
                                            cols="30"
                                            rows="10"
                                            :value="getFormSettings('email', 'autoreply_text')"
                                            @input="ev => setFormSettings('email', 'autoreply_text', ev.target.value)"
                                        ></textarea>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </transition-expand>

        <dropdown-heading
            title="Button"
            @click.native="showSettings.button = !showSettings.button"
        />
        <transition-expand>
            <div v-show="showSettings.button">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="buttonTextInput">{{ translate('Button text', 'page-builder') }}</label>
                                <input
                                    type="text"
                                    id="buttonTextInput"
                                    class="form-control"
                                    @input="ev => setFormSettings('button', 'text', ev.target.value)"
                                    :value="getFormSettings('button', 'text')"
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ translate('Button width', 'page-builder') }}</label>
                                <v-select
                                    :options="[ 'Normal', 'Full Width']"
                                    @input="width => setFormSettings('button', 'width', width)"
                                    :value="getFormSettings('button', 'width')"
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ translate('Alignment', 'page-builder') }}</label>
                                <v-select
                                    :options="['Left', 'Center', 'Right']"
                                    @input="alignment => setFormSettings('button', 'alignment', alignment)"
                                    :value="getFormSettings('button', 'alignment')"
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ translate('Button type', 'page-builder') }}</label>
                                <v-select
                                    :options="['Round', 'Square']"
                                    @input="type => setFormSettings('button', 'type', type)"
                                    :value="getFormSettings('button', 'type')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition-expand>

        <dropdown-heading
            :title="translate('Redirect', 'page-builder')"
            @click.native="showSettings.redirect = !showSettings.redirect"
        />
        <transition-expand>
            <div v-show="showSettings.redirect">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="d-flex justify-content-between py-2">
                                    <label>{{ translate('Add redirect after submit', 'page-builder') }}:</label>
                                    <div class="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            id="hasRedirect"
                                            class="custom-control-input"
                                            @input="ev => setFormSettings('redirect', 'has_redirect', ev.target.checked)"
                                            :checked="getFormSettings('redirect', 'has_redirect')"
                                        />
                                        <label for="hasRedirect" class="custom-control-label"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <transition name="fade">
                            <div
                                v-show="getFormSettings('redirect', 'has_redirect')"
                                class="col-12"
                            >
                                <div class="form-group">
                                    <label for="redirectLinkInput">{{ translate('Link', 'page-builder') }}:</label>
                                    <input
                                        type="text"
                                        id="redirectLinkInput"
                                        class="form-control"
                                        @input="ev => setFormSettings('redirect', 'redirect_link', ev.target.value)"
                                        :value="getFormSettings('redirect', 'redirect_link')"
                                    />
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </transition-expand>

        <dropdown-heading
            :title="translate('Spam protect', 'page-builder')"
            @click.native="showSettings.spamProtect = !showSettings.spamProtect"
        />
        <transition-expand>
            <div v-show="showSettings.spamProtect">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="d-flex justify-content-between py-2">
                                    <label>{{ translate('Enable visible captcha', 'page-builder') }} </label>
                                    <div class="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            id="enableInvisibleCaptcha"
                                            class="custom-control-input"
                                            @input="ev => setFormSettings('spam_protect', 'invisible_captcha_enabled', ev.target.checked)"
                                            :checked="getFormSettings('spam_protect', 'invisible_captcha_enabled')"
                                        />
                                        <label
                                            for="enableInvisibleCaptcha"
                                            class="custom-control-label"
                                        ></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <transition name="fade">
                            <div
                                v-show="getFormSettings('spam_protect', 'invisible_captcha_enabled')"                            >
                                <div class="col-12">
                                    <p
                                        class="mb-0"
                                    >{{ translate('You need google authentication and fill in the data below.', 'page-builder') }}</p>
                                    <a href="#">{{ translate('Pick up my keys at Google', 'page-builder') }}</a>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="accountNameInput">{{ translate('Account name', 'page-builder') }}:</label>
                                        <input
                                            type="text"
                                            id="accountNameInput"
                                            class="form-control"
                                            @input="ev => setFormSettings('spam_protect', 'account_name', ev.target.value)"
                                            :value="getFormSettings('spam_protect', 'account_name')"
                                        />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="siteKeyInput">{{ translate('Sitekey', 'page-builder') }} (v3)</label>
                                        <input
                                            type="text"
                                            id="siteKeyInput"
                                            class="form-control"
                                            @input="ev => setFormSettings('spam_protect', 'site_key', ev.target.value)"
                                            :value="getFormSettings('spam_protect', 'site_key')"
                                        />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="secretKeyInput">{{ translate('Secret key', 'page-builder') }} (v3)</label>
                                        <input
                                            type="text"
                                            id="secretKeyInput"
                                            class="form-control"
                                            @input="ev => setFormSettings('spam_protect', 'secret_key', ev.target.value)"
                                            :value="getFormSettings('spam_protect', 'secret_key')"
                                        />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="text-right">
                                        <a href="#" class="btn-connect">{{ translate('Connect', 'page-builder') }}</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </transition-expand>

        <dropdown-heading
            :title="translate('Messages', 'page-builder')"
            @click.native="showSettings.messages = !showSettings.messages"
        />
        <transition-expand>
            <div v-show="showSettings.messages">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="successMessageInput">{{ translate('Success message', 'page-builder') }}:</label>
                                <input
                                    type="text"
                                    id="successMessageInput"
                                    class="form-control"
                                    @input="ev => setFormSettings('messages', 'success', ev.target.value)"
                                    :value="getFormSettings('messages', 'success')"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="failedMessageInput">{{ translate('Failed message', 'page-builder') }}:</label>
                                <input
                                    type="text"
                                    id="failedMessageInput"
                                    class="form-control"
                                    @input="ev => setFormSettings('messages', 'error', ev.target.value)"
                                    :value="getFormSettings('messages', 'error')"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="fieldRequiredMessageInput">{{ translate('Required field message', 'page-builder') }}:</label>
                                <input
                                    type="text"
                                    id="fieldRequiredMessageInput"
                                    class="form-control"
                                    @input="ev => setFormSettings('messages', 'required_field', ev.target.value)"
                                    :value="getFormSettings('messages', 'required_field')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition-expand>

        <dropdown-heading
            :title="translate('Layout', 'page-builder')"
            @click.native="showSettings.layout = !showSettings.layout"
        />
        <transition-expand>
            <div v-show="showSettings.layout">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 mb-4">
                            <h5>{{ translate('Form', 'page-builder') }}</h5>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ translate('Border style', 'page-builder') }}</label>
                                <v-select
                                    :options="['Solid', 'Dotted', 'Dashed']"
                                    :value="getFormSettings('form', 'border_style')"
                                    @input="style => setFormSettings('form', 'border_style', style)"
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ translate('Label layout', 'page-builder') }}</label>
                                <v-select
                                    :options="['Rounded', 'Square']"
                                    :value="getFormSettings('form', 'label_layout')"
                                    @input="layout => setFormSettings('form', 'label_layout', layout)"
                                ></v-select>
                            </div>
                        </div>
                        <div class="col-12 my-4">
                            <h5>{{ translate('Send button', 'page-builder') }}</h5>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ translate('Button font color', 'page-builder') }}</label>
                                <span
                                    class="color-picker"
                                    @click.stop="showSettings.buttonFontColor = !showSettings.buttonFontColor"
                                >
                                    <span
                                        :style="{ 'background-color': getFormSettings('button', 'color') }"
                                    />
                                    <input
                                        class="mx-2"
                                        :value="getFormSettings('button', 'color')"
                                    />
                                </span>
                                <color
                                    v-click-outside="showSettings.buttonFontColor"
                                    v-if="showSettings.buttonFontColor"
                                    :value="getFormSettings('button', 'color')"
                                    @input="({ hex }) => setFormSettings('button', 'color', hex)"
                                ></color>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="buttonFontSizeInput">{{ translate('Font size', 'page-builder') }}</label>
                                <input
                                    type="number"
                                    class="form-control"
                                    id="buttonFontSizeInput"
                                    :value="getFormSettings('button', 'font-size').split('').slice(0, -2).join('')"
                                    @input="ev => setFormSettings('button', 'font-size', ev.target.value + 'px')"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ translate('Label layout', 'page-builder') }}</label>
                                <v-select
                                    :options="['Bold', 'Italic', 'Normal']"
                                    :value="getFormSettings('button', 'font_style')"
                                    @input="style => setFormSettings('button', 'font_style', style)"
                                ></v-select>
                            </div>
                        </div>
                        <div class="col-12 my-4">
                            <h5>CSS</h5>
                        </div>
                        <div class="col-12">
                            <label for="customClassInput">{{ translate('Custom class', 'page-builder') }}</label>
                            <input
                                type="text"
                                id="customClassInput"
                                class="form-control"
                                :value="getFormSettings('form', 'custom_class')"
                                @input="ev => setFormSettings('form', 'custom_class', ev.target.value)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </transition-expand>

        <div class="text-right mt-3 px-3">
            <a href="#" class="btn btn-cancel rounded-pill mr-2">{{ translate('Cancel', 'page-builder') }}</a>
            <a
                @click.prevent="saveForm"
                href="#"
                class="btn btn-primary rounded-pill"
            >{{ translate('Save form', 'page-builder') }}</a>
        </div> -->
    </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import DraggableMixin from "@/mixins/DraggableMixin";

import TransitionExpand from '../../TransitionExpand'
import {CraftColorPicker, CraftInput, CraftSelect, CraftTabPills, CraftTextarea, CraftToggle} from "@/modules/pagebuilder/components/craft-inputs";
import uniqid from "uniqid";
import draggable from 'vuedraggable'
import FormField from "./components/FormField";

export default {
    inject: ['editor', 'node'],
    mixins: [ComponentSettingsMixin, CraftSettingsMixin, DraggableMixin],
    components: {
      FormField,
      CraftColorPicker, CraftInput, CraftSelect, CraftTabPills, CraftTextarea, CraftToggle,
      TransitionExpand,
      draggable
    },
    data() {
        return {
            key: 0,
            drag: false,
            form: {
                name: '',
                type: 'contact',
                method: 'POST',
                slug: '',
                enctype: null,
                action: null,
                company_id: this.$store.state.system.company.key,
                actions: {
                    create: true,
                    update: true,
                    delete: true,
                    locked: false
                },
                settings: [],
                default_properties: [],
            },
            errors: {},
            selectedInputTabs: [],
            // users: [],
            formId: null,
            fieldTypes: {
              text: {
                label: 'Text', // Input field
                icon: 'icon-erp-text-block',
              },
              email: {
                label: 'Email',
                icon: 'icon-erp-email',
              },
              tel: {
                label: 'Phone number',
                icon: 'icon-erp-phone',
              },
              date: {
                label: 'Date',
                icon: 'icon-erp-calendar',
              },
              textarea: {
                label: 'Textarea',
                icon: 'icon-erp-add-text',
              },
              select: {
                label: 'Dropdown',
                icon: 'icon-erp-chevron icon-erp-rotate-90',
              },
              // checkbox: {
              //   label: 'Checkbox',
              //   icon: 'icon-erp-checkbox'
              // },
              file_picker: {
                label: 'File picker',
                icon: 'icon-erp-files-and-folders',
              },
            },
        }
    },
    computed: {
        dragOptions() {
          return {
            animation: 200,
            group: "nav",
          }
        },
        // formTemplate() {
        //     return (
        //         this.getFormSettings('email', 'form_template') ||
        //         `From: ${this.getFormSettings(
        //             'email',
        //             'from'
        //         )}\nSubject: ${this.getFormSettings(
        //             'email',
        //             'subject'
        //         )}\n\nMessage area:\n`
        //     )
        // },
        formTypes() {
          return [
            { key: 'contact', label: 'Contact Form' },
            { key: 'newsletter', label: 'Newsletter' },
          ];
	    },
        // TODO Refactor - #15313854
        buttonAlignmentOptions() {
          return [
            {key: 'left', label: 'Left'},
            {key: 'center', label: 'Center'},
            {key: 'right', label: 'Right'}
          ];
	    },
        // END TODO
        buttonSizeOptions() {
          return [
            { key: '25%', label: 'Normal' },
            { key: '100%', label: 'Full width'},
          ]
        },
        buttonBehaviorOptions() {
          return [
            { key: 'message', label: 'Thank you message' },
            { key: 'link', label: 'Link to page'},
          ]
        },
        // form & button layout options
        layoutOptions() {
          return [
            { key: '1rem', label: 'Rounded' },
            { key: '0', label: 'Square' },
            { key: '3rem', label: 'Round' },
          ]
        },
        isContactForm() {
            return this.form.type === 'contact';
        },
        enctypeComputed(){
          if(this.form.default_properties.filter(field => field.type === 'file_picker').length > 0){
            return 'multipart/form-data'
          } else {
            return 'application/x-www-form-urlencoded'
          }
        }
    },
    async created() {
      this.formId = this.getSetting('id')

      if (this.formId) {
        const { data: formData } = await this.erp.ext.request.axiosInstance.get(
          `modules/forms/${this.formId}`
        );
        this.form = {... this.form, ...formData.data }

        this.form.default_properties.forEach((field,i) => {
          this.selectedInputTabs[i] = false;
          let attributes = {}
          field.attributes.forEach(f => {
            attributes = {...attributes, [f.key]: f.value }
          })
          field.attributes = attributes;
        })
      }
    },
    methods: {
        dragStart() {
          this.drag = true
        },
        dragEnd() {
          this.drag = false
          this.form.default_properties.forEach((field,i) => {
            field.position = i
          })
        },
        fieldTitle(field){
          if(field.label !== ''){
            return field.label
          }
          let readableType = this.fieldTypes[field.type]
          let type_fields = this.form.default_properties.filter(form_field => form_field.type === field.type && form_field.label === '')
          let order = type_fields.findIndex(type_field => type_field.position === field.position) + 1
          let placeholder = order < 2 ? readableType.label : readableType.label + " " + order
          return placeholder
        },
        changeFormType(event) {
            this.$set(this.form, 'type', event)
            this.setFormSettings('form', 'type', event);
        },
        getFormSettings(group, key) {
            const searchedSettings = this.form.settings.find(
                setting => setting.group === group && setting.key === key
            )

            if (!searchedSettings) return ''
            return searchedSettings.value
        },
        getFormType(type) {
            const item = this.formTypes.filter(t => t.key === type)
            return item.length ? item[0] : ''
        },
        setFormSettings(group, key, value) {
            const modifiedSetting = this.form.settings.find(
                setting => setting.group === group && setting.key === key
            )
            if (modifiedSetting) {
                modifiedSetting.value = value
            } else {
                this.form.settings.push({ group, key, value })
            }
            this.$forceUpdate();
        },
        validateFields(){
          this.errors = {};

          if(this.getFormSettings('form', 'button_behavior') === 'message'){
            if(this.getFormSettings('button', 'thankyou_message').length < 1){
              this.errors.button = {}
              this.errors.button.thankyou_message = ['This field is required'];
            }
          } else if(this.getFormSettings('form', 'button_behavior') === 'link'){
            if(this.getFormSettings('redirect', 'redirect_link').length < 1){
              this.errors.redirect = {}
              this.errors.redirect.redirect_link = ['This field is required'];
            }
          }

          if(parseInt(this.getFormSettings('email', 'autoreply_enabled')) === 1){
            if(this.getFormSettings('email', 'autoreply_text').length < 1){
              this.errors.email = {}
              this.errors.email.autoreply_text = ['This field is required'];
            }
          }
        },
        async saveForm() {
            let res;

            this.form.default_properties.forEach((field,i) => {
              if(field.attributes.placeholder === ''){
                delete field.attributes.placeholder;
              }
            })

            // hot fix settings without values
            this.form.settings.forEach((setting,i) => {
              if(typeof setting.value === 'undefined'){
                setting.value = ''
              }
            })

            this.form.enctype = this.enctypeComputed

            this.validateFields()
            if(Object.keys(this.errors).length > 0){
              return this.$toast.error('There are errors in the form')
            }

            if(this.formId) {
                res = await this.erp.ext.request.axiosInstance.put(`modules/forms/${this.formId}`, this.form).catch(error => this.$alert.formattedError(error));
            } else {
                this.form.slug = `${this.form.name.toLowerCase().replaceAll(' ', '-')}-${uniqid()}`;
                res = await this.erp.ext.request.axiosInstance.post('modules/forms',{...this.form}).catch(error => this.$alert.formattedError(error));
            }

            if(res) {
                this.formId = res.data.data.id;
                this.setSetting('id', this.formId)
                // todo refresh
                this.$toast.requestSuccess(false, 'Form')
            }
        },
        addField(fieldType) {
          let newField =  {
              label: '',
              name: '',
              type: fieldType,
              position: this.form.default_properties.length,
              width: '12',
              attributes: {
                placeholder: '',
                required: 0,
                // field_size: '100%' not used
              }
          }

          if (['multieselect', 'select'].includes(fieldType)) {
            newField.options = []
          }

          if(this.formId) {
              newField = {...newField, form_id: this.formId }
          }
          this.form.default_properties.push(newField);
          this.selectedInputTabs.push(true);
          this.$forceUpdate();
        },
        removeField(index) {
            this.form.default_properties.splice(index,1)
        },
    },
}
</script>

<style lang="scss" scoped>
.card {
    border-radius: 0;
    border-top: 1px solid $border-color;
    margin: 0;
    background: #fff; /* required for the drag and drop */
    .card-head {
        span {
            font-weight: bold;
            color: #000;
            margin-left: 20px;
        }
    }
}
.card:last-child {
  border-bottom: 1px solid $border-color;
}
.add-new-field {
    font-weight: bold;
    font-size: 14px;
    color: #000;
    margin-left: 1.5rem; /* same as .card */
    margin-bottom: 1.25rem; /* same as .card */
    display: inline-block;
    &:hover {
      cursor: pointer;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

// .btn-connect {
//     display: inline-block;
//     padding: 10px 32px;
//     border-width: 2px;
//     border-radius: 8px;
//     background-color: white;
//     border-style: solid;
//     border-color: #ff004f;
//     color: #ff004f;
// }
</style>
