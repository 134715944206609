<template>
    <div class="module-permissions" v-if="modules">
        <div class="_left">
            <div class="single-module" v-for="(module, mi) in modules" :key="mi" @click="getModuleColumns(mi)" :class="{active: selected_module === mi}">
                <span>{{module}}</span>
                <i class="fa ml-auto fa-chevron-down chevron-relative" :class="{'chevron-rotate': selected_module === mi}"></i>
            </div>
        </div>
        <div class="_right" v-if="selected_module">
            <div class="columns-permissions">
                <div class="selected-module">
                    <h5>{{selected_module}} {{ translate('Permissions', 'entities') }}</h5>
                    <div class="d-flex">
                        <div class="custom-control custom-switch" v-for="(perm, pi) in permissions">
                            <span style="padding-right: 70px">{{ translate('perm_' + pi, 'entities') }}</span>
                            <input type="checkbox"
                                   class="custom-control-input"
                                   :id="`permission-${perm}-${selected_module}-${pi}`"
                                   v-model="permissions[pi]">
                            <label class="custom-control-label" :for="`permission-${perm}-${selected_module}-${pi}`"></label>
                        </div>
                    </div>
                </div>
                <div v-for="(column, ci) in restrictions" class="single-column">
                    <div class="d-flex justify-content-between">
                        <span>{{column.property_name}}</span>
                        <div class="d-flex">
                            <div class="custom-control custom-switch" v-for="(perm, pi) in column.data">
                                <span style="padding-right: 70px">{{ translate('perm_' + pi, 'entities') }}</span>
                                <input type="checkbox"
                                       class="custom-control-input"
                                       :disabled="column.restriction === 'd' && !$store.getters['system/isAdmin']"
                                       @click="adjustPermission(column.data, pi, column.permission_id)"
                                       :id="`permission-${column.property_name}-${role.value}-${pi}`"
                                       v-model="column.data[pi]">
                                <label class="custom-control-label" :for="`permission-${column.property_name}-${role.value}-${pi}`"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-3">
                    <button @click="savePermissions" class="btn btn-primary rounded-pill">{{ translate('Save changes', 'global') }}</button>
                </div>
            </div>
        </div>
        <div class="w-75" v-else>
            <h4 class="text-center p-3">{{ translate('Please select model to adjust permissions and restrictions', 'entities') }}</h4>
        </div>
        <div class="_loader" v-if="loading">
            <i class="fa fa-spinner fa-spin fa-3x"></i>
        </div>
    </div>
</template>

<script>
import {db} from "@/utilities/database";

export default {
    name: "RolePermissions",
    data () {
        return {
            restrictions: {},
            permissions: {},
            selected_module: '',
            loading: false,
        }
    },
    props: {
        modules: {
            required: true
        },
        role: {
            required: true
        }
    },
    methods: {
        async getModuleColumns(module) {
            this.loading = true
            this.selected_module = module

            db.get(`/modules/roles/role-restrictions/${module}/${this.role.role_id}`).then(res => {
                this.restrictions = res.data.data.restrictions
                this.permissions = res.data.data.permissions

                this.restrictions.forEach(column => {
                    for (let restriction in column.data) {
                        if(column.restriction !== 'd') {
                            column.data.r = true
                            column.data.w = true
                        }
                        if(column.restriction === 'd') {
                            column.data.d = true
                        }
                    }
                })
                this.loading = false
            })

        },
        savePermissions() {
            this.loading = true
            let data = {
                permissions: this.permissions,
                restrictions: this.restrictions
            }
            db.post(`modules/roles/role-restrictions`, data).then(res => {
                this.$toast.requestSuccess('put', 'Permissions and restrictions') // explicit put
                this.restrictions = {}
                this.permissions = {}
                this.selected_module = ''
                this.loading = false
            }).catch(err => {
                this.$toast.errorGeneral()
                this.loading = false
            })
        },
        adjustPermission(perm, type, rule) {
            if(['r', 'w', 'd'].includes(type)){
                if(type == 'd' && perm.d == false) {
                    perm.w = false
                    perm.r = false
                }
                if(type == 'w' && perm.w == false) {
                    perm.r = true
                }
                if(type != 'd') {
                    perm.d = false
                }
                if(rule === 'r') {
                    perm.w = false
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.module-permissions {
    display: flex;
    position: relative;

    ._loader {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff80;
    }

    ._left {
        width: 25%;
        border-right: 1px solid #f2f2f2;

        .single-module {
            background-color: #ffffff;
            padding: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #f2f2f2;

            &.active {
                background-color: $primary;
                color: #fff;
            }

            .chevron-relative.chevron-rotate {
                transform: rotate(-90deg);
            }
        }
    }

    ._right {
        width: 75%;

        .columns-permissions {
            background-color: #ffffff;
            padding: 1rem;

            .selected-module {
                display: flex;
                padding-bottom: 1rem;
                justify-content: space-between;
                border-bottom: 1px solid #f2f2f2;
            }

            .single-column {
                padding: 0.3rem 0;
            }
        }
    }
}
</style>
