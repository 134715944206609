<template>
  <div class="row mb-3">
    <div class="col-12 d-flex justify-content-end">
      <button class="btn btn-primary rounded-pill" @click="performAction('export')">
        <i class="fas fa-file-export"></i> {{ translate('Export', 'entities') }}
      </button>
      <button class="btn btn-primary rounded-pill" @click="performAction('import')">
        <i class="fas fa-file-import"></i> {{ translate('Import', 'entities') }}
      </button>
    </div>

    <modal
      name="import-export-modal"
      :pivot-x="0.5"
      :pivot-y="0.3"
      height="auto"
      width="500px"
      transition="slide-top"
      classes="remove-modal-height"
      overlay-transition="wait"
      @before-close="exportEntity = false"
    >
      <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h6>{{ translate(`${exportEntity ? 'Export as JSON' : 'Import from JSON'}`, 'entities') }}</h6>

          <button type="button" class="close" @click="$modal.hide('import-export-modal')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="card-body form-group">
          <textarea v-if="exportEntity" v-model="plainData" class="form-control" readonly/>
          <textarea v-else v-model="importData" :placeholder="translate('Paste your JSON', 'entities')" class="form-control" />
        </div>
        <div class="card-footer d-flex justify-content-end">
          <button v-if="exportEntity" class="btn btn-primary rounded-pill" @click="copyContent">
            {{ translate('Copy to clipboard', 'entities') }}
          </button>
          <button v-else class="btn btn-primary rounded-pill" @click="save">
            {{ translate('Import', 'entities') }}
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "ImportExportEntity",
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      plainData: '',
      importData: '',
      exportEntity: false,
    }
  },
  methods: {
    performAction(action) {
      const { id, ...data } = this.value
      this.plainData = JSON.stringify(data, null, 2)
      if (action === 'export') {
        this.exportEntity = true
      }
      this.$modal.show('import-export-modal')
    },
    // todo refactor #4552213
    copyContent() {
      navigator.clipboard.writeText(this.plainData).then(() => {
        this.$modal.hide('import-export-modal')
        this.$toast.success('Content copied')
      })
    },
    save() {
      if (!this.importData || !this.importData.length) {
        this.$toast.error('Invalid input')
        return
      }
      this.$emit('import', this.importData)
      this.$toast.success('Content imported')
      this.$modal.hide('import-export-modal')
    },
  },
}
</script>

<style scoped lang="scss">
.form-group textarea {
  height: 300px;
}
</style>
