<template>
  <div id="builder" class="app-page">
    <div class="preview-title w-100 d-flex justify-content-center">
      <span>{{ translate('Preview mode', 'page-builder') }}</span>
    </div>
    <div class="app-page-preview" :emulator="pageWidth">
      <div id="dom" :class="pageWidth">
        <div v-if="componentData" class="card mt-5 mx-auto text-left">
          <div class="card-body card-grid text-black">
            <div class="d-flex align-items-center mx-auto justify-content-center">
              <div>
                <img
                  :src="image"
                  class="rounded-circle"
                  style="width: 110px; height: 110px; object-fit: cover"
                >
              </div>
              <div class="ml-3">
                <h5 class="mb-0 font-weight-bold">
                  {{ componentData.user_first_name }} {{ componentData.user_last_name }}
                </h5>
                <span>{{ componentData.company_name }}</span>
                <div class="text-warning" style="height: 20px">
                  <i
                    v-for="star in range(+componentData.rating)"
                    :key="star"
                    class="fa fa-star"
                  />
                </div>
              </div>
            </div>

            <div class="mt-4 text-center">
              <h5 class="font-weight-bold">
                {{ componentData.subject }}
              </h5>
              <p
                class="card-text"
                v-html="componentData.description"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EntityShowMixin from '../../mixins/EntityShowMixin'
import { range } from '../../utilities/helpers'

export default {
  name: 'TestimonialsShow',
  mixins: [EntityShowMixin],
  data() {
    return {
      entity: 'testimonials',
    }
  },
  computed: {
    ...mapState('layout', [
      'pageWidth',
    ]),
    image(){
      if(!this.data || this.data.user_image === null || !this.data.user_image)
        return '/uploads/defaults/image-placeholder.jpg'
      return this.data.user_image
    },
  },
  methods: {
    range,
  },
}
</script>

<style scoped lang="scss">
.preview-title {
    border-bottom: 1px solid #ccc;

    span {
        font-size: 40px;
        color: #ccc;
    }
}
</style>
