<template>
  <!-- classes like col-md-6 are automatically passed to this div -->
  <div v-show="typeof fieldProps.v_hide === undefined || !fieldProps.v_hide">

    <div :class="fieldClasses" class="form-group">
      <!-- LABEL -->
      <label class="form-control-label" v-if="showLabel && !['datatable', 'datatable_editable'].includes(this.fieldProps.type)">
        {{ translate(entityLabel(fieldProps.label, fieldProps.system_attribute == 1), 'entities') }}
        <span v-if="fieldProps.label && fieldProps.label_tooltip"
          v-tooltip="labelTooltip"
              class="m-3 cursor-pointer align-middle">
          <i class="fa-solid fa-circle-question" />
        </span>
        <template v-if="fieldProps.description">
          <br>
          <small class="text-muted">{{ fieldProps.description }}</small>
          <span v-if="fieldProps.description_tooltip"
                v-tooltip="descriptionTooltip"
                class="m-3 cursor-pointer align-middle">
            <i class="fa-solid fa-circle-question" />
          </span>
        </template>
      </label>

      <!-- SWITCH TYPES -->

      <!-- Text, Number, Int -->
      <input-field v-if="['text', 'number', 'int'].includes(fieldProps.type)"
         v-model="value"
         :field-props="fieldProps"
         :placeholder="placeholder"
         :entity="entity"
      />

      <!-- Text_responsive, Number_responsive, Int_responsive -->
      <input-responsive-field
        v-if="['text_responsive', 'number_responsive', 'int_responsive'].includes(fieldProps.type)"
        v-model="value"
        :field-props="fieldProps"
        :placeholder="placeholder"
        :entity="entity"
      />

      <!-- icon -->
      <icon-picker-field v-else-if="fieldProps.type == 'icon'" v-model="value" :field-props="fieldProps" />

      <!-- image -->
      <image-field v-else-if="fieldProps.type == 'image'"
        v-model="value"
        :field-props="fieldProps"
      />

      <!-- Image_advanced -->
      <image-advanced-field v-else-if="fieldProps.type == 'image_advanced'"
        v-model="value"
        :field-props="fieldProps"
      />


      <!-- hidden -->
      <hidden-field v-else-if="fieldProps.type == 'hidden'" v-model="value" :field-props="fieldProps" />


      <!-- select || multiselect -->
      <select-field v-else-if="(fieldProps.type == 'select' || fieldProps.type == 'multiselect')"
                    :name="fieldProps.name"
                    :placeholder="placeholder ? placeholder : ''"
                    v-model="value"
                    :field-props="fieldProps"
                    :data-filters="dataFilters"
                    @createOptions="v => $emit('createOptions', v)"
                    :apply-default-val="applyDefaultVal"
      />

      <!-- table multiselect  -->
      <table-select-field v-else-if="fieldProps.type == 'table_multiselect'"
                          :name="fieldProps.name"
                          v-model="value"
                          :field-props="fieldProps"
                          :data-filters="dataFilters"
                          @createOptions="v => $emit('createOptions', v)"
      />


      <!-- treeselect -->
      <treeselect-field v-else-if="fieldProps.type == 'treeselect'"
                        :name="fieldProps.name"
                        :placeholder="placeholder ? placeholder : ''"
                        v-model="value"
                        :field-props="fieldProps"
                        :data-filters="dataFilters"
      />


      <!-- visual_swatch  -->
      <visual-swatch-field v-else-if="fieldProps.type == 'visual_swatch'"
                           :name="fieldProps.name"
                           :placeholder="placeholder ? placeholder : ''"
                           v-model="value"
                           :field-props="fieldProps"
                           :data-filters="dataFilters"
                           @createOptions="v => $emit('createOptions', v)"
                           :apply-default-val="applyDefaultVal"
      />


      <!-- radio -->
      <radio-field v-else-if="fieldProps.type == 'radio'"
                   :name="fieldProps.name"
                   :class="['field-'+fieldProps.name.split('.')[1]]"
                   v-model="value"
                   :field-props="fieldProps"
                   :data-filters="dataFilters"
                   @createOptions="v => $emit('createOptions', v)"
                   :apply-default-val="applyDefaultVal"
      />


      <!-- Checkbox -->
      <checkbox-field v-else-if="fieldProps.type  == 'checkbox'"
                      v-model="value"
                      :field-props="fieldProps"
      />

      <!-- Textarea -->
      <textarea-field v-else-if="fieldProps.type == 'textarea'"
        v-model="value"
        :field-props="fieldProps"
        :entity="entity"
        :placeholder="placeholder ? placeholder : ''"
      />


      <!-- simple_textarea -->
      <textarea-simple-field v-else-if="fieldProps.type == 'simple_textarea'"
        v-model="value"
        :field-props="fieldProps"
        :entity="entity"
        :placeholder="placeholder ? placeholder : ''"
      />


      <!-- html_editor -->
      <html-editor-field v-else-if="fieldProps.type == 'html_editor'" v-model="value" :field-props="fieldProps" />


      <!-- date-picker  -->
      <date-picker-field v-else-if="pickerTypes.includes(fieldProps.type)" v-model="value" :field-props="fieldProps" />

      <!-- switch -->

      <!-- If you need this type add it to EditableInputFields

      <div class="custom-control custom-switch" v-else-if="fieldProps.type == 'switch'">
        <input type="checkbox"
          class="custom-control-input"
          :id="'switch-'  + '-' + entityIndex"
          value="true"
        >
        <label class="custom-control-label" :for="'switch-'  + '-' + entityIndex"></label>
      </div>

      -->

      <!-- page_builder -->
      <page-builder-field v-else-if="fieldProps.type == 'page_builder'" v-model="value" />

      <!-- color_picker -->
      <color-picker-field v-else-if="fieldProps.type == 'color_picker'" v-model="value" :field-props="fieldProps" />

      <!-- Percentage -->
      <range-field v-else-if="fieldProps.type == 'percentage'" v-model="value" :field-props="fieldProps"/>

      <!-- Range -->
      <range-field v-else-if="fieldProps.type == 'range'" v-model="value" :field-props="fieldProps"/>

      <!-- font_settings -->
      <font-settings-field v-else-if="fieldProps.type == 'font_settings'" v-model="value" :field-props="fieldProps" />


      <!-- font_and_media_settings -->
      <font-and-media-settings-field v-else-if="fieldProps.type == 'font_and_media_settings'" v-model="value" :field-props="fieldProps" />


      <!-- background_settings -->
      <background-settings-field v-else-if="fieldProps.type == 'background_settings'" v-model="value" :field-props="fieldProps" />


      <!-- file_picker -->
      <file-picker-field v-else-if="fieldProps.type == 'file_picker'" v-model="value" :field-props="fieldProps"/>


      <!-- usps_settings -->
      <usps-settings-field v-else-if="fieldProps.type == 'usps_settings'" v-model="value" :field-props="fieldProps"/>


      <!-- text_with_caution -->
      <text-with-caution-field v-else-if="fieldProps.type == 'text_with_caution'" v-model="value" :field-props="fieldProps" />

      <!-- DataTable -->
      <data-table-field
        v-else-if="fieldProps.type == 'datatable'"
        :field-props="fieldProps"
        :data-filters="dataFilters"
        @createOptions="v => $emit('createOptions', v)"
      />

      <!-- DataTable editable field -->
      <data-table-editable-field
        v-else-if="fieldProps.type == 'datatable_editable'"
        v-model="value"
        :field-props="fieldProps"
        :data-filters="dataFilters"
      />

      <!-- Image_settings -->
      <image-settings-field v-else-if="fieldProps.type == 'image_settings'" v-model="value" :field-props="fieldProps" />

      <!--  Gallery_field -->
      <gallery-field v-else-if="fieldProps.type == 'gallery'" v-model="value" :field-props="fieldProps" />

      <!-- JSON -->
      <json-field v-else-if="fieldProps.type == 'json'" v-model="value" :field-props="fieldProps" />

      <!-- file_upload -->
      <file-upload-field v-else-if="fieldProps.type == 'file_upload'" v-model="value" :field-props="fieldProps"/>

      <!-- URL Files -->
      <url-files-field v-else-if="fieldProps.type == 'url_files'" v-model="value" :field-props="fieldProps" />

      <!-- END SWITCH TYPES -->

      <small class="help text-danger" v-if="errorsFormatted" v-text="errorsFormatted"></small>

    </div>
  </div>
</template>
<script>
import { inputTypes } from '../../../utilities/helper'
import { validate } from '../../../utilities/validation/helper'
import bus from './../../../utilities/eventBus'

import * as Fields from '@/modules/erp_entities/components/page/form/fields'

export default {
  name: "InputEntityProps",
  components: {
    ...Fields,
    // if needed add webpackPrefetch like this: DataTableField: () => import(/* webpackPrefetch: true */ '@/modules/erp_entities/components/page/form/fields/DataTableField.vue'),
    DataTableField: () => import('@/modules/erp_entities/components/page/form/fields/DataTableField.vue'), // datatable must be lazy loaded because the filters use current file
    DataTableEditableField: () => import('@/modules/erp_entities/components/page/form/fields/DataTableEditableField.vue'), // datatable editable must be lazy loaded because the table use current file to show inputs
  },
  props: {
    value: {
      required: true,
    },
    fieldProps: {
      required: true,
    },
    placeholder: {
      required: false,
    },
    applyDefaultVal: {
      required: false,
      default: true,
      type: Boolean,
    },
    showLabel: {
      required: false,
      default: true,
      type: Boolean,
    },
    siblings: {
      required: false,
    },
    entity: { // required for fields with shortcodes
      required: false,
    },
    dataFilters: {
      type: Object,
      required: false,
    },
  },
  methods: {
    validate(){
      this.$set(this.fieldProps, 'errors', validate(this.value, this.fieldProps.rules))
    },
  },
  // TODO: move each input to it's own component so we dont mix functionalities
  async created(){
    try {

      // Remove stuck data in database and prevent wrong behaviour
      this.$set(this.fieldProps, 'errors', null)

    } catch(e){

    }
    // Listen for validation call
    bus.$on('validate', this.validate)
  },
  computed: {
    // #01247177 same as errorsFormatted in FormFieldMixing
    errorsFormatted() {
      return this.fieldProps.errors ? Object.values(this.fieldProps.errors).join(', ') : false
    },
    pickerTypes: () => Object.keys(inputTypes.pickers.subInputTypes),
    labelTooltip(){
      return this.translate(this.fieldProps.label_tooltip, 'entities')
    },
    descriptionTooltip(){
      return this.translate(this.fieldProps.description_tooltip, 'entities')
    },
    fieldClasses() {
      const classes = []

      if (this.fieldProps.type === 'checkbox' || this.fieldProps.type === 'group_checkbox') {
        classes.push(this.fieldProps.name)
      }

      if (this.fieldProps.rules && this.fieldProps.rules.some(rule => rule.rule === 'required')) {
        classes.push('required')
      }

      return classes.join(' ')
    },
  },
  watch: {
    'value': function(new_value, old_value){
      this.validate()
      this.$emit('input', this.value)
    },
  },
}
</script>
