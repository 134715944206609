<template>
    <div class="sidebar-new _fixed px-3" id="sidenav-main" v-if="authData && authData.company">
            <!-- Company / scope logo -->
            <div class="d-flex flex-column">
				<div class="sidebar-logo cursor-pointer mr-0">
					<div class="d-flex align-items-center" @click="envChange = !envChange; calcHeight($event);">
            <template v-if="$store.state.system.theme === 'blockzapp'">
              <img v-if="$store.state.system.interface === 'skitle'" alt="skitle" src="/img/skitle-new-logo.png">
              <img v-if="$store.state.system.interface === 'blockz'" alt="blockz" src="/img/blockz.jpg">
              <img v-if="$store.state.system.interface === 'sitecart'" alt="sitecart" src="/img/sitecart-logo.png">
              <i class="chevron fa fa-chevron-down" v-if="authData.environments.length > 1" :class="{'chevron-rotate' : envChange}"></i>
            </template>
            <template v-else>
              <img :alt="translate('Logo', 'global')" :src="`/img/themes/${$store.state.system.theme}/logo.png`">
            </template>
					</div>
				</div>
				<transition-expand>
					<div v-if="envChange">
						<div class="logo-wrappers">
							<div class="sidebar-logo mt-0" :class="{'m-0': authData.environments.length <= 1}">
								<div class="d-flex align-items-center">
									<div class="d-flex flex-column" v-if="$store.state.system.interface === 'skitle'">
										<img class="pb-2 cursor-pointer" @click="goToBlockz" v-if="authData.environments.includes('blockz')" alt="blockz" src="/img/blockz.jpg">
										<img class="py-2 cursor-pointer" @click="goToSitecart" v-if="authData.environments.includes('sitecart')" alt="sitecart" src="/img/sitecart-logo.png">
									</div>
									<div class="d-flex flex-column" v-if="$store.state.system.interface === 'blockz'">
										<img class="pb-2 cursor-pointer" @click="goToSkitle" v-if="authData.environments.includes('skitle')" alt="skitle" src="../../assets/images/skitle.png">
										<img class="py-2 cursor-pointer" @click="goToSitecart" v-if="authData.environments.includes('sitecart')" alt="sitecart" src="/img/sitecart-logo.png">
									</div>
									<div class="d-flex flex-column" v-if="$store.state.system.interface === 'sitecart'">
										<img class="pb-2 cursor-pointer" @click="goToSkitle" v-if="authData.environments.includes('skitle')" alt="skitle" src="../../assets/images/skitle.png">
										<img class="py-2 cursor-pointer" @click="goToBlockz" v-if="authData.environments.includes('blockz')" alt="blockz" src="/img/blockz.jpg">
									</div>
								</div>
							</div>
						</div>
					</div>
				</transition-expand>
                <div class="sidebar-scope" v-if="['skitle', 'sitecart', 'rock'].includes($store.state.system.interface) && scope">
                    <div class="scope">
                        <a class="scope-select" href="javascript:;" @click="showScope = !showScope">
                            {{ translate(`${$store.state.system.interface === 'skitle' ? 'Website' : 'Webshop'}`, 'sidebar') }}: {{ selectedScope.name }}
                            <i class="fa ml-auto fa-chevron-down chevron align"
                               :class="{'chevron-rotate': showScope === true}"></i>
                        </a>
                        <div class="mt-3" v-if="info">
                          <progress-bar :value="steps.complete" :total="steps.total"/>
                          <span>{{ translate('{completed} of {total} finished', 'dashboard', { completed: steps.complete, total: steps.total }) }}</span>
                        </div>
                    </div>
                </div>
            </div>


            <transition name="slide-in">
                <div class="links-container" v-if="scope && scope.data && showScope">
                    <div class="_inner">
                        <div class="_inner-header">
                            <h4 class="font-weight-700">
                                {{ translate(`${$store.state.system.interface === 'skitle' ? 'Websites' : 'Webshops'}`, 'sidebar') }}
                            </h4>
                            <button class="btn" @click="showScope = false"><span class="fa fa-times"></span></button>
                        </div>
                        <div class="_inner-container">
                            <ul class="reset-list">
                                <li class="column">
                                    <ul class="reset-list">
                                        <li class="sub-menu">
                                            <strong class="sub-menu-title">
                                                {{ translate(`Choose ${$store.state.system.interface === 'skitle' ? 'website' : 'webshop'}`, 'sidebar') }}
                                            </strong>
                                            <ul class="reset-list">
                                                <li v-for="(record, ri) in scope.data" :key="ri" class="sub-menu-item">
                                                    <a href="javascript:;" class="sub-menu-link"
                                                       @click="scopeByWebsite(record.id)">
                                                        {{ record.name }}
                                                    </a>
                                                </li>
                                                <li v-if="$store.state.system.theme !== 'trucks'" class="sub-menu-item mt-3">
                                                    <router-link class="btn rounded-pill add-new-website"
                                                                 to="/onboarding/identity"
                                                                 @click.native="showScope = false">
                                                        {{ translate(`Add new ${$store.state.system.interface === 'skitle' ? 'website' : 'webshop'}`, 'sidebar') }}
                                                        <i class="fa fa-plus"></i>
                                                    </router-link>
                                                </li>
                                                <li v-if="$store.state.system.theme !== 'trucks'" class="sub-menu-item mt-3">
                                                    <router-link class="btn rounded-pill add-new-website"
                                                                 :to="`/entity/cms-websites`"
                                                                 @click.native="showScope = false">
                                                        {{ translate('View all websites', 'sidebar') }}
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </transition>
        <div class="links-container_overlay" @click="showScope = false" v-if="showScope"></div>

        <!-- Company / scope logo end -->

            <!-- Application nav -->
            <nav class="sidenav-links">
                <!-- Dynamic rendering links. TODO: put it back when its discussed -->
                <sidebar-links :website_sidebar="showScope" @close="showScope = false" :value="navigation"></sidebar-links>
            </nav>
            <!-- Application nav end -->

            <!-- Account link -->
            <div class="sidebar-account border-top" v-if="$store.state.system.interface == 'skitle'">
                <router-link :to="`/my-account/${$store.state.system.authData.selected_company}/general`"
                             class="btn btn-primary rounded-pill w-100">
                    {{ translate('My Account', 'sidebar') }}
                </router-link>
            </div>
            <!-- End account link -->
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import SidebarLinks from './SidebarLinks'
import {scopeSystemByEnv, scopeSystemByWebsite} from '@/utilities/helper'
import {getNavigation} from '@/utilities/navigation'
import WebsiteProgress from '@/mixins/WebsiteProgress';
import {sidebarNavigation} from "@/utilities/sidebarNavigation";
import {erp} from "@/modules/erp_framework";
import store from "@/store";

export default {
    name: "Sidebar",
    mixins: [WebsiteProgress],
    components: {
        SidebarLinks
    },
    async mounted() {
        this.navigation = await getNavigation()
        setTimeout(function () {
            this.sidebarNavigation()
		}.bind(this), 2500)
	},
	data() {
		return {
			isActive: true,
			design: false,
			settings: false,
			logo: "",
			showScope: false,
			envChange: false,
      navigation: []
		}
	},
	methods: {
        sidebarNavigation,
		scopeByWebsite(website_id) {
          if(website_id === this.$store.state.system.scope.value){
            return
          }

          this.showScope = false
          document.getElementById('initial-loader').style.display = 'flex' // show initial-loader

          this.erp.ext.request.find('cms-websites', website_id)
              .then(res => {
                scopeSystemByWebsite(res.objectData)

                if(this.$router.currentRoute.path !== '/'){
                  this.$router.push('/')
                }
                document.getElementById('initial-loader').style.display = 'none' // hide initial-loader
            })
		},
    async goToBlockz() {
      scopeSystemByEnv('blockz')
			this.envChange = false
      this.navigation = await getNavigation()
			this.$router.push('/')
		},
    async goToSitecart() {
      scopeSystemByEnv('sitecart')
      this.envChange = false
      this.navigation = await getNavigation()
      this.$router.push('/')
    },
    async goToSkitle() {
      scopeSystemByEnv('skitle')
      this.envChange = false
      this.navigation = await getNavigation()
      this.$router.push('/')
    },
		calcHeight(event) {
			let sidebarLinks = this.$el.querySelector('.sidebar-new-links')
			let topBlock = event.currentTarget.parentElement.parentElement

			setTimeout(function () {
				let topBlockHeight = topBlock.offsetHeight
				sidebarLinks.style.height = `calc(100vh - ${topBlockHeight}px)`;
			}, 500)
		}
	},
	computed: {
		...mapGetters('system', [
			'canSwitchInterface'
		]),
		...mapState('system', ['authData', 'modules', 'company', 'scope']),
		selectedScope() {
			if (this.scope && this.scope.data && this.scope.value) {
				let scopeItem = this.scope.data.filter(item => item.id == this.scope.value)
				return scopeItem ? scopeItem[0] : 'not_found'
			}
			return {
				name: 'Select a website'
			}
		},
	},
	watch: {
		'scope': {
			deep: true,
			async handler(){
        this.navigation = await getNavigation()
			}
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
