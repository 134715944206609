<template>
  <div class="container dual-list-box mx-auto p-3 shadow-lg d-flex bg-white justify-content-between rounded">

    <dual-list-box-item
      :label="translate('Available columns', 'columns')"
      :columns="availableColumns"
      :selectedColumn="selectedColumn"
      :actions="['addColumn', 'removeColumn']"
      @selectColumn="selectedColumn = $event"
      @addColumn="addColumn"
      @removeColumn="removeColumn"
    />

    <dual-list-box-item
      :label="translate('Added columns', 'columns')"
      :columns="addedColumns"
      :selectedColumn="selectedColumn"
      :actions="['moveColumnUp', 'moveColumnDown']"
      @selectColumn="selectedColumn = $event"
      @moveColumnUp="moveColumn('up')"
      @moveColumnDown="moveColumn('down')"
    />

    <div class="position-absolute" style="right: 15px">
      <button class="btn-sm bg-transparent border-0" @click="$emit('closeModal')">
        <span class="icon-erp-cancel"></span>
      </button>
    </div>
  </div>
</template>

<script>
import { isVoidValue } from '@/utilities/helper'
import DualListBoxItem from "@/modules/erp_entities/components/page/table/DualListBoxItem.vue"

export default {
  name: "DualListBox",
  components: { DualListBoxItem },
  props: {
    // array <objects>
    allAvailableColumns: {
      required: true,
    },
    // array <strings>
    selectedColumnNames: {
      required: true,
    },
  },
  data() {
    return {
      columnNames: [],
      selectedColumn: null,
    }
  },
  computed: {
    availableColumns() {
      let cols = []
      this.allAvailableColumns.forEach(el => {
        if (!this.columnNames.includes(el.name)){
          cols.push(el)
        }
      })
      return cols
    },

    addedColumns() {
      let cols = []
      this.columnNames.forEach((col, idx) => {
        let item = this.allAvailableColumns.find(item => item.name == col)
        if(item){ // skip deleted or disabled columns
          item.order = idx
          cols.push(item)
        }
      })

      return cols.sort((a, b) => a.order - b.order)
    },
  },
  methods: {
    addColumn() {
      this.columnNames.push(this.selectedColumn)
      this.$emit('onColumnsChange', this.columnNames)
    },
    removeColumn() {
      let idx = this.columnNames.indexOf(this.selectedColumn)
      this.columnNames.splice(idx, 1)
      this.$emit('onColumnsChange', this.columnNames)
    },

    moveColumn(direction) {
      const idx = this.columnNames.indexOf(this.selectedColumn)
      if ((idx === 0 && direction === 'up') || (idx === this.columnNames.length - 1 && direction === 'down')) {
        return
      }

      const item = this.columnNames.splice(idx, 1)
      if (direction === 'up') {
        this.columnNames.splice(idx - 1, 0, ...item)
      } else {
        this.columnNames.splice(idx + 1, 0, ...item)
      }

      this.$emit('onColumnsChange', this.columnNames)
    },
  },
  created(){
    this.columnNames = this.selectedColumnNames
  },
}
</script>

<style lang="scss" scoped>
.dual-list-box {
  height: 450px;
}
</style>