<template>
  <!-- select || multiselect,  data_type = moduleNames -->
  <v-select
    v-model="model"
    label="value"
    :placeholder="placeholderComputed"
    :options="data || []"
    :disabled="isDisabled"
    :multiple="fieldProps.type == 'multiselect'"

    :get-option-label="(option) => entityLabel(option.value)"
    :reduce="value => value.key"
    class="select2-form"
    :class="[...fieldClasses, { 'loading': loading }]"

    @open="onFieldOpen"
    @close="onFieldClose"
    @search="onSearch"
  >
    <template #spinner>
      <i class="fa fa-spinner fa-spin" v-show="loading"></i>
    </template>

    <template v-if="hasOptionDataSlot" v-slot:option="option">
      <slot name="option-data" v-bind="option"></slot>
    </template>
    <template v-slot:no-options>{{ translate(noOptionsLabel, 'entities', { required_fields: missingFiltersNames.join(', ') }) }}</template>

    <template #list-footer>
      <li v-show="hasNextPage" ref="load" class="vs__dropdown-option" style="height: 1px">
<!--          <i class="fa fa-spinner fa-spin"></i> {{ translate('Loading more...', 'entities') }}-->
      </li>
    </template>
  </v-select>
</template>

<script>
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import { createEntityFormObjectNoTabs, isVoidValue } from "@/utilities/helper"
import debounce from "debounce"
import { cleanseInput } from "@/modules/erp_entities/utilities/helper"
import { applyShortcodes } from "@/modules/erp_entities/utilities/shortcodes"

export default {
  name: 'SelectDynamicField',
  mixins: [FormFieldMixin],
  props: {
    applyDefaultVal: {
      required: false,
      defaults: true,
    },
    dataFilters: {
      type: Object,
      required: false,
    },
    customRoute: {
      type: String,
      required: false,
    },
    hasOptionDataSlot: { // workaround because this.$slots does not contain "option-data"
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      data: null, // must be null because of cleanseInput, if it needs to be [] introduce other property that checks if data is loaded
      loading: false,
      observer: null,
      search: '',
      page: 1,
      meta: {
        last_page: 1,
      },
    }
  },
  computed: {

    // used for select, multiselect, treeselect, visual_swatch and radio
    model: {
      get() {

        // ON EDIT
        if (!isVoidValue(this.value)) {

          // Multiselect, Table multiselect, Visual swatch
          // all multislect values must be String, during import categories and attributes get Integer values sometimes
          if (['multiselect', 'table_multiselect', 'visual_swatch'].includes(this.fieldProps.type)) {

            try {
              // if this is the first time getting the value, convert the format
              if (typeof this.value !== "object") {
                // hot fix convert non array values into arrays
                if(!this.value.toString().startsWith('[')) {
                  this.value = `[${this.value}]`
                }

                this.value = JSON.parse(this.value)

                // remove void values and covert to proper type
                this.value = this.value.filter(element => !isVoidValue(element)).map(element => {
                  return element.toString()
                })

                // hot fix, options must contain the json parsed value
                this.$emit('input', this.value)
                // END hot fix, options must contain the json parsed value
              }
            } catch (e) {}

            // select, table_select
          } else if (['select', 'table_select'].includes(this.fieldProps.type)) {
            this.value = this.value.toString()
          }

          this.value = cleanseInput(this.value, this.data, this.fieldProps.type)
        }

        return this.value
      },
      set(v) {
        // todo check if setting this.value = v is still needed, usually on emit input it should get set
        this.value = v
        this.$emit('input', this.value)
      },
    },

    isDisabled(){
      // we also use "disabled" for read only because v-select does not support readonly
      return !this.canWrite
    },

    missingFilters() {
      let missingFilters = []
      if(this.fieldProps.data_filters && this.fieldProps.data_filters.length > 0) {
        this.fieldProps.data_filters.forEach(filter => {
          if(!this.dataFilters[filter] || !this.dataFilters[filter].value){
            missingFilters.push(filter)
          }
        })
      }
      return missingFilters
    },

    missingFiltersNames(){
      let res = []
      this.missingFilters.forEach(filter => {
        res.push(this.entityLabel(this.dataFilters[filter].label))
      })
      return res
    },
    noOptionsLabel(){
      if(this.fieldProps.data_filters &&
        this.fieldProps.data_filters.length > 0 &&
        this.missingFilters.length) {
        return 'Please select {required_fields} first.'
      }
      if(this.loading){
        return 'Loading...'
      }
      return 'Sorry, no matching options.'
    },

    hasNextPage() {
      return this.page < this.meta.last_page
    },

  },
  methods: {

    async getRecords(filterQuery){
      const route = this.customRoute ? this.customRoute : `modules/dropdowns/${this.fieldProps.data_module}`
      const result = await this.erp.ext.request.axiosInstance.get(`${route}?${filterQuery.toString()}`)

      if(this.customRoute){
        // Customers
        if(this.customRoute === 'customers'){
          result.data.data = result.data.data.reduce((acc, obj) => {
            acc.push({ key: obj.id.toString(), value: obj.full_name, rawObject: obj })
            return acc
          }, [])
        // Products
        } else if(this.customRoute === 'modules/inventory'){
          result.data.data = result.data.data.reduce((acc, obj) => {
            obj.options = createEntityFormObjectNoTabs(obj.options, 'key')
            acc.push({ key: obj.id.toString(), value: this.entityLabel(obj.options.name) + ' - '+obj.sku, rawObject: obj })
            return acc
          }, [])
        }
      }

      return result.data
    },

    loadDataDebounced: debounce(async function () {
      // todo test what will happen if you search in less than 0.7s on two select fields
      this.loadData()
    }, 700),

    async loadInitialData(){
      if(!isVoidValue(this.value)){
        let filterQuery = this.erp.ext.query()
        filterQuery.set('perpage', 999999)
        filterQuery.set('page', 1)
        filterQuery.where('id', 'in', this.value, true)

        const { data } = await this.getRecords(filterQuery)
        this.data = data
      }

      await this.loadData()
    },

    async loadData(){

      // set data only if there are no filters or all filters are filled
      if(!this.fieldProps.data_filters || this.fieldProps.data_filters.length === 0 || this.missingFilters.length === 0){

        let filterQuery = this.erp.ext.query()

        filterQuery.set('perpage', 20)
        filterQuery.set('page', this.page)
        if(this.fieldProps.data_filters && this.fieldProps.data_filters.length > 0){
          Object.values(this.fieldProps.data_filters).forEach(filter => {
            const condition = typeof this.dataFilters[filter].condition !== 'undefined' ? this.dataFilters[filter].condition : 'in' // used only in products select for now

            let conditionValue = this.dataFilters[filter].value
            if (condition === 'in' && !Array.isArray(conditionValue)) {
              conditionValue = [conditionValue]
            }

            filterQuery.where(filter, condition, conditionValue, this.dataFilters[filter].group)
          })
        }
        if(this.search){
          if(this.customRoute && this.customRoute === 'customers'){
            filterQuery.where('first_name', 'like', `%${this.search}%`, 'group1')
            filterQuery.where('last_name', 'like', `%${this.search}%`, 'group1')
          } else {
            filterQuery.where('name', 'like', `%${this.search}%`)
          }
        }

        const { data, meta } = await this.getRecords(filterQuery)

        if(meta){ // only for data_type = moduleNames
          this.meta.last_page = meta.last_page
        }

        // always keep loaded data
        if(!isVoidValue(this.data) && this.data.length){
          // combine unique values
          const dataMerged = this.data.concat(data.filter(function(i) {
            return this.map(e => e.key).indexOf(i.key) == -1 // this = this.data
          }, this.data ))

          this.data = dataMerged
        } else {
          this.data = data
        }

      } else { // otherwise reset data
        this.data = []
      }
      this.loading = false

    },

    watchFields(fieldsToWatch) {
      fieldsToWatch.forEach(el => {
        this.$watch(
          `dataFilters.${el}.value`, val => {
            this.data = [] // reset data on dataFilters change
            this.loadData()
          },
        )
      })
    },

    async onFieldOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onFieldClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        this.loading = true

        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.page += 1
        await this.loadData()

        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },

    onSearch(query) {
      this.loading = true
      this.search = query
      this.page = 1
      this.loadDataDebounced()
    },

    setDefaultValue(){
      if (this.fieldProps.default_value && this.data && this.data.length) {
        this.$emit('input', applyShortcodes(this.fieldProps.default_value))
      }

      // DEPRECATED
      // set first value as default
      /*
      if(this.data && this.data.length && parseInt(this.fieldProps.apply_default_value) === 1) {
        if(this.fieldProps.type === 'multiselect'){
          this.$emit('input', [this.data[0].key])
        } else {
          this.$emit('input', this.data[0].key)
        }
      }*/

    },
  },

  async created(){
    let fieldsToWatch = []
    if(this.fieldProps.data_filters && this.fieldProps.data_filters.length){
      this.watchFields(this.fieldProps.data_filters)
    }

    // throw nice js errors if backend is not configured properly, todo refactor #19281651
    if(!this.fieldProps.data_type){
      console.error('data_type on '+ this.fieldProps.name +' is required')
      return
    } else if (this.fieldProps.data_type === 'moduleNames' && !this.fieldProps.data_module){
      console.error('data_module on '+ this.fieldProps.name +' is required')
      return
    }

    await this.loadInitialData()

    if(this.applyDefaultVal && isVoidValue(this.value)) {
      this.setDefaultValue()
    }
  },

  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll) // try to move it above
  },

}
</script>

<style lang="scss">
.loading {
  .vs__open-indicator {
    display: none;
  }
}
</style>