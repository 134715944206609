<template>
  <div class="container-fluid">
    <div v-if="componentData && componentData.content_html" class="app-page-preview" :emulator="pageWidth">
      <render-html id="dom" :class="pageWidth" :html="componentData.content_html" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EntityShowMixin from '../../mixins/EntityShowMixin'

export default {
  name: 'SectionPreview',
  mixins: [EntityShowMixin],
  props: {
    value: {
      required: false,
    },
  },
  computed: {
    ...mapState('layout', [
      'pageWidth',
    ]),
  },
}
</script>