<template>
    <div class="container px-0">
        <div class="card rounded bg-white">
            <div class="card-header d-flex align-items-center justify-content-between">
                <h5>{{ translate('Account details', 'entities') }}</h5>

                <!-- B2B Only-->
                <template v-if="manually_approve_registration">

                  <div class="dropdown dropdown-styled dropdown-animate" data-toggle="hover">
                    <button class="btn btn-primary status-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="approvalStatusClass">
                      {{ approvalStatuses[value.approved] }}
                      <i class="fa fa-chevron-down"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                      <a href="#" class="dropdown-item" v-for="(title, key) in approvalStatuses" :key="key" @click="updateApprovalStatus(key)">
                        {{ translate(title, 'entities') }}
                      </a>
                    </div>
                  </div>
                </template>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('First name', 'entities') }} *</label>
                            <input type="text" name="first_name" class="form-control"
                                :class="{'is-invalid': errors && errors.hasOwnProperty('first_name')}"
                                v-model="value.first_name">
                                <span class="text-danger" v-if="errors && errors.hasOwnProperty('first_name')">{{errors.first_name}}</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('Last name', 'entities') }} *</label>
                            <input type="text" name="last_name" class="form-control"
                                :class="{'is-invalid': errors && errors.hasOwnProperty('last_name')}"
                                v-model="value.last_name">
                                <span class="text-danger" v-if="errors && errors.hasOwnProperty('last_name')">{{errors.last_name}}</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('Email', 'entities') }} *</label>
                            <input type="text" name="email" class="form-control"
                                :class="{'is-invalid': errors && errors.hasOwnProperty('email')}"
                                v-model="value.email">
                                <span class="text-danger" v-if="errors && errors.hasOwnProperty('email')">{{errors.email}}</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ translate('Phone', 'entities') }}</label>
                            <input type="text" name="phone" class="form-control"
                                v-model="value.phone">

                        </div>
                    </div>
                  <!-- moved to addresses
                  <div class="col-md-4">
                      <div class="form-group">
                          <label>{{ translate('Company name', 'entities') }}</label>
                          <input type="text" name="company_name" class="form-control"
                              v-model="value.company_name">
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group">
                          <label>{{ translate('VAT Number', 'entities') }}</label>
                          <input type="text" name="vat_number" class="form-control"
                              v-model="value.vat_number">
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group">
                          <label>{{ translate('Chamber of commerce', 'entities') }}</label>
                          <input class="form-control" v-model="value.chamber_of_commerce">
                      </div>
                  </div>
                  -->
                  <div class="col-md-4" v-if="websites">
                      <div class="form-group">
                          <label>{{ translate('Website', 'entities') }}</label>
                          <v-select :options="websites" :reduce="value => value.key" :value="value.website_id"
                                  @input="v => value.website_id = v" label="value" class="" disabled></v-select>
                      </div>
                  </div>
                  <div class="col-md-4" v-if="customer_groups">
                      <div class="form-group">
                          <label>{{ translate('Customer group', 'entities') }}</label>
                          <v-select :options="customer_groups" :reduce="value => value.key" :value="value.customer_group_id"
                                  @input="v => value.customer_group_id = v" label="value" class="select2-form"></v-select>
                      </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label>{{ translate('B2B User', 'entities') }}</label>
                      <div class="d-flex justify-content-between py-2">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            name="b2b_user"
                            id="b2b_user"
                            :true-value="1"
                            :false-value="0"
                            class="custom-control-input"
                            v-model="value.b2b_user"
                          />
                          <label for="b2b_user" class="custom-control-label"></label>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="d-flex justify-content-end">
          <router-link class="btn btn-white rounded-pill" to="/webshop-customers">
              {{ translate('Go back', 'global') }}
          </router-link>
          <button class="btn btn-primary rounded-pill" @click="save">
              <span v-if="$route.params.id">{{ translate('Save changes', 'global') }}</span>
              <span v-else>{{ translate('Create', 'global') }}</span>
          </button>
      </div>

  </div>

</template>

<script>
import {createSettingsForm} from "@/utilities/helper";
import {getWebsiteSettings} from "@/utilities/helpers/websiteSettings";

export default {
  name: "CustomerGeneralInfo",
  props: {
      value: {
          required: true
      }
  },
  data() {
      return {
          websites: null,
          customer_groups: null,
          manually_approve_registration: null,
          errors: {}
      }
  },
  computed: {
    approvalStatuses() {
      return {
        '-1': 'Declined',
        '0': 'Waiting approval',
        '1': 'Approved',
      }
    },
    approvalStatusClass(){
      switch (this.value.approved){
        case '-1': return 'declined'
        case '0': return 'waiting_approval'
        case '1': return 'approved'
      }
    }
  },
  async created() {
      let websites = await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/CmsWebsite?perpage=9999')
      this.websites = websites.data.data

      // Customer groups
      await this.erp.ext.request.axiosInstance.get('/modules/dropdowns/CustomerGroup?perpage=9999').then((res) => {
        this.customer_groups = res.data.data
      })

      // B2B settings
      const b2b_settings = await getWebsiteSettings('b2b-settings-cmswebsite')
      this.manually_approve_registration = Boolean(b2b_settings['registration.manually_approve_registration'])

  },
  methods: {
    async updateApprovalStatus(status) {
      if(status !== this.value.approved){
        let data = {
          approved: status
        }
        await this.erp.ext.request.axiosInstance.put(`/customers/approve/${this.$route.params.id}`, data)
            .then((res) => {
              this.$toast.requestSuccess('put', 'Customer')
              this.value.approved = status;
            })
            .catch(error => {
              this.$alert.formattedError(error)
            })
      }
    },
  async save() {

      this.validateFields();

      if(Object.keys(this.errors).length){
          return;
      }

      let data = {
          first_name: this.value.first_name,
          last_name: this.value.last_name,
          email: this.value.email,
          phone: this.value.phone,
          /* moved to addresses
          company_name: this.value.company_name,
          vat_number: this.value.vat_number,
          chamber_of_commerce: this.value.chamber_of_commerce,
          */
          customer_group_id: this.value.customer_group_id,
          b2b_user: this.value.b2b_user,
      }

      await this.erp.ext.request.axiosInstance.put(`/customers/${this.$route.params.id}`, data)
          .then((res) => {
            this.$toast.requestSuccess('put', 'Customer')
          }).catch(error => {
              this.$alert.formattedError(error)
          })

  },
  validateFields(){
      this.errors = {};
      if(!this.value.first_name.replace(/\s/g,'').length) {
          this.errors.first_name = 'This field is required';
      }
      if(!this.value.last_name.replace(/\s/g,'').length) {
          this.errors.last_name = 'This field is required';
      }
      if(!this.value.email.replace(/\s/g,'').length) {
          this.errors.email = 'This field is required';
      }
  },
  async findWebsite() {
    this.website = await ErpModel.find('cms-websites', this.$store.state.system.scope.value)
  }
}
}
</script>

<style scoped>

</style>
