export default {
    namespaced: true,
    state: {
        pageWidth: 'width-desktop',
        preview: false,
    },
    mutations: {
        SET_PAGE_WIDTH: (state, width) => {
            state.pageWidth = width
        }
    }
}
