<template>
<!--  <span class="ghost-label" v-if="!isSelected && hover && componentNameFormatted">{{ componentNameFormatted }}</span> MAKES PROBLEMS WITH COLUMNS -->
  <component
    :is="editor.findResolver(node.componentName)"
    v-bind="editor.enabled || editor.bindAllAttributes ? bindAttributes : bindExportAttributes"
    :class="[{ 'cf-node-selected': isSelected }, elementClasses]"

    :draggable="isDraggable"
    @dragstart.native="handleDragStart"
    @dragover.native="handleDragOver"
    @drop.native="handleDrop"
    @dragend.native.stop="handleDragEnd"
    @click.native="handleClickNode"

    @mouseover.native="hover = true"
    @mouseleave.native="hover = false"
  >
    <Node
      v-for="node in node.children" :key="node.uuid"
      :node="node"
    />
  </component>
</template>

<script>
// todo VUE 3, use fragments and remove the first span
import Node from '../core/Node'
import NodeService from '../core/services/NodeService'
import CraftElementDataMixin from "../../mixins/CraftElementDataMixin"
import busEvent from "@/utilities/eventBus"


export default {
  name: 'Node',
  mixins: [CraftElementDataMixin],
  props: {
    node: Node,
  },
  inject: [
    'editor',
  ],
  data() {
    return {
      nodeService: new NodeService(this),
      hover: false,
    }
  },
  computed: {
    dragDisabled() {
      return this.editor.hasTexteditorActive || this.editor.showSettingsPopup
    },
    isSelected() {
      return this.editor.enabled && this.node === this.editor.selectedNode
    },
    isDraggable() {
      if (this.editor.enabled && !this.dragDisabled) {
        return this.node.isDraggable()
      }

      // for export as html
      // this doesn't work somehow, with other attribute name it works as it should but not with "draggable"
      // for now hot-fix #1093617
      return null // omit draggable attribute
    },
    container() {
      return document.getElementById('page-content')
    },

    /*
    componentNameFormatted() {
      if(this.node.isCanvas()){
        if(this.node.props.component === 'MainContainer'){
          return false
        }
        return this.node.props.component;
      }
      return this.node.componentName
    }*/
  },
  provide() {
    return {
      node: this.node,
    }
  },
  methods: {
    cancelDefault(event) {
      event.stopPropagation()
      event.preventDefault()
    },
    handleDragStart(event) {
      event.stopPropagation()

      if (!this.editor.enabled) {
        // it is used to cancel dragging
        event.preventDefault()
        return
      }

      if(this.dragDisabled){
        return
      }

      this.editor.dragNode(this.node)
    },
    handleDragOver(event) {
      if(this.dragDisabled){
        return
      }

      this.cancelDefault(event)

      this.scrollPage(event)

      this.nodeService.handleDragOver({
        clientX: event.clientX,
        clientY: event.clientY,
      })
    },
    handleDrop(event) {
      if(this.dragDisabled){
        return
      }

      // if it is new component pre-select it
      if(this.editor.draggedNode.parent === null){
        this.editor.draggedNode.props.preSelectNode = true
      }

      this.cancelDefault(event)

      this.nodeService.handleDrop({
        clientX: event.clientX,
        clientY: event.clientY,
      })
    },
    handleDragEnd(event) {
      this.editor.handleDragEnd()
    },
    handleClickNode(event) {
      this.cancelDefault(event) // disable clicking on links
      
      if (this.nodeCanBeSelected()) {
        event.stopPropagation()

        this.editor.hasTexteditorActive = false
        if(this.editor.selectedNode && this.editor.selectedNode.uuid === this.node.uuid){
          this.toggleSettingsOrActions()
        } else {
          if (!this.editor.enabled) {
            return // don't call this.editor.selectNode if it is the same component
          }
          this.editor.selectNode(this.node)
        }

      }
    },

    toggleSettingsOrActions() {
      busEvent.$emit('rowToolsToggleSettings')
    },

    // blockzapp
    scrollPage(event){
      const mouseY = event.clientY
      const windowHeight = window.innerHeight
      const scrollThreshold = 200

      let scrollAmount = 0
      if (mouseY < scrollThreshold) {
        scrollAmount = - (scrollThreshold - mouseY)
      } else if (mouseY > windowHeight - scrollThreshold) {
        scrollAmount = mouseY - (windowHeight - scrollThreshold)
      }

      if(scrollAmount !== 0){
        this.container.scrollTop += scrollAmount / 20 // / 20 to make it smoother
      }
    },
    nodeCanBeSelected() {
      if (this.node.props.component === 'ContentSliderSlideElement') {
        return false
      }
      return true
    },
  },

  mounted() {
    // On add new node we want this node to get selected and tools to get open
    if(this.node.props.preSelectNode){
      this.editor.selectNode(this.node)
      delete this.node.props.preSelectNode
      setTimeout(() => { // does not work without timeout, because rowTools watch for editor.selectedNode and disable the settings
        this.toggleSettingsOrActions()
      }, 50)
    }
  },
}
</script>