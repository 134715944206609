import settingMixin from "../craft/components/settingMixin"
/* same as CraftSettingsMixin but reads and applies the changes to the elementStyle instead of settings */
export default {
  mixins: [settingMixin],
  methods: {
    styleExist(name) {
      return this.elementProps && this.elementProps.elementStyle && this.elementProps.elementStyle[name] !== undefined
    },
    getStyle(name, suffix = null, type = null) {

      let style = ''
      if (this.styleExist(name)) {
        style = this.elementProps.elementStyle[name]
      }

      // If suffix, replace it so only the value is returned, if value is available
      if (suffix) {
        // Percentage fix
        if (suffix == '%')
          suffix = /%/g

        style = style.replace(suffix, '')
      }

      if (type === 'background-image') {
        style = style.replace('url(', '').replace(')', '')
      }

      return style
    },

    setStyle(name, value, suffix = null) {

      if (value === '') {
        if (this.styleExist(name)) {
          delete this.elementProps.elementStyle[name]
          this.elementPropsSetter({
            elementStyle: {
              ...this.elementProps.elementStyle
            },
          })
        }
      } else {
        this.elementPropsSetter({
          elementStyle: {
            ...this.elementProps.elementStyle,
            [name]: suffix
              ? `${value}${suffix}`
              : value
          },
        })
      }
    },
  },
}
