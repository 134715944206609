import redirectIfAuthenticated from "./middlewares/redirectIfAuthenticated"
import authenticateFromLocalToken from './middlewares/authenticateFromLocalToken'
import layoutWatcher from "./middlewares/layoutWatcher"
import checkIfPageExist from "./middlewares/checkIfPageExist"

export default function middleware (router) {
  /**
   * Authenticate user from token stored in localStorage
   */
  authenticateFromLocalToken(router)

  /**
   * Redirect user to url by role
   */
  redirectIfAuthenticated(router)

  /**
   * Watch for layout changes
   */
  layoutWatcher(router)

  /**
   * If route doesn't exist go to 404 page
   */
  checkIfPageExist(router)
}
