<template>
  <div>
    <element-settings-content-tabs>

      <element-settings-content-tab :selected="true" :name="translate('General', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-input
                :label="'Button'"
                :value="getSetting('button_text')"
                @input="setSetting('button_text', $event)"
            />
          </div>

          <div class="col-12">
            <craft-input
                :label="'Button Link'"
                :value="getSetting('link')"
                @input="setSetting('link', $event)"
            />
          </div>

          <div class="col-12">
            <craft-tab-pills
                :label="'Link behavior'"
                :value="getSetting('link_target')"
                @input="setSetting('link_target', $event)"
                :options="generalLinkTargetOptions"
            />
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="'Layout'">
        <div class="row">
          <div class="col-12">
            <craft-tab-pills
                :label="'Button type'"
                :value="getStyle('border-radius')"
                @input="setStyle('border-radius', $event)"
                :options="borderRadiusOptions"
            />
          </div>
          <div class="col-12">
            <craft-input
              label="Button size"
              input-type="range"
              min="1"
              max="100"
              :value="getStyle('width', '%')"
              @input="setStyle('width', $event, '%')"
              suffix="%"
            />
          </div>
          <div class="col-12">
            <craft-tab-pills
                :label="'Button alignment'"
                :value="getStyle('margin')"
                @input="setStyle('margin', $event)"
                :options="buttonAlignmentOptions"
            />
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Icon', 'page-builder')">
        <div class="row">
          <div class="col-12">
            <craft-icon-picker
              :label="'Add icon'"
              :value="getSetting('icon')"
              @input="setSetting('icon', $event)"
            />
          </div>

          <div class="col-12">
            <craft-image-picker
              :label="'Or upload custom image'"
              :value="getSetting('icon_custom', '')"
              @input="setSetting('icon_custom', $event);"
            />
          </div>

          <div class="col-md-12">
            <craft-tab-pills
              :label="'Icon position'"
              :value="getSetting('icon_position')"
              @input="setSetting('icon_position', $event)"
              :options="iconPositionOptions"
            />
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Styling', 'page-builder')" :has-padding="false">

        <element-settings-content-tabs>

          <element-settings-content-tab :selected="true" :name="'Background'">
            <div class="row">
              <div class="col-md-6">
                <craft-color-picker
                    :label="'Background color'"
                    :value="getStyle('background-color')"
                    @input="setStyle('background-color', $event);"
                />
              </div>
              <div class="col-md-6">
                <craft-color-picker
                    :label="'Background hover color'"
                    :value="getSetting('background_hover_color')"
                    @input="setSetting('background_hover_color', $event)"
                />
              </div>
            </div>
          </element-settings-content-tab>

          <element-settings-content-tab :name="translate('Text', 'page-builder')">
            <div class="row">
              <div class="col-md-6">
                <craft-color-picker
                    :label="'Default text color'"
                    :value="getStyle('color')"
                    @input="setStyle('color', $event)"
                />
              </div>
              <div class="col-md-6">
                <craft-color-picker
                    :label="'Hover text color'"
                    :value="getSetting('text_hover_color')"
                    @input="setSetting('text_hover_color', $event);"
                />
              </div>
              <div class="col-md-6">
                <craft-input
                    :label="'Font size'"
                    :input-type="'number'"
                    :suffix="'PX'"
                    :value="getStyle('font-size', 'px')"
                    @input="setStyle('font-size', $event, 'px')"
                />
              </div>
            </div>
          </element-settings-content-tab>

          <element-settings-content-tab :name="translate('Border', 'page-builder')">
            <div class="row">
              <div class="col-md-6">
                <craft-select
                  :label="'Border style'"
                  :options="borderStyleOptions"
                  :value="getStyle('border-style')"
                  @input="setStyle('border-style', $event)"
                />

              </div>
              <div class="col-md-6">
                <craft-input
                    :label="'Border width'"
                    :input-type="'number'"
                    :suffix="'PX'"
                    :value="getStyle('border-width', 'px')"
                    @input="setStyle('border-width', $event, 'px')"
                />
              </div>
              <div class="col-md-6">
                <craft-color-picker
                    :label="'Border color'"
                    :value="getStyle('border-color')"
                    @input="setStyle('border-color', $event);"
                />
              </div>
              <div class="col-md-6">
                <craft-color-picker
                    :label="'Border hover color'"
                    :value="getSetting('border_hover_color')"
                    @input="setSetting('border_hover_color', $event);"
                />
              </div>
            </div>
          </element-settings-content-tab>

          <element-settings-content-tab :name="translate('Icon', 'page-builder')">
            <div class="row">
              <div class="col-md-6">
                <craft-color-picker
                  :label="'Icon color'"
                  :value="getSetting('icon_color')"
                  @input="setSetting('icon_color', $event)"
                />
              </div>
              <div class="col-md-6">
                <craft-color-picker
                  :label="'Icon hover color'"
                  :value="getSetting('icon_hover_color')"
                  @input="setSetting('icon_hover_color', $event)"
                />
              </div>
            </div>
          </element-settings-content-tab>

        </element-settings-content-tabs>

      </element-settings-content-tab>

    </element-settings-content-tabs>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import CraftStyleSettingsMixin from "@/modules/pagebuilder/mixins/CraftStyleSettingsMixin";
import CraftAttributesMixin from "@/modules/pagebuilder/mixins/CraftAttributesMixin";
import { CraftInput, CraftIconPicker, CraftImagePicker, CraftSelect, CraftColorPicker, CraftTabPills } from "@/modules/pagebuilder/components/craft-inputs"

export default {
  name: 'ButtonSettings',
  inject: ['editor', 'node'],
  mixins: [
    ComponentSettingsMixin,
    CraftSettingsMixin,
    CraftStyleSettingsMixin,
    CraftAttributesMixin,
  ],
  components: {
    CraftInput, CraftIconPicker, CraftImagePicker, CraftSelect, CraftColorPicker, CraftTabPills,
  },
  props: {
    value: {
      required: true,
    },
  },
  computed: {
    buttonAlignmentOptions() {
      return [
        {key: '0 auto 0 0', label: 'Left'},
        {key: '0 auto 0 auto', label: 'Center'},
        {key: '0 0 0 auto', label: 'Right'},
      ]
    },
    iconPositionOptions() {
      return [
        { key: 'left', label: 'Left' },
        { key: 'right', label: 'Right' },
      ]
    },
  },
}
</script>