<template>
  <div class="cms-block cms-block-row row flex-md-nowrap"
       :class="reverseColumnsClasses">
    <video-background v-if="getSetting('background_video_link')" />
    <slot />

    <div v-if="editor.enabled && !$slots.default" class="element-placeholder d-flex justify-content-center align-items-center flex-column w-100">
      <button class="start-button start-button-row">
        <i class="fas fa-plus fa-2x"></i>
      </button>
      <span class="mt-2">{{ translate('Please choose grid layout.', 'page-builder')}}</span>
    </div>

    <button v-if="editor.enabled && !this.editor.draggedNode" class="add_new_row" @click="addNewRow"><i class="fa fa-plus mr-2"></i>{{translate('Add row', 'page-builder')}}</button>
  </div>
</template>

<script>
import {createNodeFromComponentName} from "@/modules/pagebuilder/craft/utils/createNodeFromVNode";
import busEvent from "@/utilities/eventBus";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import VideoBackground from "@/modules/pagebuilder/components/components/VideoBackground.vue"

export default {
  components: { VideoBackground },
  mixins: [CraftSettingsMixin],
  inject: ['editor', 'node'],
  craft: {
    defaultProps: {
      settings: {
        grid_layout: null,
        reverse_columns_mobile: null,
        reverse_columns_tablet: null,

        // todo refactor #18764781, this code is duplicated
        background_video_link: null,
        background_video_start_time: null,
        background_video_end_time: null,
        background_video_loop: 0,
        background_video_opacity: '100%',
        background_video_overlay_color: '',
        background_video_overlay_opacity: '50%',
        // END todo refactor #18764781
      },
      elementStyle: {
        "padding-top": '30px',
        "padding-bottom": '30px',
        "align-items": "start",
      },
    },
    rules: {
      // Allow only columns to be placed in Row
      canMoveIn(incommingNode, thisNode) {
        if(incommingNode.componentName === 'Canvas' && incommingNode.props.component === 'ColumnElement'){
          return true
        }
        return false
      },
    },
  },
  computed: {
    reverseColumnsClasses() {
      if (this.getSetting('reverse_columns_mobile') || this.getSetting('reverse_columns_tablet')) {
        if (this.getSetting('reverse_columns_mobile') && this.getSetting('reverse_columns_tablet')) {
          return "flex-column-reverse flex-md-row-reverse flex-lg-row"
        } else if (this.getSetting('reverse_columns_mobile')) {
          //return "flex-column-reverse flex-md-row flex-lg-row"
          return "flex-column-reverse flex-sm-column flex-md-row"
        } else if (this.getSetting('reverse_columns_tablet')) {
          return "flex-sm-column-reverse flex-md-row-reverse flex-lg-row"
        }
      }
      return ''
    },
  },
  methods: {
    addNewRow() {
      const row = createNodeFromComponentName(this.editor, 'RowElement')
      row.insertAfter(this.node)

      setTimeout(() => { // does not work without timeout
        this.editor.selectNode(row)

        setTimeout(() => { // does not work without timeout
          busEvent.$emit('rowToolsToggleSettings')
        }, 10)

      }, 10)
    },
  },
  /* works but moved in RowSettings
  watch: {
    'node.props.settings.grid_layout': {
      deep: true,
      handler(v) {
        console.log('grid_layout changed to '+ v)
      }
    }
  },
  */
};
</script>