<template>
  <treeselect
    v-model="model"
    :multiple="multiple"
    :placeholder="placeholderComputed"
    :normalizer="normalizer"
    :value-consists-of="'ALL'"
    :close-on-select="!multiple"
    :flat="multisite"
    :defaultExpandLevel="multisite ? 1 : 10"
    clearable
    :disabled="isDisabled"
    :class="{'vue-treeselect--multisite': multisite}"
    :options="treeselectOptions"
    :matchKeys="matchKeys"
    :disableFuzzyMatching="true"
  >

    <template #value-label="{ node }">
      <template v-if="node.isFallbackNode">
        {{ translate('Uncategorized', 'entities') }}
      </template>
      <template v-else>
        <title-with-shortcodes :value="entityLabel(node.label)"></title-with-shortcodes>
      </template>
    </template>
    <template #option-label="{ node, shouldShowCount, count, labelClassName, countClassName }">
      <label :class="labelClassName"><title-with-shortcodes :value="entityLabel(node.label)"></title-with-shortcodes></label>
    </template>
  </treeselect>
</template>

<script>
// Ignore: [Vue-Treeselect Warning] You are using flat mode. But you forgot to add "multiple=true"?
// We need a way to allow selecting descendants

/*
 * disableFuzzyMatching - disabled because we join all translations in labelAsString
 * it can be enabled again if we have only the current label and call normalizer again on locale change
 */

import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin";
import FormSelectMixin from "@/modules/erp_entities/mixins/FormSelectMixin";

// Treeselect
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import TitleWithShortcodes from "@/modules/erp_entities/components/utilities/TitleWithShortcodes";

export default {
  name: 'TreeselectField',
  mixins: [FormFieldMixin, FormSelectMixin],
  components: {
    Treeselect, TitleWithShortcodes,
  },
  data(){
    return {
      normalizer(node) {
        return {
          id: node.key,
          label: node.value,
          labelAsString: typeof node.value === 'object' ? Object.values(node.value).join(' ') : node.value,
          isDisabled: typeof node.isDisabled != 'undefined' ? node.isDisabled : false
        }
      },
    }
  },
  props: {
    // only for parent category in categories page and in blog author we pass false,
    // since we don't have a fieldProps.multiple we use component prop
    multiple: {
      required: false,
      default: true,
    },
    // only for todo write comment we pass false,
    // since we don't have a fieldProps.multisite we use component prop
    // all fields created with type treeselect are categories treeselect at the moment which are multisite
    multisite: { // todo remove me and make me computed, use data_type if it is treeselectCategory it is multisite
      required: false,
      default: true,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    treeselectOptions() {
      if(!this.data){
        return []
      }

      // custom format for multisite dropdowns
      if(this.multisite){

        let data_formatted = []
        this.data.forEach((el, key) => {
          let item = {
            key: 'disabled-'+key,
            value: this.data[key].name,
            isDisabled: true,
            children: [],
          }

          this.data[key].values.forEach(category => {
            item.children.push(category)
          })

          data_formatted.push(item)
        })

        return data_formatted
      }

      return this.data
    },
    matchKeys() {
      // default is ['label'] but it does not work with translatable labels
      return ['labelAsString']
    },
    isDisabled() {
      return !this.canWrite
    },
  },

}
</script>

<style lang="scss">
.vue-treeselect--multisite {
  .vue-treeselect__indent-level-0 {
    > .vue-treeselect__option--disabled {
      .vue-treeselect__option-arrow-container, .vue-treeselect__checkbox-container {
        display: none;
      }
    }
  }
}
</style>