<template>

  <span>
    <template v-if="debugMode">
      <Export />
      <hr />
      <SettingPanel />
    </template>

    <!-- #pb-wrapper is used as <html> -->
    <div id="pb-wrapper">
      <!-- #pb-wrapper-body is used as <body> -->
        <div id="pb-wrapper-body">
          <!-- id="app" is required because of some frontend styles -->
          <Frame component="div" class="preview-panel pb-wrapper" id="app" :key="renderKey"/>
      </div>
    </div>

    <RowTools/>
  </span>




</template>

<script>
import {
  Canvas, Frame, Blueprint,
} from '../craft';

import SettingPanel from './components/SettingPanel.vue';
import Export from './components/Export.vue';

import RowTools from './RowTools'

export default {
  inject: ['editor'],
  components: {
    Canvas, Frame, SettingPanel, Blueprint, Export,
    RowTools
  },
  props: {
      value: {
        required: true
      },
  },
  computed: {
    renderKey(){
      return 'craft-'+this.editor.renderKey
    },
    debugMode(){
      return this.$route.query.debug ? true : false
    }
  },
}
</script>
