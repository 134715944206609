<template>
  <div class="cms-block cms-block-separator">
    <div style="position:relative;">
      <div class="separator-text" :style="textStyle">{{ getSetting('separator_text') }}</div>
      <div class="separator-border" :style="borderStyle"></div>
    </div>
  </div>
</template>

<script>
import CraftSettingsMixin from "../../../mixins/CraftSettingsMixin";

export default {
  mixins: [CraftSettingsMixin],
  inject: ['editor', 'node'],
  craft: {
    defaultProps:{
      settings:{
        separator_type: 'with_text',
        separator_text: 'Separator Text',
        text_alignment: 'center',
        border_bottom_color: '#808080',
        border_bottom_style: 'solid',
        border_bottom_width: '3px',
      },
      elementStyle: {
        "width": '100%',
        "margin": '0 auto 0 0',
        "padding-top": '15px',
        "padding-bottom": '15px',
      }
    }
  },
  methods: {
    alignmentToCss(value) {
      if(value === 'left'){
        return 'right: 100%; transform: translate(100%, -50%);'
      } else if (value === 'center'){
        return 'right: 50%; transform: translate(50%, -50%);'
      } else if (value === 'right'){
        return 'right: 0%; transform: translate(0%, -50%);'
      }
    },
  },
  computed:{
    textStyle() {
      return 'background-color: #f8fafc; display: inline-block; position: absolute;top: 50%;padding: 0 10px; white-space: nowrap;'
          + `display: ${this.getSetting('separator_type') === 'with_text' ? 'inline-block': 'none'};`
          + `${this.alignmentToCss(this.getSetting('text_alignment'))};`;
    },
    borderStyle(){
      return `border-bottom-width: ${this.getSetting('border_bottom_width')};`
          + `border-bottom-color: ${this.getSetting('border_bottom_color')};`
          + `border-bottom-style: ${this.getSetting('border_bottom_style')};`;
    }
  }
};
</script>