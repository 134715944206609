import localization from '@/localization/all'
import store from '@/store'
import moment from 'moment'

import _ from 'lodash'
import { erp } from '@/modules/erp_framework'
import app from '@/main'
import { v4 as uuid } from 'uuid'

const pluralize = require('pluralize')

export const environment = {
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
  client_id: process.env.VUE_APP_CLIENT_ID,
  api_domain: process.env.VUE_APP_API_DOMAIN,
  grant_type: 'password',
  google_fonts_api: process.env.VUE_APP_google_fonts_key,
}

export function fileManagerSettings() {
  return {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('authorizationToken')}`,
      user_id: store.state.system.authData.user_id,
      company: store.state.system.company.key,
      website: store.state.system.scope.value,
      package: 'Cms',
    },
    baseUrl: `${environment.api_domain}/file-manager`,
    // not sure if any is needed
    lang: store.state.system.locale, // this was in the File Manager modal
    locale: store.state.system.locale, // this was in the Media library
    // END not sure if any is needed
  }
}

/**
 * Change theme color in html and save in storage
 *
 * @export
 * @param {Object} [newTheme=null]
 */

export function setThemeColor(themeType) {

  let root = document.documentElement
  for (const color in themes[themeType].colors) {
    root.style.setProperty(`--${color}`, themes[themeType].colors[color])
  }

}

// Leave old theme variables for a while, todo remove them at the end of 2023
export const themes = {
  blockzapp: {
    name: 'blockzapp',
    colors: {
      'primary-color': '#FF0553',
      'primary-offset-color': '#f9fbfd',
      'primary-panel-color': '#fff',
      'primary-panel-text-color': '#95aac9',
      'primary-panel-offset-color': '#fff',
      'primary-text-color': '#000',
      'primary-text-hover-color': '#FF0553',
      'primary-btn-color': '#FF0553',
      'header-color': '#fff',
      'header-text-color': '#000',
      'sidebar-color': '#fff',
      'sidebar-text-color': 'black',
      'sidebar-hover-color': '#ff055330',

      'secondary-color': '#eff2f7',
      'secondary-offset-color': '#d6ddea',
      'secondary-text-color': '#273444',
      'secondary-text-hover-color': '#273444',


      'white-text': '#fff',
      'border-color': '#e5edf5',
      // 'border-offset-color': '#3a547d',
    },
  },
  trucks: {
    name: 'trucks',
    colors: {
      'primary-color': '#30485D',
      'primary-offset-color': '#f9fbfd',
      'primary-panel-color': '#fff',
      'primary-panel-text-color': '#95aac9',
      'primary-panel-offset-color': '#fff',
      'primary-text-color': '#000',
      'primary-text-hover-color': '#FF0553',
      'primary-btn-color': '#FF0553',
      'header-color': '#fff',
      'header-text-color': '#000',
      'sidebar-color': '#fff',
      'sidebar-text-color': 'black',
      'sidebar-hover-color': '#ff055330',

      'secondary-color': '#FFCD00',
      'secondary-offset-color': '#d6ddea',
      'secondary-text-color': '#273444',
      'secondary-text-hover-color': '#273444',


      'white-text': '#fff',
      'border-color': '#e5edf5',
      // 'border-offset-color': '#3a547d',
    },
  },
  rock: {
    name: 'rock',
    colors: {
      'primary-color': '#80b5a0',
      'primary-offset-color': '#f9fbfd',
      'primary-panel-color': '#fff',
      'primary-panel-text-color': '#95aac9',
      'primary-panel-offset-color': '#fff',
      'primary-text-color': '#000',
      'primary-text-hover-color': '#80b5a0',
      'primary-btn-color': '#80b5a0',
      'header-color': '#fff',
      'header-text-color': '#000',
      'sidebar-color': '#293e40',
      'sidebar-text-color': 'black',
      'sidebar-hover-color': '#80b5a030',

      'secondary-color': '#eff2f7',
      'secondary-offset-color': '#d6ddea',
      'secondary-text-color': '#273444',
      'secondary-text-hover-color': '#273444',


      'white-text': '#fff',
      'border-color': '#e5edf5',
      // 'border-offset-color': '#3a547d',
    },
  },
}

/**
 * Change theme in html and save in storage
 *
 * @export
 * @param {Object} [newLayout=null]
 */
// DEPRECATED
/*
export function setLayout(newLayout = null) {
    let layout = newLayout ? newLayout : JSON.parse(window.localStorage.getItem('layout'))

    if(!layout) {
        layout = layouts.default
    }

    if(newLayout) {
        window.localStorage.setItem('layout', JSON.stringify(newLayout))
    }

    store.state.system.theme = layout
}

export const layouts = {
    default: 'default',
    blockzapp: 'blockzapp'
}
*/

/*
*  Permission helpers
*/

export function createPropertyRestrictionsObject(roles) {
  const defaultRestrictions = { r: 0, w: 0, d: 1 }
  const defaultAdminRestrictions = { r: 1, w: 1, d: 0 }

  let permissions = []

  for (const role of roles) {
    // role.key:1 = admin
    permissions.push({
      role_id: role.key,
      data: role.key === '1' ? defaultAdminRestrictions : defaultRestrictions,
    })
  }

  return permissions
}

export function can(action, entity_slug) {
  if (store.state.system.entitiesPermissions === null || !store.state.system.entitiesPermissions[entity_slug]) {
    return false
  }

  return parseInt(store.state.system.entitiesPermissions[entity_slug][action]) !== 0
}

export function canAll(actions, entity) {
  return actions.every(action => can(action, entity))
}

export function canAny(actions, entity) {
  return actions.some(action => can(action, entity))
}

export function getEntityName(entity_slug) {
  const entities = JSON.parse(localStorage.getItem('entities'))

  if (entities && entities[entity_slug]) {
    return entities[entity_slug].name
  }

  return null
}

export function getEntityModel(entity_slug) {
  const entities = JSON.parse(localStorage.getItem('entities'))

  if (entities && entities[entity_slug]) {
    return entities[entity_slug].model
  }

  return null
}

/*
*  Entity options helpers
*/

// used in both erp-front and erp-front-entities
export function createEntityFormObject(entities, key = 'name') {
  let newEntityObject = {}
  for (let tab in entities) {
    for (const entity of entities[tab]) {
      newEntityObject[entity.name] = entity.value
      if (typeof newEntityObject[entity.name] === 'undefined') {
        newEntityObject[entity.name] = null
      }
    }
  }

  return newEntityObject
}

// todo rename to createEntityFormObject and remove the function above
export function createEntityFormObjectNoTabs(entities, key = 'name', valueKey = 'value', parse = true) {
  let newEntityObject = {}

  for (const entity of entities) {
    let val = null
    try {
      val = (parse && typeof entity[valueKey] === 'string') ? JSON.parse(entity[valueKey]) : entity[valueKey]
    } catch {
      val = entity[valueKey]
    }

    let field_key = entity[key]

    newEntityObject[field_key] = val
    if (typeof val === 'undefined') {
      newEntityObject[field_key] = null
    }
  }

  return newEntityObject
}

// used in both erp-front and erp-front-entities
export function createEntityTypesObject(entities) {
  let newEntityObject = {}
  for (let tab in entities) {
    for (const entity of entities[tab]) {
      newEntityObject[entity.name] = entity.type
    }
  }

  return newEntityObject
}

// todo rename to createEntityTypesObject and remove the function above
export function createEntityTypesObjectNoTabs(entities, key = 'name') {
  let newEntityObject = {}
  for (const entity of entities) {
    newEntityObject[entity[key]] = entity.type
  }

  return newEntityObject
}

/*
* Locales helper
*/

// used in both erp-front and erp-front-entities
export function locales() {
  let sysLocales = {}
  let locales = Object.keys(localization)
  locales.splice(0, 1)
  for (let locale of locales) {
    sysLocales[locale] = localization[locale].name
  }

  return sysLocales
}

export function localesDropdown() {
  let localesDropdown = []

  let locales = Object.keys(localization)
  locales.splice(0, 1)
  for (let locale of locales) {
    localesDropdown.push({ key: locale, label: localization[locale].name })
  }

  return localesDropdown
}

// used in both erp-front and erp-front-entities
export function changeLocale(locale) {
  moment.locale(locale)
  store.state.system.locale = locale
}

export async function allLocales() {
  let allLocales = []
  await erp.ext.request.axiosInstance.get('/modules/dropdowns/Locale?perpage=9999').then(res => {
    for (let locale of res.data.data) {
      allLocales.push({ key: locale.key, name: locale.value })
    }
  })

  return allLocales
}

// DEPRECATED, USE store.state.system.scope.obj.website_locales
export function translationLocales() {
  // hot fix because on first load website data is not available yet
  if (store.state.system.scope.obj === null) {
    return false
  }

  return store.state.system.scope.obj.website_locales
}

export function hasManyTranslationLocales(){
  // hot fix because on first load website data is not available yet
  if (store.state.system.scope === null || store.state.system.scope.obj === null) {
    return false
  }

  return Object.keys(store.state.system.scope.obj.website_locales).length > 1
}

export function changeTranslationLocale(locale) {

  // moment.locale(locale) todo ask Niki why this was here
  store.state.system.translation_locale = locale
  window.localStorage.setItem('translation_locale', locale)
  scopeSystemByLocale(locale)
}

export function scopeSystemByLocale(locale) {
  erp.ext.request.axiosInstance.defaults.headers.common.locale = locale

  return true
}

/*
*   Random helpers
*/

export function scopeSystemByEnv(env) {
  if(!env){
    delete erp.ext.request.axiosInstance.defaults.headers.common['env']
    store.state.system.interface = ''
    window.localStorage.removeItem('interface')
  } else {
    erp.ext.request.axiosInstance.defaults.headers.common['env'] = env
    store.state.system.interface = env
    window.localStorage.setItem('interface', env)
  }
}

export function scopeSystemByCompany(company) {
  window.localStorage.setItem('company', JSON.stringify(company))
  erp.ext.request.axiosInstance.defaults.headers.common.company = company.key
  delete erp.ext.request.axiosInstance.defaults.headers.common.cmswebsite
  store.state.system.company = company

  return true
}

export function scopeSystemByWebsite(model) {
  const default_locale = 'nl' // nl default locale for websites without locale

  window.localStorage.setItem('website', model.id)
  erp.ext.request.axiosInstance.defaults.headers.common.cmswebsite = model.id
  store.state.system.scope.value = model.id
  store.state.system.scope.obj = model

  // list of available locales
  if (model.website_locales !== undefined && model.website_locales) {
    store.state.system.scope.obj.website_locales = (typeof model.website_locales == 'string') ? JSON.parse(model.website_locales) : model.website_locales
    changeTranslationLocale(model.website_primary_locale || default_locale)
  } else if (model.website_primary_locale !== undefined && model.website_primary_locale) {
    store.state.system.scope.obj.website_locales = {}
    store.state.system.scope.obj.website_locales[model.website_primary_locale] = { status: 1 }
    changeTranslationLocale(model.website_primary_locale || default_locale)
  } else {
    store.state.system.scope.obj.website_locales = {}
    store.state.system.scope.obj.website_locales[default_locale] = { status: 1 }
    changeTranslationLocale(default_locale)
  }

  store.state.builder.categories = null

  return true
}

export function isVoidValue(value){
  return typeof value === 'undefined'
    || value === null
    || (typeof value === "string" && value.trim().length == 0)
    || (typeof value === 'object' && Object.keys(value).length === 0) // if value is [__ob__: Observer] returns true
}

// used in both erp-front and erp-front-entities
export function slug(str, delimiter = '_') {
  // work with objects
  if (typeof str === "object") {
    str = Object.values(str).filter(item => item !== '').join(delimiter)
  }

  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  var to = "aaaaeeeeiiiioooouuuunc------"
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, delimiter) // collapse whitespace and replace by -
    .replace(/-+/g, delimiter) // collapse dashes

  return str
}

// used in both erp-front and erp-front-entities
export function parseValue(v) {
  try {

    let val = typeof v == 'object' ? v : JSON.parse(v)

    if (!Array.isArray(val)) {
      return val.value
    } else if (Array.isArray(val)) {
      return val.reduce((a, i) => a += `${i.value}, `, '')
    } else {
      return v
    }
  } catch (e) {
    return v
  }

  return v
}

// used in both erp-front and erp-front-entities
export function searchArrayByKey(array, key, returnKey = 'value') {
  let match = array.filter(function (item) {
    return item.key == key
  })
  if (match.length == 0) {
    return 'not found'
  }

  return match[0][returnKey]
}

// used in both erp-front and erp-front-entities
export function searchArray(array, arrayKey, compareKey, returnKey = null) {
  let match = array.filter(function (item) {
    return item[arrayKey] == compareKey
  })
  if (match.length == 0) {
    return 'not found'
  }
  if (returnKey) {
    return match[0][returnKey]
  }

  return match[0]
}

export function optionLabel(options, key) {
  const item = options.find(option => option.key === key)
  return item ? item.label : ''
}

// used in both erp-front and erp-front-entities
export function setPageTitle(title) {
  document.title = title.charAt(0).toUpperCase() + title.slice(1) + ' - ' + store.state.system.company.value + ' ERP'
}

// TODO: this should be not here
export function buildHttpFilterObject(params, filters) {
  let filterData = cleanObject({ ...params })

  if (!filters) {
    return urlEncoder(filterData)
  }
  // mix up values
  filterData.filter_criteria = { 'filter_groups': {} }

  let filterKeys = Object.keys(filters)
  for (let filterIndex in filterKeys) {
    filterData.filter_criteria.filter_groups[filterIndex] = {
      filters: { 0: { key: filterKeys[filterIndex], value: filters[filterKeys[filterIndex]], condition: '=' } },
    }
  }

  return urlEncoder(filterData)
}

/* this was in erp-front-entities but it was not used, the only difference is that it returns the the filter as array - filters: {0: [{key: filterKeys
export function buildHttpFilterObject(params, filters){
    let filterData = cleanObject({...params})

    if(!filters){
        return urlEncoder(filterData)
    }
    // mix up values
    filterData.filter_criteria = { 'filter_groups': { } }

    let filterKeys = Object.keys(filters)
    for (let filterIndex in filterKeys) {
        filterData.filter_criteria.filter_groups[filterIndex] = {
            filters: {0: [{key: filterKeys[filterIndex], value: filters[filterKeys[filterIndex]], condition: '='}]}
        }
    }

    return urlEncoder(filterData)
}*/

// used in both erp-front and erp-front-entities
export function urlEncoder(obj, prefix) {
  var str = [],
    p
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p]
      str.push((v !== null && typeof v === "object") ?
        urlEncoder(v, k) :
        encodeURIComponent(k) + "=" + encodeURIComponent(v))
    }
  }

  return str.join("&")
}

// used in both erp-front and erp-front-entities
export function cleanObject(obj) {
  Object.keys(obj).forEach(key => (obj[key] == null) && delete obj[key])

  return obj
}

_.rename = function (obj, key, newKey) {

  if (_.includes(_.keys(obj), key)) {
    obj[newKey] = _.clone(obj[key], true)

    delete obj[key]
  }

  return obj
}

// used in both erp-front and erp-front-entities
export function humanReadable(string = '') {
  let readableString = (string.charAt(0).toUpperCase() + string.slice(1)).replace(/(-|_)/gm, ' ')
  readableString = readableString.replace(/([a-z])([A-Z])/g, (a, b, c) => `${b} ${c}`)

  return readableString
}

/*
 * Filters
 */

// deprecated, moved to erp_translations module
export function entityLabel(value, system_attribute = false, append_shortcodes = false) {
  return value
}

export function objectWithoutProp(object, prop){
  let { [prop]: _, ...rest } = object

  return rest
}

export function isEntityLocked(entity) {
  if (!entity || !entity.locked_until || !entity.locked_by) {
    return false
  }

  return entity.locked_until >= Date.now() && entity.locked_by != store.state.system.authData.user_id
}

function generateSrc(provider, code){
  if(provider === 'youtube'){
    return `https://www.youtube.com/embed/${code}`
  } else if (provider === 'vimeo') {
    return `https://player.vimeo.com/video/${code}`
  }
}

function generateSrcWithParameters(provider, code, params) {
  const src = generateSrc(provider, code)
  const queryParams = []
  let startTime = timeToSeconds(params.start_time)
  const endTime = timeToSeconds(params.end_time)

  // startTime is required when endTime is set
  if(endTime && !startTime) {
    startTime = 1 // youtube does not work well with 0, it has some cache and does not start from beginning
  }

  if (provider === 'youtube') {
    if (endTime !== null) {
      queryParams.push(`end=${endTime}`)
    }

    queryParams.push('rel=0') // do not show related videos
  }

  if (params.autoplay) {
    queryParams.push('autoplay=1')
  }
  if (params.hide_controls) {
    queryParams.push('controls=0')
  }
  if (params.loop) {
    if (provider === 'youtube') {
      queryParams.push(`playlist=${code}`)
    }

    queryParams.push('loop=1')
  }

  if (params.mute) {
    queryParams.push(provider === 'youtube' ? 'mute=1' : 'muted=1')
  }
  if (startTime !== null) {
    queryParams.push(provider === 'youtube' ? `start=${startTime}` : `#t=${startTime}`)
  }

  return `${src}${queryParams.length > 0 ? '?' + queryParams.join('&') : ''}`
}

export function getVideoProviderAndCode(link) {
  let provider = null
  let code = null

  // youtube 1 - https://www.youtube.com/watch?v=4bqwbNTJiIM
  if (link.includes('youtube.com/watch?v=')) {
    provider = 'youtube'
    code = link.split('watch?v=')[1]
    code = code.replace(/\&.*/g,"$'") // removed every other url parameters

    // youtube 2 - https://www.youtube.com/embed/4bqwbNTJiIM
  } else if (link.includes('youtube.com/embed')) {
    provider = 'youtube'
    code = link.split('embed/')[1]
    code = code.split('"')[0] // work even with whole iframe pasted

    // youtube 3 - https://youtu.be/4bqwbNTJiIM
  } else if (link.includes('youtu.be')) {
    provider = 'youtube'
    code = link.split('youtu.be/')[1]
    code = code.replace(/\?.*/g,"$'") // removed every other url parameters

    // Vimeo 1 - https://vimeo.com/video/761931035
  } else if (link.includes('vimeo.com/video')) {
    provider = 'vimeo'
    code = link.split('vimeo.com/video/')[1]
    code = code.split('?')[0] // work even with whole iframe pasted

    // Vimeo 2 - https://vimeo.com/761931035
  } else if (link.includes('vimeo.com')) {
    provider = 'vimeo'
    code = link.split('vimeo.com/')[1]
  }

  return provider ? { provider, code } : false
}

export function generateEmbeddedSrc(link, options = {}) {
  const provider = getVideoProviderAndCode(link)
  return provider ? generateSrcWithParameters(provider.provider, provider.code, options) : null
}

// todo make use of getVideoProviderAndCode
export function generateThumbnailFromSrc(link) {
  if (link.includes('youtube.com') || link.includes('youtu.be')) {
    // YouTube
    const code = link.includes('youtube.com/watch?v=')
      ? link.split('watch?v=')[1].split('&')[0]
      : link.split('/').pop()

    return `https://img.youtube.com/vi/${code}/hqdefault.jpg`
  }
  // TODO generate thumbnail from vimeo

  return null
}

export function downloadFile(blog, filename = '') {
  const data = URL.createObjectURL(blog)
  const link = document.createElement('a')
  link.href = data
  link.download = filename
  link.click()
}

export function IsJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function timeToSeconds(timeString) {
  if (!timeString) {
    return null
  }

  const timeMoment = moment(timeString, "HH:mm:ss")
  return timeMoment.seconds() + timeMoment.minutes() * 60 + timeMoment.hours() * 3600
}

export function generateSlug() {
  return uuid()
}

// get relations, todo change the endpoint
export async function getRelationField(entityType, dataModule) {
  const relations = await erp.ext.request.axiosInstance.get(`modules/relations/${dataModule}`)

  if(relations.data.data.length){
    if (entityType === 'clients') { // Hot fix remove once sourceClient is renamed to client in relations
      return 'source_client_id'
    }

    const entityTypePlural = entityType.toCamelCase()
    const entityTypeSingular = pluralize.singular(entityTypePlural)

    // Always check first the plural if exists because sometimes there is singular made of the plural relation.
    // for example roles - return roles relations, role - return the role with most priority
    if(relations.data.data.includes(entityTypePlural)){
      return entityTypePlural.toSnakeCase() // traject_types
    } else if(relations.data.data.includes(entityTypeSingular)){
      return entityTypeSingular.toSnakeCase() + '_id' // traject_type_id
    }
  }
}

export async function checkIfUrlExists(url, model = null, id = null, locale = store.state.system.translation_locale) {
  let data = {
    url_key: url,
    locale: locale,
  }

  if (model) {
    data.model = model
  }

  if (id) {
    data.id = id
  }

  return (await erp.ext.request.axiosInstance.post('/modules/cms/check-url', data)).data
}

export async function getUniqueUrlKey(urlKey, model, id) {
  let uniqueUrlKey = {}

  if (typeof urlKey === 'string') {
    const existingUrl = await checkIfUrlExists(urlKey, model, id)
    if (existingUrl.available) {
      uniqueUrlKey = urlKey
    } else {
      uniqueUrlKey = existingUrl.data.url_key
    }
  } else if (typeof urlKey === 'object') {
    for (const locale in urlKey) {
      const existingUrl = await checkIfUrlExists(urlKey[locale], model, id, locale)
      if (existingUrl.available) {
        uniqueUrlKey[locale] = urlKey[locale]
      } else {
        uniqueUrlKey[locale] = existingUrl.data.url_key
      }
    }
  }

  return uniqueUrlKey
}