<template>
  <div class="cms-block" :class="'cms-block-'+blockName" :data-component="blockName">
    <template v-if="editor.enabled">
      <div v-if="resData" v-html="resData.output" />
      <vs-skeleton v-else primary-color="#ebebeb" secondary-color="#f2f2f2" class="cms-placeholder" data-placeholder="Instagram feed">
        <vs-line height="250" class="mt-1" b-radius="0" />
      </vs-skeleton>
    </template>
  </div>
</template>

<script>
import DynamicComponentMixin from "@/modules/pagebuilder/mixins/DynamicComponentMixin"

export default {
  name: "InstagramFeedElement",
  mixins: [DynamicComponentMixin],
  inject: ['editor'],
  craft: {
    defaultProps: {
      settings: {
        show_logo_top: 0,
        enable_instagram_logo: 0,

        // layout settings
        layout: 'wall',
        items_count: 8,
        columns_count: 2,
        column_gap: '10',
        autoplay: 0,
        enable_navigation: 0,
        navigation_type: 'arrows',

        // Load more button settings
        show_load_more_button: 0,
        load_more_button_text: '',
        load_more_button_background_color: '',
        load_more_text_color: '',

        // Follow button settings
        show_follow_us_button: 0,
        follow_button_text: '',
        follow_button_background_color: '',
        follow_button_text_color: '',
      },
    },
  },
  data(){
    return {
      blockName: 'instagram-feed',
      renderUrl: 'instagram-feed',
    }
  },
}
</script>
