<template>
  <component :is="this.getSetting('link') ? 'a' : 'span'"
             :href="linkHref"
             :target="linkTarget"
             class="cms-block cms-block-image"
             :class="{'default-image': !this.getAttribute('src') }">

    <picture>
      <source type="image/webp" :srcset="replaceExtension(src, 'webp')">
      <img :src="src" :alt="getAttribute('alt')" :title="getAttribute('title')" loading="lazy" :style="imgStyle">
    </picture>
  </component>
</template>

<script>
import CraftAttributesMixin from "../../../mixins/CraftAttributesMixin"
import CraftSettingsMixin from "../../../mixins/CraftSettingsMixin"
import {replaceExtension} from "@/utilities/formatters";

export default {
  mixins: [CraftAttributesMixin, CraftSettingsMixin],
  inject: ['editor', 'node'],
  craft: {
    defaultProps: {
      settings: {
        link: '',
        link_target: '_self',
        imageBorderType: 'default',

        // initially 10px of border-radius if border type is rounded
        imageBorderRadius: '10px',
      },
      attributes: {
        title: '',
        alt: '',
        src: '',
      },
      elementStyle: {
        "width": '100%',
        "margin": '0 auto 0 0',
        "object-fit": 'contain',
      },
    },
  },
  computed: {
    src(){
      let src = this.getAttribute('src')
      if(src.length){
        return src
      }

      return '/uploads/defaults/image-placeholder.jpg'
    },
    imgStyle() {
      if (this.getSetting('imageBorderType') === 'square') {
        return { borderRadius: '0px' }
      } else if (this.getSetting('imageBorderType') === 'rounded') {
        return { borderRadius: this.getSetting('imageBorderRadius') }
      } else if (this.getSetting('imageBorderType') === 'round') {
        return { borderRadius: '50%', 'aspect-ratio' : '1 / 1', 'object-fit' : 'cover' }
      }

      return null
    },
    linkHref() {
      return this.getSetting('link') ? this.getSetting('link') : null;
    },
    linkTarget() {
      return (this.getSetting('link_target') && this.getSetting('link_target') !== '_self') ? this.getSetting('link_target') : null;
    },
  },
  methods: {
    replaceExtension,
  },
}
</script>