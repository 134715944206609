<template>
  <div class="tab-templates">
    <div class="row">

      <!-- Filters -->
      <div class="col-md-3">
        <div class="form-group">
          <label class="label">{{ translate('Search by name', 'page-builder') }}</label>
          <input-field
            v-model="search"
            :field-props="{}"
            @input="loadTemplates"
          />
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label class="label">{{ translate('Categories', 'page-builder') }}</label>
          <treeselect-field
            v-model="categories"
            :multisite="true"
            :field-props="{ data: this.entityType === 'cms-templates' ? $store.state.builder.templateCategories : $store.state.builder.categories }"
            @input="loadTemplates"
          />
        </div>
      </div>
    </div>

    <!-- Data -->
    <div class="row">
      <div class="col-md-12">
        <loader v-if="loading"/>
        <template v-else>
          <div class="template-items">
            <div v-if="templates.objectData.length" class="row nav-scrollbar">
              <div v-for="(template, pi) in templates.objectData" :key="pi" class="col-md-3">
                <div @click="setContent(template.content_json)" class="item p-3 mb-4">
                  <div class="mb-3">{{ template.name }}</div>
                  <div class="single-page d-flex justify-content-between align-items-center">
                    <img :src="template.preview_image || '/img/wizard/interface.svg'" style="max-width: 100%;">
                  </div>

                  <div class="template-overlay">
                    <button class="btn btn-primary px-4">
                      {{ translate('Select', 'page-builder') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="d-flex align-items-center justify-content-center font-weight-700 py-4">
              {{ translate('No data', 'global') }}
            </div>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce'
import { InputField, TreeselectField } from "@/modules/erp_entities/components/page/form/fields"
import Loader from "@/modules/system/components/utilities/Loader.vue"
import { mapGetters } from "vuex"

export default {
  name: "TabMyPages",
  components: {
    InputField,
    TreeselectField,
    Loader,
  },
  inject: ['editor'],
  props: {
    entityType: {
      required: true,
    },
  },
  data() {
    return {
      templates: [],
      categories: [],
      search: '',
      loading: true,
      hover: {},
    }
  },
  computed: {
    ...mapGetters('builder', ['mainContainerElementId']),
  },
  created() {
    this.loadTemplates()
  },
  methods: {
    loadTemplates: debounce( async function () {
      this.loading = true
      this.templates = []

      let query = this.erp.ext.query().set('perpage', 'all')

      if(this.entityType === 'cms-templates'){
        query.where('user_id', '=', this.$store.state.system.authData.user_id)
        query.where('cms_block_id', '=', this.mainContainerElementId)
      }

      if (this.categories.length) {
        query.where('category', 'in', this.categories)
      }
      if (this.search) {
        query.where('name', 'like', `%${this.search}%`)
      }

      this.templates = (await this.erp.ext.request.get(`modules/${this.entityType}`, query.toString()))
      await this.fetchPreviewImages()
      this.loading = false
    }, 800),
    setContent(content) {
      this.editor.import(content)
      this.editor.renderKey++
      this.$modal.hide('content-library-modal')
    },
    // todo refactor #12098409178
    async fetchPreviewImages() {
      const imagePromises = this.templates.objectData.map(async template => {
        try {
          const { filepath, filename } = (await this.erp.ext.request.axiosInstance.get(`/modules/documents/${template.preview_image}`)).data.data

          template.preview_image = `${filepath}/${filename}`
        } catch (err) {}
      })

      await Promise.all(imagePromises)
    },
  },
}
</script>
<style lang="scss">
  .template-items {
    .row {
      max-height: 500px;
    }

    .item {
      background: #fff;
      border-radius: $border-radius;
      cursor: pointer;

      .template-overlay {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        .template-overlay{
          display: flex;
        }
      }

    }
  }
</style>