<template>
  <swiper-slide>
    <video-background v-if="getSetting('background_video_link')" />
    <slot/>

    <div v-if="editor.enabled && !$slots.default" @click.stop="addSnippets" class="element-placeholder d-flex justify-content-center align-items-center flex-column w-100 text-center my-3">
      <button class="start-button start-button-column"><i class="fas fa-plus fa-2x"></i></button>
      <span class="mt-2">{{ translate('Drag your component here.', 'page-builder') }}</span>
    </div>

<!--     DISIABLED AS THERE IS DESIGN ISSUE -->
<!--    <button v-if="editor.enabled && !this.editor.draggedNode" @click="addSnippets" class="add_snippets_to_col"><i class="fa fa-plus mr-2"></i>-->
<!--      {{ translate('Add more components', 'page-builder') }}-->
<!--    </button>-->
  </swiper-slide>
</template>

<script>
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import uniqid from "uniqid";
import busEvent from "@/utilities/eventBus";
import VideoBackground from "@/modules/pagebuilder/components/components/VideoBackground.vue"

export default {
  components: { VideoBackground },
  mixins: [CraftSettingsMixin],
  inject: ['editor', 'node'],
  craft: {
    defaultProps: {
      settings: {
        title: '',

        // todo refactor #18764781, this code is duplicated
        background_video_link: null,
        background_video_start_time: null,
        background_video_end_time: null,
        background_video_loop: 0,
        background_video_opacity: '100%',
        background_video_overlay_color: '',
        background_video_overlay_opacity: '50%',
        // END todo refactor #18764781
      },
      // elementStyle: {
      //   "justify-content": "start",
      // },
    },
    rules: {
      canDrag() {
        return false
      },
      // Allow all elements except Section & Row(existing) to be placed in Content Slider Slide
      canMoveIn(incommingNode, thisNode) {
        if (incommingNode.componentName === 'Canvas' && ['SectionElement', 'RowElement'].includes(incommingNode.props.component)){
          return false
        }
        return true
      },
    },
  },

  methods: {
    addSnippets() {
      busEvent.$emit('toggleLeftSidebar', 'components')
    },
  },
}
</script>