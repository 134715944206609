
/*
*   Internal object validation types explanation
*   laravel validation name: {
*       valueType: false -> empty closure params | true -> send 1 param to closure | 'multi' -> send multiple values to closure
*       validation: closure which will handle the validaiton type
*   }
*/

/*
*   Internal validation object -> defines the behaviour of validation
*   External validation object -> we use it as way to set data for selected internal validation object
*   and is also a way to link between internal/vuelidation object
*   Vuelidation validation object -> the end result vuelidate should get
*/

// Custom validations are held in closures file
import {

} from './closures'


// TODO: move short hand validations to closures with proper validation
export default {
  required: {
    valueType: false,
    validation: v => {
      if(Array.isArray(v)) {
        return v.length > 0
      }
      // todo trim string fields
      return !!v
    },
    parameters: false,
    message: 'This field is required',
  },
  email: {
    valueType: 'multiple',
    input: 'select',
    parameters: false,
    // data: ['refc', 'strict', 'dns', 'spoof', 'filter'], // not used anymore
    validation: v => {
      if(!v){
        return true
      }
      let emails = v.split(',')
      let hasIncorrect = emails.some(email =>
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) === false,
      )
      return !hasIncorrect
    },
    message: 'Incorrect email',
  },
  min: {
    valueType: true,
    parameters: true,
    validation: (v, min) => v.length >= min,
    message: 'Minimum length is :v',
  },
  max: {
    valueType: true,
    parameters: true,
    validation: (v, max) => v.length <= max,
    message: 'Maximum length is :v',
  },
  // won't work anymore, values are not array anymore, if needed it must be updated
  // between: {
  //   valueType: 'two',
  //   input: 'text',
  //   parameters: true,
  //   validation: (v, { first, second }) => v.length >= first && v.length <= second,
  //   value: {
  //     first: null,
  //     second: null,
  //   },
  //   message: 'Value should be between :v and :v',
  // },
  unique_url: {
    valueType: false,
    parameters: false,
    validation: async value => {
      return false // todo, to be added in another task
    },
    message: 'Url key is already taken',
  },
}