import Login from "@/modules/system/views/Login.vue"
import PasswordRecovery from "@/modules/system/views/PasswordRecovery.vue"
import PasswordReset from "@/modules/system/views/PasswordReset.vue"
import SelectCompany from '@/modules/system/views/SelectCompany.vue'
import MediaLibrary from "@/modules/cms/views/cms/MediaLibrary.vue"
import notFound from "@/modules/system/components/404.vue"
import EntitySettings from '@/modules/system/views/entity/Settings.vue'
import Logout from "@/modules/system/views/Logout.vue"
import Manager from "@/modules/erp_translations/componenets/Manager.vue"
import ImportExportDesignOptions from "@/modules/erp_entities/components/page/form/ImportExportDesignOptions.vue"

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/password-recovery',
    name: 'password-recovery',
    component: PasswordRecovery,
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: PasswordReset,
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/select-company',
    name: 'select-company',
    component: SelectCompany,
    meta: {
      layout: 'blank',
    },
  },


  {
    path: '/media',
    name: 'media-library',
    component: MediaLibrary,
  },
  {
    path: '/404',
    name: 'page-not-found',
    component: notFound,
  },
  {
    path: '/entity/:entity/settings/:setting?/:group?',
    name: 'design-settings',
    component: EntitySettings,
  },
  {
    path: '/design/:action',
    name: 'import-export-design-options',
    component: ImportExportDesignOptions,
  },
  {
    path: '/manager',
    name: 'manager',
    component: Manager,
  },
]
