<template>
  <div>
    <element-settings-content-tabs>
      <element-settings-content-tab :selected="true" :name="translate('Type', 'page-builder')">
        <div class="row">
          <div class="col-md-12">
            <craft-select
                :label="'Choose separator type'"
                :value="getSetting('separator_type')"
                @input="setSetting('separator_type', $event)"
                :options="separatorTypeOptions"
            />
          </div>

          <transition name="fade">
            <div v-if="getSetting('separator_type') === 'with_text'" class="col-md-12">
              <craft-input
                  :label="'Title'"
                  :value="getSetting('separator_text')"
                  @input="setSetting('separator_text', $event)"
              />
            </div>
          </transition>

          <div class="col-md-12">
            <craft-tab-pills
                :label="'Text Alignment'"
                :options="generalAlignmentOptions"
                :value="getSetting('text_alignment')"
                @input="setSetting('text_alignment', $event)"
            />
          </div>

          <div class="col-md-12">
            <craft-tab-pills
                :label="'Element Alignment'"
                :options="marginOptions"
                :value="getStyle('margin')"
                @input="setStyle('margin', $event)"
            />
          </div>
        </div>
      </element-settings-content-tab>

      <element-settings-content-tab :name="translate('Styling', 'page-builder')">
        <div class="row">
          <div class="col-md-6">
            <craft-color-picker
                :label="'Border color'"
                :value="getSetting('border_bottom_color')"
                @input="setSetting('border_bottom_color', $event)"
            />
          </div>

          <div class="col-md-6">
            <craft-select
                :label="'Border style'"
                :options="borderStyleOptions"
                :value="getSetting('border_bottom_style')"
                @input="setSetting('border_bottom_style', $event)"
            />
          </div>

          <div class="col-md-6">
            <craft-input
                :label="'Border width'"
                :input-type="'number'"
                :suffix="'PX'"
                :value="getSetting('border_bottom_width', 'px')"
                @input="setSetting('border_bottom_width', $event, 'px')"
            />
          </div>

          <div class="col-md-6">
            <craft-select
                :label="'Element width'"
                :options="elementWidthOptions"
                :value="getStyle('width')"
                @input="setStyle('width', $event)"
            />
          </div>
        </div>
      </element-settings-content-tab>
    </element-settings-content-tabs>
  </div>
</template>

<script>
import ComponentSettingsMixin from "@/modules/pagebuilder/mixins/ComponentSettingsMixin";
import CraftStyleSettingsMixin from "@/modules/pagebuilder/mixins/CraftStyleSettingsMixin";
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";
import {CraftColorPicker, CraftInput, CraftSelect, CraftTabPills} from "@/modules/pagebuilder/components/craft-inputs";

export default {
  mixins: [ComponentSettingsMixin, CraftStyleSettingsMixin, CraftSettingsMixin],
  inject: ['editor'],
  name: 'SeparatorSettings',
  components: {
    CraftColorPicker,
    CraftInput,
    CraftSelect,
    CraftTabPills,
  },
  data() {
    return {
      key: "",
    }
  },
  props: {
    value: {
      required: true
    }
  },
  computed: {
    positionToTextMap() {
      return {
        '100%': 'Left',
        '50%': 'Center',
        '0': 'Right',
      };
    },
    separatorTypeOptions() {
      return [
        { key: 'with_text', label: 'Separator with text' },
        { key: 'without_text', label: 'Separator without text' },
      ]
    },
    marginOptions() {
      return [
        {key: '0 auto 0 0', label: 'Left'},
        {key: '0 auto 0 auto', label: 'Center'},
        {key: '0 0 0 auto', label: 'Right'}
      ];
    },

    elementWidthOptions() {
      return [
        {label: '20%', key: '20%'},
        {label: '30%', key: '30%'},
        {label: '40%', key: '40%'},
        {label: '50%', key: '50%'},
        {label: '60%', key: '60%'},
        {label: '70%', key: '70%'},
        {label: '80%', key: '80%'},
        {label: '90%', key: '90%'},
        {label: '100%', key: '100%'}
      ];
    },
  },
}
</script>