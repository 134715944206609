<template>
  <div class="container entity-container">
    <page-title v-if="pageTitle" :page-title="pageTitle" :buttons="isModal ? false : `/entity/${entityRoute}`">
      <template v-slot:actions>
        <button v-if="isModal" class="btn btn-white rounded-pill" @click="$emit('cancel')">
          {{ translate('Cancel', 'global') }}
        </button>
        <button v-if="canSave" class="btn btn-primary rounded-pill" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
        <button v-else class="btn btn-primary rounded-pill" @click="print">
          {{ translate('Print', 'global') }}
        </button>
      </template>
    </page-title>

    <div v-if="entity">

      <template v-if="Object.keys(attributeSetGroups).length">

        <!-- fields without tabs on top -->
        <!-- Changed to div because v-show="show[visibilityKey('__default__')]" cannot be put on template -->
        <!--  <template v-if="attributeSetGroups['__default__']" v-show="show[visibilityKey('__default__')]"> -->
        <div v-if="attributeSetGroups['__default__']" v-show="show[visibilityKey('__default__')]">
          <template v-for="(attrSetGroup, attrSetGroupI) in attributeSetGroups['__default__']">
            <entity-form-group
              v-show="show[visibilityKey('__default__', attrSetGroupI)]"
              :attrSetGroupI="attrSetGroupI"
              :attrSetGroup="attrSetGroup"
              :visible="visible[visibilityKey('__default__', attrSetGroupI)]"
              @toggleVisibility="visible[visibilityKey('__default__', attrSetGroupI)] = !visible[visibilityKey('__default__', attrSetGroupI)]"
            >
              <entity-form-row :attrSetGroup="attrSetGroup" :data-filters="dataFilters" :options="options" @setValue="setValue" @createOptions="v => createOptions = v"/>
            </entity-form-group>
          </template>
        </div>

        <!-- The rest of the tabs -->
        <tabs v-if="Object.keys(objectWithoutProp(attributeSetGroups, '__default__')).length">
          <template v-for="(tab, tabI, tabIndex) in objectWithoutProp(attributeSetGroups, '__default__')">
            <tab :name="tabI | translate('entities')" :selected="tabIndex === 0" :v_hide="!show[visibilityKey(tabI)]">
              <template v-for="(attrSetGroup, attrSetGroupI) in attributeSetGroups[tabI]">
                <entity-form-group
                  v-show="show[visibilityKey(tabI, attrSetGroupI)]"
                  :attrSetGroupI="attrSetGroupI"
                  :attrSetGroup="attrSetGroup"
                  :visible="visible[visibilityKey(tabI, attrSetGroupI)]"
                  @toggleVisibility="visible[visibilityKey(tabI, attrSetGroupI)] = !visible[visibilityKey(tabI, attrSetGroupI)]"
                >
                  <entity-form-row :attrSetGroup="attrSetGroup" :data-filters="dataFilters" :options="options" @setValue="setValue" @createOptions="v => createOptions = v"/>
                </entity-form-group>
              </template>
            </tab>
          </template>
        </tabs>
      </template>

      <div class="d-flex justify-content-end d-print-none">
        <router-link v-if="!isModal" :to="`/entity/${entityRoute}`" class="btn btn-white rounded-pill">
          {{ translate('Go back', 'global') }}
        </router-link>

        <button v-if="canSave" class="btn btn-primary rounded-pill" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
        <button v-else class="btn btn-primary rounded-pill" @click="print">
          {{ translate('Print', 'global') }}
        </button>
      </div>
    </div>

    <modal name="create_new_options"
           :pivot-x="0.5"
           :pivot-y="0.5"
           height="auto"
           width="500px"
           transition="slide-top"
           overlayTransition="wait"
           classes="remove-modal-height">
      <create-new-option :value="createOptions" />
    </modal>

    <modal
      name="create_new_entity"
      :pivot-x="0.5"
      :pivot-y="0.5"
      height="90%"
      width="80%"
      transition="slide-top"
      overlayTransition="wait"
      classes="remove-modal-height"
    >
      <create-new-option
        :value="createOptions"
        @save="updateFieldValue(createOptions)"
      />
    </modal>
  </div>
<!--    <div>-->
<!--        <div class="modal-content">-->
<!--            <div class="modal-header">-->
<!--                <h5 class="modal-title" v-if="value.mode != 'edit'">-->
<!--                    {{ translate('Create', 'global') }} {{$route.params.entityType}}-->
<!--                </h5>-->
<!--                <h5 class="modal-title" v-else>-->
<!--                    {{ translate('Update', 'global') }}-->
<!--                </h5>-->
<!--                <button type="button" class="close" @click="$modal.hide('add_new_' + entity)">-->
<!--                    <span aria-hidden="true">×</span>-->
<!--                </button>-->
<!--            </div>-->
<!--            <div class="modal-footer py-2">-->
<!--                <button type="button" :disabled="loading" class="btn btn-white rounded-pill border"-->
<!--                        @click="$modal.hide('add_new_' + entity)">-->
<!--                    {{ translate('Go back', 'global') }}-->
<!--                </button>-->
<!--                <button type="submit" :disabled="loading" @click="save"-->
<!--                        class="btn btn btn-primary rounded-pill">-->
<!--                    {{ translate('Save changes', 'global') }}-->
<!--                </button>-->
<!--            </div>-->
<!--            <div class="modal-body overflow-auto pt-0" v-if="form">-->
<!--                <form id="trigger-logo" @submit.prevent="save">-->
<!--                    <input type="hidden" name="id">-->
<!--                    form render -->
<!--                </form>-->
<!--            </div>-->
<!--            <v-infinite-progressbar v-if="loading"></v-infinite-progressbar>-->
<!--        </div>-->

<!--    </div>-->
</template>

<script>
import {
  createEntityTypesObjectNoTabs,
  humanReadable,
  isVoidValue,
  setPageTitle,
  objectWithoutProp, getEntityName, can, generateSlug
} from "@/utilities/helper"
import {environment, groupEntityForm, checkIfFormIsValidNoTabs} from '../../../utilities/helper'
const pluralize = require('pluralize')

import bus from './../../../utilities/eventBus'
import CreateNewOption from "@/modules/erp_entities/components/entities/CreateNewOption.vue"
import { validate } from "@/modules/erp_entities/utilities/validation/helper"
import EntityFormGroup from "@/modules/erp_entities/components/page/form/EntityFormGroup.vue"
import EntityFormRow from "@/modules/erp_entities/components/page/form/EntityFormRow.vue"
import { erp } from "@/modules/erp_framework";

export default {
  name: "EntityForm",
  components: {
    EntityFormGroup,
    EntityFormRow,
    CreateNewOption,
  },
  props: {
    entityRoute: {
      required: true,
      type: String,
    },
    isModal: {
      required: false,
      type: Boolean,
      default: false,
    },
    modalData: {
      required: false,
    },
  },
  data() {
    return {
      form: [],
      options: {},
      attributeSetGroups: {},
      dataFilters: {},
      show: {},
      visible: {},
      loading: false,
      entity: null,
      primary_fields: false,
      createOptions: null,
    }
  },
  async created() {
    await this.loadEntity()
    // used to indicate that form is loaded in the entity create modal
    if (this.isModal) {
      this.$emit('loading', false)
    }
  },

  computed: {
    entitySlug() {
      if (this.entity && this.entity.entity_slug) {
        return this.entity.entity_slug
      }

      return pluralize.singular(this.entityRoute)
    },
    // todo refactor #109378176
    entityName() {
      const entityName = getEntityName(this.entitySlug)
      if (entityName) {
        return entityName
      }

      return this.entitySlug.toPascalCase().split(/(?=[A-Z])/).join(" ")
    },
    entityNameTranslated(){
      return this.translate(this.entityName, 'entities')
    },
    entityNamePluralTranslated(){
      return this.translate(pluralize(this.entityName), 'entities')
    },
    // END todo refactor #109378176
    pageTitle() {
      if (!this.entityNameTranslated) {
        return null
      }

      return this.translate(`${this.currentAction} {entity}`, 'entities', { entity: this.titleContent })
    },

    isEditMode() {
      if (this.isModal) {
        return this.modalData?.id
      } else {
        return this.$route.params.id
      }
    },

    currentAction() {
      return this.isEditMode
        ? (can('update', this.entitySlug) ? 'Edit' : 'Show')
        : (can('create', this.entitySlug) ? 'Create' : 'Show')
    },

    titleContent() {
      return this.primaryFieldsComputed || this.entityNameTranslated
    },

    canSave() {
      if (this.$route.params.id && !this.isModal) {
        return can('update', this.entitySlug)
      } else {
        return can('create', this.entitySlug)
      }
    },
    primaryFieldsComputed() {
      if (!this.isEditMode || !this.primary_fields) {
        return false
      }

      const primaryFieldValues = this.primary_fields?.reduce((acc, field) => {
        if (!isVoidValue(this.options[field])) {
          acc.push(this.options[field])
        }

        return acc
      }, []) || []

      return primaryFieldValues.length ? primaryFieldValues.join(", ") : false
    },
  },

  methods: {
    humanReadable,
    objectWithoutProp,
    visibilityKey(...params){ // todo refactor #1048715466
      return params.join('_')
    },
    async loadEntity() {
      this.$store.state.system.isLoading = true

      this.entity = await this.erp.ext.request.get(`modules/${this.entityRoute}`)

      // similar to createSettingsForm(res)
      let entity = false
      if (this.isEditMode) {
        const id = this.isModal ? this.modalData.id : this.$route.params.id
        entity = await this.erp.ext.request.axiosInstance.get(`modules/${this.entityRoute}/${id}`)
        if (entity?.data?.primary_fields) {
          this.primary_fields = entity.data.primary_fields
        }
      }

      this.form = []
      let fieldsToWatch = []
      for (const prop of this.entity.columns) {
        let value = null
        if(!isVoidValue(prop.default_value)) {
          value = prop.default_value
        }

        if (this.modalData) {
          value = this.modalData[prop.name]
        }

        if(entity){

          // first check in options (if any)
          let recordProp = entity.data.data.options ? entity.data.data.options.filter(rec => rec.key == prop.name) : []
          if (recordProp.length) {
            let propIndex = entity.data.data.options.indexOf(recordProp[0])

            if(!isVoidValue(entity.data.data.options[propIndex].value)){
              value = entity.data.data.options[propIndex].value
            }
          }
          // else check in root
          else if(typeof entity.data.data[prop.name] !== 'undefined'){
            value = entity.data.data[prop.name]
          }
        }

        if (typeof this.options[prop.name] === 'undefined') {
          this.$set(this.options, prop.name, value)
        }

        if(prop.data_filters && prop.data_filters.length){
          // we want only unique names
          fieldsToWatch = [...new Set([...fieldsToWatch, ...prop.data_filters])]
        }

        //this.form.push({ ...prop, ...{ value: value } })
        this.form.push({ ...prop })
      }

      this.attributeSetGroups = groupEntityForm(this.form)

      this.setInitialShow()

      this.watchConditions()

      this.watchFields(fieldsToWatch)

      this.setInitialVisibility()

      setPageTitle(`${this.entityRoute}`)
      bus.$on('bulk', this.save)

      this.$store.state.system.isLoading = false
    },
    // Used for conditional tabs & accordions
    setInitialShow() {
      for (let tabKey in this.attributeSetGroups) {
        this.$set(this.show, this.visibilityKey(tabKey), true)
        for (let groupKey in this.attributeSetGroups[tabKey]) {
          this.$set(this.show, this.visibilityKey(tabKey, groupKey), true)
        }
      }
    },
    // used for accordions content
    setInitialVisibility(){
      for (let tabKey in this.attributeSetGroups) {
        for (let groupKey in this.attributeSetGroups[tabKey]) {
          this.$set(this.visible, this.visibilityKey(tabKey, groupKey), true)
        }
      }
    },
    getIndexByFieldName(fields, field_name){
      const attr = fields.filter(field => field.name == field_name)
      if(attr.length){
        return fields.indexOf(attr[0])
      }
      return false
    },
    getVisibleFieldNames() {
      const visibleFields = []

      Object.keys(this.attributeSetGroups).forEach(tabKey => {
        if (this.show[this.visibilityKey(tabKey)]) {
          Object.keys(this.attributeSetGroups[tabKey]).forEach(groupKey => {
            if (this.show[this.visibilityKey(tabKey, groupKey)]) {
              this.attributeSetGroups[tabKey][groupKey]
                .filter(field => field && !field.v_hide)
                .forEach(field => visibleFields.push(field.name))
            }
          })
        }
      })

      return visibleFields
    },
    validate() {
      const visibleFieldNames = this.getVisibleFieldNames()
      for (const field of this.form) {
        if (visibleFieldNames.includes(field.name)) { // validate only the visible fields
          this.$set(field, 'errors', validate(this.options[field.name], field.rules || []))
        }
      }
    },
    async updateFieldValue(field) {
      bus.$emit(`record_created_${field.name}_${field.id}`)
    },
    save() {
      if (this.form.some(field => field.name === 'slug' && field.type === 'hidden') && isVoidValue(this.options.slug)) {
        this.options.slug = generateSlug()
      }

      this.validate()

      //let formIsValid = checkIfFormIsValid(this.attributeSetGroups) replaced because we now have tabs + accordions
      let formIsValid = checkIfFormIsValidNoTabs(this.form)

      if(!formIsValid){
        return this.$toast.error('Fix errors in form')
      }

      // todo fonts
      // if (!this.areAllFontsProvided()) {
      //   return this.$toast.error('Please provide fonts for selected variants')
      // }

      // Datatable Editable Fields, Checkbox field
      let datatableEditableFields = []
      this.form.forEach(field => {
        if(field.type === 'datatable_editable') {
          datatableEditableFields.push({
            module: field.data_module.toKebabCase() + 's',
            value: this.options[field.name],
          })
          this.options[field.name] = null // don't keep datatable_editable value in the main entity
        }
        // Default value for checkbox if its not clicked once
        if (field.type === 'checkbox' && !this.options[field.name]) {
          this.options[field.name] = 0
        }
      })
      // END Datatable Editable Fields, Checkbox field

      // send only the visible options
      const visibleOptions = this.getVisibleFieldNames().reduce((acc, fieldName) => {
        if (this.options.hasOwnProperty(fieldName)) {
          acc[fieldName] = this.options[fieldName]
        }

        return acc
      }, {})


      let method = (this.$route.params.id && !this.isModal) || this.modalData?.id ? 'put' : 'post'

      let target = this.entityRoute
      if (this.isModal && this.modalData?.id) {
        target += `/${this.modalData.id}`
      } else if (!this.isModal && this.$route.params.id) {
        target += `/${this.$route.params.id}`
      }

      environment.request[method](`modules/${target}`, { options: visibleOptions, entity_slug: this.entitySlug }).then(response => {

        // update related entities if any (datatable editable fields)
        this.updateRelations(datatableEditableFields, response.data.data.id).then(response => {
          this.$toast.requestSuccess(method, this.entityNameTranslated)

          if (this.isModal) {
            this.$emit('save')
          } else {
            return this.$router.push(`/entity/${this.entityRoute}`)
          }
        })

      }).catch(error => {
        this.$toast.error(error.message)
        this.loading = false
      })
    },

    print() {
      print()
    },

    // todo move to EntityMixin
    async updateRelations(datatableEditableFields, main_entity_id) {
      // disabled on create
      // const method = this.$route.params.id && !this.isModal ? 'put' : 'post'

      if(datatableEditableFields.length){
        datatableEditableFields.forEach(async field => {
          if(field.value){
            field.value.dataToCreate.forEach(async options => { // Create
              options[field.value.relationField] = main_entity_id
              await environment.request['post'](`modules/${field.module}`, { options: options })
            })
            field.value.dataToUpdate.forEach(async options => { // Update
              options[field.value.relationField] = main_entity_id
              await environment.request['put'](`modules/${field.module}/${options.id}`, { options: options })
            })
            field.value.dataToDelete.forEach(async id => { // Delete
              await environment.request['delete'](`modules/${field.module}/${id}`)
            })
          }
        })
      }
    },
    // END todo move to EntityMixin

    // copied from productsCreate, not needed things commented for now
    setValue(fieldProps, v){
      this.options[fieldProps.name] = v
    },

    watchConditions() {
      const conditions = this.form.flatMap(field => {
        const conditions = field?.conditions || []
        return conditions.map(condition => ({ ...condition, source_field: field.name }))
      })

      conditions.forEach(condition => {

        // watch only valid tabs/groups/fields
        const destinationTab = condition.destination_tab ? this.attributeSetGroups[condition.destination_tab] : false
        const destinationGroup = (condition.destination_tab && condition.destination_group) ? this.attributeSetGroups[condition.destination_tab][condition.destination_group] : false
        const destinationField = condition.destination ? this.form[this.getIndexByFieldName(this.form, condition.destination)] : false

        if (destinationTab || destinationGroup || destinationField) {
          this.$watch(`options.${condition.source_field}`, () => {
            const conditionsSatisfied = this.areAllConditionsSatisfied(this.options[condition.source_field], condition.conditions)
            this.applyBehaviour(condition, conditionsSatisfied)
          }, { immediate: true })
        }
      })
    },

    applyBehaviour(condition, conditionsSatisfied) {
      const destinationField = condition.destination ? this.form[this.getIndexByFieldName(this.form, condition.destination)] : false

      for (const behaviour of condition.destination_behaviour) {
        if (behaviour === 'hide') {
          // field
          if (condition.destination) {
            this.$set(destinationField, 'v_hide', conditionsSatisfied)
          // tab/accordion
          } else if (condition.destination_tab) {
            const visibilityKey = condition.destination_group ? this.visibilityKey(condition.destination_tab, condition.destination_group) : this.visibilityKey(condition.destination_tab)
            this.$set(this.show, visibilityKey, !conditionsSatisfied)
          }
        } else if (behaviour === 'show') {
          // field
          if (condition.destination) {
            this.$set(destinationField, 'v_hide', !conditionsSatisfied)
            // tab/accordion
          } else if (condition.destination_tab) {
            const visibilityKey = condition.destination_group ? this.visibilityKey(condition.destination_tab, condition.destination_group) : this.visibilityKey(condition.destination_tab)
            this.$set(this.show, visibilityKey, conditionsSatisfied)
          }

        }
      }
    },

    // Returns true if all conditions are met
    areAllConditionsSatisfied(sourceFieldValue, conditions = []) {
      return conditions.every(condition => {
        switch (condition.key) {
        case 'lower':
          return sourceFieldValue < condition.value
        case 'greater':
          return sourceFieldValue > condition.value
        case 'equals':
          return sourceFieldValue == condition.value
        case 'not_equals':
          return !(sourceFieldValue == condition.value)
        case 'is_empty':
          return isVoidValue(sourceFieldValue)
        case 'is_not_empty':
          return !isVoidValue(sourceFieldValue)
        case 'one_of':
          return sourceFieldValue !== null && condition.value.split(/\s*,\s*/)
            .map(String)
            .includes(sourceFieldValue.toString())
        case 'not_one_of':
          return sourceFieldValue === null || !condition.value.split(',')
            .map(String)
            .includes(sourceFieldValue.toString())
        default:
          return true // Default case, consider it a match
        }
      })
    },

    watchFields(fieldsToWatch){
      fieldsToWatch.forEach(el => {

        this.$set(this.dataFilters, el, {
          name: el,
          label: this.form[this.getIndexByFieldName(this.form, el)].label,
          value: this.options[el],
          group: el,
        })

        this.$watch(
          `options.${el}`, val => {
            this.$set(this.dataFilters[el], 'value', val)
          },
        )
      })
    },
    areAllFontsProvided() {
      const files = this.options['files'] ? JSON.parse(this.options['files']) : null

      return files && this.options['variants'].every(variant => !isVoidValue(files[variant]))
    },
  },
  watch: {
    'entityRoute': function(){
      this.loadEntity()
    },
  },
}
</script>
