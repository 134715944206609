<template>
    <div v-if="allLocalesObject && hasManyTranslationLocales">
      	<label v-if="label" class="label" :class="customLabelClasses">{{ label  }}</label>
        <div class="nav-item dropdown dropdown-animate" :class="{unaligned: !labelAlignment}"  data-toggle="hover">
            <a
				class="nav-link language-changer"
				href="#"
				role="button"
				data-toggle="dropdown"
				aria-haspopup="true"
               	aria-expanded="false"
			>
                <img :src="'/img/flags/locales/1x1/' + selectedLocale + '.svg'" alt="Change locale" class="mr-2 language-flag rounded-circle">
                <span class="locale-name" v-if="userLocale">{{ userLocale.name }}
                  	<i class="fa ml-auto fa-chevron-down chevron"></i>
                </span>
            </a>
            <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow p-lg-0 mr-0">
                <div class="p-lg-4">
                    <div class="pb-3 pt-3 delimiter-bottom" v-for="(locale, key) in translationLocales" :key="key">
                        <a href="javascript:;" @click="$emit('changeLocale', key)" class="d-flex align-items-center">
                            <img :src="'/img/flags/locales/4x3/' + key + '.svg'" alt="Change locale" class="language-flag">
                            <span class="dropdown-item pt-0 pl-3">{{allLocalesObject[key].name}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {allLocales, translationLocales, hasManyTranslationLocales} from "@/utilities/helper";
import {mapState} from 'vuex'

export default {
	name: "LanguagePicker",
	props: {
		label: {
			type: String,
			required: false
		},
		lang: {
			required: false
		},
		labelAlignment: {
			type: Boolean,
			required: false,
		},
		customLabelClasses: {
			type: Array,
			required: false,
		},
	},
	data() {
		return {
			allLocales: [],
			allLocalesObject: null,
		}
	},

	computed: {
		translationLocales,
        hasManyTranslationLocales,
		...mapState('system', ['translation_locale']),
		selectedLocale() {
			return this.lang || this.translation_locale
		},
		userLocale() {
			return this.allLocales.filter((item) => {
				return item.key == this.selectedLocale
			})[0];
		}
	},

	async created() {
		this.allLocales = await allLocales()
		this.allLocalesObject = {}
		for (let locale of this.allLocales){
			this.allLocalesObject[locale.key] = {name: locale.name}
		}

		this.userLocale = this.allLocales.filter((item) => {
			return item.key == this.translation_locale }
		)[0];
	},

	watch: {
		selectedLocale: function (val) {
			this.userLocale = this.allLocales.filter((item) => {
			return item.key == this.selectedLocale
			})[0];
		}
	}
}
</script>
<style lang="scss" scoped>
    .language-changer {
        border: 1px solid #edeff1;
        border-radius: 50rem !important;
        padding: .1rem .2rem !important;
		min-width: 215px;
    }
    .language-changer .language-flag{
      height: 30px;
      width: 30px;
    }
  .dropdown {
    a {
      background: #fff;
    }
  }
	.dropdown-menu {
		box-shadow: 0 10px 30px 0 rgba(31, 45, 61, 0.1);
		.dropdown-item:hover, .dropdown-item:focus {
			color: #657a95;
			background-color: transparent;
		}
	}
	.unaligned {
		display: block;
		min-height: 40px;
		.language-changer {
			padding: .25rem .2rem !important;
		}
		.dropdown-menu-sm {
			min-width: 100% !important;
			min-width: 100%;
		}
		.delimiter-bottom:after  {
			bottom: 3px;
		}
	}
	.font-12 {
		font-size: 12px;
	}
    span.locale-name{
      	font-weight: normal;
	  	position: relative;
		display: contents;
		width: 100%;

		i {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 10px;
		}
    }
</style>