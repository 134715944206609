import { getEntityName, hasManyTranslationLocales } from "@/utilities/helper"

const pluralize = require('pluralize')

export const entities = ['portfolios', 'blogs', 'testimonials', 'cms-pages', 'sliders', 'cms-sections', 'cms-templates']

export default {
  computed: {
    builder(){
      const { id } = this.$route.params
      const [empty, b, entity, idOrCreate] = this.$route.fullPath.split('/')

      return {
        // instance: EventBus,
        meta: {
          entity: entity,
          entityId: id,
          entities,
        },
      }
    },
    entitySlug() {
      return pluralize.singular(this.builder.meta.entity)
    },
    // todo refactor #109378176
    entityName() {
      const entityName = getEntityName(this.entitySlug)
      if (entityName) {
        return entityName
      }

      return this.entitySlug.toPascalCase().split(/(?=[A-Z])/).join(" ")
    },
    entityNameTranslated(){
      return this.translate(this.entityName, 'entities')
    },
    entityNamePluralTranslated(){
      return this.translate(pluralize(this.entityName), 'entities')
    },
    // END todo refactor #109378176
    isCreateMode() {
      return this.$route.fullPath.includes('create')
    },
    isAdvancedBuilder() {
      if(['cms-pages', 'cms-sections', 'cms-templates'].includes(this.builder.meta.entity)){
        return true
      } else { // blogs, portfolios
        return this.$store.state.builder.entity.objectData.advanced_builder
      }
    },
  },
  methods: {
    async loadData() {
      let locale = this.$store.state.system.translation_locale
      let user_id = null
      if(!['cms-pages', 'cms-sections', 'blogs', 'portfolios'].includes(this.builder.meta.entity)){
        locale = null
      }

      if(['cms-templates'].includes(this.builder.meta.entity)){
        user_id = this.$store.state.system.authData.user_id
      }

      await this.fetchEntities({ entity: this.builder.meta.entity, locale: locale, user_id: user_id })
    },
  },
}