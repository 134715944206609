<template>
  <div class="cms-block cms-block-social-media-icons">

    <component-style :value="componentStyle"/>

    <ul :id="socialMediaIconsId" :aria-orientation="getSetting('orientation')" :class="navClasses" class="nav d-flex">
      <li v-for="(item, itemIndex) in getSetting('items')" class="nav-item">
        <a class="nav-link btn d-flex align-items-center justify-content-center" :href="linkHref(item)">
          <i :class="`fa ${item.icon}`"></i><span v-if="getSetting('type') !== 'icon_only' && item.label" class="ml-2">{{ item.label }}</span>
        </a>
      </li>
    </ul>

  </div>
</template>

<script>
import CraftSettingsMixin from "../../../mixins/CraftSettingsMixin";
import ComponentStyle from "@/modules/pagebuilder/components/components/elements/components/ComponentStyle";

export default {
  name: 'SocialMediaIconsElement',
  components: {ComponentStyle},
  mixins:[
    CraftSettingsMixin,
  ],
  inject: ['editor', 'node'],
  craft: {
    defaultProps:{
      settings:{
        items: [
          {
            icon: 'fa-brands fa-facebook',
            label: 'Facebook',
            link: '',
            link_target: '_blank',
            position: 1
          },
          {
            icon: 'fa-brands fa-instagram',
            label: 'Instagram',
            link: '',
            link_target: '_blank',
            position: 2
          },
          {
            icon: 'fa-brands fa-linkedin',
            label: 'LinkedIn',
            link: '',
            link_target: '_blank',
            position: 3
          }
        ],

        // Styling
        orientation: 'horizontal',
        type: 'rounded',
        border_width: '',
        border_color: '',
        background_color: '',
        icon_color: '',
        icon_width: '16px',
        padding: '8px', // 0.5 rem
        gap: '0px'
      },
    }
  },

  computed: {
    socialMediaIconsId() {
      return 'social-media-icons-' + this.node.uuid
    },
    componentStyle() {
      let styles = '';

      // todo remove me after the css is added in frontside
      //styles += `${socialMediaIconsId} {flex-grow: 1}`
      styles += `.cms-block-icon-box a:hover {text-decoration: none!important}`
      // END todo remove me after the css is added in frontside


      // type !important in place because of frontend .card-header:first-child { border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;}
      if(this.getSetting('type') === 'rounded'){
        styles += `#${this.socialMediaIconsId} li a {border-radius: 0.25rem}`
      } else if(this.getSetting('type') === 'square'){
        styles += `#${this.socialMediaIconsId} li a {border-radius: 0}`
      } else if(this.getSetting('type') === 'round'){
        styles += `#${this.socialMediaIconsId} li a {border-radius: 50rem}`
      }

      if(this.getSetting('type') !== 'icon_only'){
        if(this.getSetting('border_width', 'px')){
          styles += `#${this.socialMediaIconsId} a {border-width: ${this.getSetting('border_width')}}`
        }
        if(this.getSetting('border_color')){
          styles += `#${this.socialMediaIconsId} a {border-color: ${this.getSetting('border_color')}}`
        }
        if(this.getSetting('background_color')){
          styles += `#${this.socialMediaIconsId} a {background-color: ${this.getSetting('background_color')}}`
        }
      }

      if(this.getSetting('icon_color')){
        styles += `#${this.socialMediaIconsId} li i {color: ${this.getSetting('icon_color')}}`
      }
      if(this.getSetting('icon_width')){
        styles += `#${this.socialMediaIconsId} li i {font-size: ${this.getSetting('icon_width')}}`
      }
      if(this.getSetting('padding')){
        styles += `#${this.socialMediaIconsId} li a {padding: ${this.getSetting('padding')}}`
      }
      if(this.getSetting('gap')){
        styles += `#${this.socialMediaIconsId} {gap: ${this.getSetting('gap')}}`
      }

      return styles;
    },

    navClasses(){
      let classes = []
      if(this.getSetting('orientation') === 'vertical'){
        classes.push('flex-column')
      } else {
        classes.push('flex-row')
      }
      return classes
    },
  },
  methods: {
    linkHref(item){
      return item.link.length ? item.link : null;
    },
  }
};
</script>