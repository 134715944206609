import { SweetToast } from '@/utilities/sweetalert'
import { erp } from '@/modules/erp_framework'

// Handler is called when entities are loaded
// Example given: System will warn you if u dont have home page
export default {
  'cms-pages': async function(state, websiteId, locale){
    let query = erp.ext.query()
      .where('website_id', '=', websiteId)
      .set('perpage', 9999)

    if(locale){
      query.where('locale', '=', locale)
    }

    let pages = await erp.ext.request.get(`modules/cms-pages`,
      query.toString(),
    )
    if(!(pages.data.some(page => page.url_key === '' || page.url_key === '/' || !page.url_key))){
      SweetToast.error('Your website does not have home page! Please mark page as home page from the sidebar home icon')
    }
  },
  portfolios: function(){
    return true
  },
  blogs: function(){
    return true
  },
  testimonials: function(){
    return true
  },
  sliders: function (){
    return true
  },
  'cms-sections': function (){
    return true
  },
  'cms-templates': function (){
    return true
  },
}
