<template>
  <div class="app-page-snippets d-flex flex-column">
    <craft-input
        :label="'Title'"
        :value="value.label"
        @input="e => {
          value.label = e
          value.name = e.toLowerCase().split(' ').join('-')
        }"
    />
    <craft-input
        v-if="value.type !== 'select'"
        :label="'Placeholder'"
        :value="value.attributes.placeholder"
        @input="value.attributes.placeholder = $event"
    />

    <div v-if="value.type === 'select'" class="form-group input-field">
      <div class="d-flex align-items-center justify-content-between">
        <label class="label">{{ translate('Dropdown data', 'page-builder') }}</label>
        <a href="#" class="btn-add-dropdown-data" @click.prevent="addFieldOption">
          <i class="fas fa-plus"></i>
        </a>
      </div>
      <div class="mt-3">
        <div class="row">
          <div class="col-sm-3 label">{{ translate('Key', 'page-builder') }}</div>
          <div class="col-sm-4 label">{{ translate('Value', 'page-builder') }}</div>
          <div class="col-sm-3 text-center label">{{ translate('Default', 'page-builder') }}</div>
          <div class="col-sm-2 text-center label">{{ translate('Delete', 'page-builder') }}</div>
        </div>
        <transition-group name="fade" tag="div">
          <div
              v-for="(option, optionIdx) in selectedField.options"
              :key="option"
              class="row my-2 align-items-center"
          >
            <div class="col-sm-3">
              <input
                  type="text"
                  class="form-control form-control-sm"
                  :value="option.key"
                  @input="ev => setFieldOption(optionIdx, 'key', ev.target.value)"
              />
            </div>
            <div class="col-sm-4">
              <input
                  type="text"
                  class="form-control form-control-sm"
                  :value="option.value"
                  @input="ev => setFieldOption(optionIdx, 'value', ev.target.value)"
              />
            </div>
            <div class="col-sm-3 text-center">
              <div class="custom-control custom-switch">
                <input
                    type="checkbox"
                    :id="`dropdown-${optionIdx}`"
                    class="custom-control-input"
                    :value="option.selected == true ? 1 : 0"
                    :checked="option.selected == true ? 1 : 0"
                    @input="ev => setFieldOption(optionIdx, 'selected', ev.target.checked)"
                />
                <label
                    :for="`dropdown-${optionIdx}`"
                    class="custom-control-label"
                ></label>
              </div>
            </div>
            <div class="col-sm-2 text-center">
              <a href="#" class="btn-del-dropdown">
                <i
                    class="fas fa-minus"
                    @click.prevent="deleteFieldOption(optionIdx)"
                ></i>
              </a>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <craft-toggle
        :label="'Required field'"
        :id="`required-field-${fieldId}`"
        :value="parseInt(value.attributes.required)"
        @input="value.attributes.required = $event"
    />
    <craft-tab-pills
        :label="'Field size'"
        :value="value.width"
        @input="value.width = $event"
        :options="fieldSizeOptions"
    />
    <button class="btn btn-secondary rounded-pill" @click.prevent="$emit('removeField', fieldId)"><i class="icon-erp-trash"></i> {{ translate('Remove this field', 'page-builder') }}</button>
  </div>
</template>

<script>

import {CraftInput, CraftTabPills, CraftToggle} from "@/modules/pagebuilder/components/craft-inputs";

export default {
    name: 'FormField',
    components: {
      CraftInput, CraftTabPills, CraftToggle
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        fieldId: {
          required: true
        }
    },
    computed: {
      fieldSizeOptions() {
        return [
          { key: '6', label: '50%' },
          { key: '12', label: '100%'},
        ]
      },
      selectedField() {
        return this.value;
        // return this.form.default_properties.find(
        //     ({ id }) => id === this.field.id
        // )
      },
    },
  methods: {
    addFieldOption() {
      this.selectedField.options.push({
        key: '',
        value: '',
        selected: 0,
      })
    },
    deleteFieldOption(optionIdx) {
      this.selectedField.options = this.selectedField.options.filter(
          (option, idx) => idx !== optionIdx
      )
    },
    setFieldOption(idx, key, value) {
      if (key === 'selected') {
        this.selectedField.options.forEach(
            option => (option.selected = 0)
        )
      }
      this.selectedField.options[idx][key] = value
    },
  },
}
</script>