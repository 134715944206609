<template>
  <div><!-- THIS DIV CAN BE REMOVED IN VUE 3 -->

    <!-- No accordion by default -->
    <template v-if="attrSetGroupI === '__default__'">
      <slot></slot>
    </template>

    <template v-else>
      <div class="card rounded bg-white" v-if="attrSetGroup" :key="attrSetGroupI">
        <div class="card-header d-flex justify-content-between align-items-center" :class="{'border-bottom-0': !visible}" @click="$emit('toggleVisibility')">
          <h5>{{ translate(`${humanReadable(attrSetGroupI)}`, 'products') }}</h5>
          <i class="fa fa-chevron-down chevron" :class="{'chevron-rotate': visible === true}"></i>
        </div>
        <transition-expand>
          <div class="card-body" v-show="visible">
            <slot></slot>
          </div>
        </transition-expand>
      </div>
    </template>
  </div>
</template>

<script>
import field from "./InputEntityProps.vue"
import { humanReadable } from "@/utilities/helper"
export default {
  name: "FormRender",
  components: {
    field,
  },
  props: {
    attrSetGroupI: {
      type: String,
      required: true,
    },
    attrSetGroup: {
      type: Object,
      required: true,
    },
    display: {
      type: Boolean,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    humanReadable,
  },
}
</script>