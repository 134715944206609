import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';

class Node {
  constructor(componentName, props = {}, parent = null, children = [], rules = {}, addition = {}) {
    this.componentName = componentName;
    this.props = props;
    this.parent = parent;
    this.children = children;
    this.rules = rules;
    this.addition = addition;
    this.uuid = uuidv4();
  }

  setProps(change) {
    this.props = { ...this.props, ...change };
  }

  makeOrphan() {
    const { parent } = this;

    if (!parent) {
      return;
    }

    const index = parent.children.indexOf(this);
    parent.children.splice(index, 1);
    this.parent = null;
  }

  setParent(parent) {
    if (!parent.isDroppable(this)) {
      throw new Error('Parent node is not droppable.');
    }

    this.makeOrphan();

    parent.children.push(this);
    this.parent = parent;
  }

  inCanvas() {
    let currentParent = this.parent;

    while (currentParent) {
      if (currentParent.isCanvas()) {
        return true;
      }
      currentParent = currentParent.parent;
    }

    return false;
  }

  isDraggable() {
    if (!this.inCanvas()) {
      return false;
    }

    if (this.rules.canDrag) {
      return this.rules.canDrag(this);
    }

    return true;
  }

  isAncestor(node) {
    let currentParent = this.parent;

    while (currentParent) {
      if (currentParent === node) {
        return true;
      }
      currentParent = currentParent.parent;
    }

    return false;
  }

  isDroppable(incommingNode) {
    // blockzapps changed, allows rows dropped from the sidebar to have columns
    // && this.componentName !== 'ContentSliderElement' - todo this must be tested, added to fix ContentSliderElement is not droppable with the incommingNode - Canvas
    //if (!this.isCanvas()) {
    if (!this.isCanvas() && this.componentName !== 'RowElement' && this.componentName !== 'ContentSliderElement') {
      return false;
    }

    if (incommingNode === this) {
      return false;
    }

    if (incommingNode.parent
      && incommingNode.parent.rules.canMoveOut
      && !incommingNode.parent.rules.canMoveOut(incommingNode, incommingNode.parent)) {
      return false;
    }

    if (this.isAncestor(incommingNode)) {
      return false;
    }

    if (this.rules.canMoveIn) {
      return this.rules.canMoveIn(incommingNode, this);
    }

    return true;
  }

  isCanvas() {
    if (this.componentName === 'Canvas') {
      return true;
    }

    return false;
  }

  insertAtIndex(incomingNode, index) {
    if (!this.isDroppable(incomingNode)) {
      throw new Error(`${this.componentName} is not droppable with the incomingNode - ${incomingNode.componentName}.`);
    }

    incomingNode.makeOrphan()

    this.children.splice(index, 0, incomingNode)
    incomingNode.parent = this
  }

  append(incomingNode) {
    this.insertAtIndex(incomingNode, this.children.length)
  }

  prepend(incomingNode) {
    this.insertAtIndex(incomingNode, 0)
  }

  canBeSibling(targetNode) {
    if (targetNode === this) {
      return false;
    }

    if (!targetNode.parent) {
      return false;
    }

    return targetNode.parent.isDroppable(this);
  }

  insertBefore(targetNode) {
    if (!this.canBeSibling(targetNode)) {
      throw new Error('Can not be the sibling of the target node.');
    }

    this.makeOrphan();

    const parentOfTargetNode = targetNode.parent;
    const indexOfTargetNode = parentOfTargetNode.children.indexOf(targetNode);
    parentOfTargetNode.children.splice(indexOfTargetNode, 0, this);
    this.parent = parentOfTargetNode;
  }

  insertAfter(targetNode) {
    if (!this.canBeSibling(targetNode)) {
      throw new Error('Can not be the sibling of the target node.');
    }

    this.makeOrphan();

    const parentOfTargetNode = targetNode.parent;
    const indexOfTargetNode = parentOfTargetNode.children.indexOf(targetNode);
    parentOfTargetNode.children.splice(indexOfTargetNode + 1, 0, this);
    this.parent = parentOfTargetNode;
  }

  serialize() {
    return {
      componentName: this.componentName,
      props: this.props,
      children: this.children.map((node) => node.serialize()),
      addition: this.addition,
      uuid: this.uuid,
    };
  }

  // blockzapps added deleteUuid, templates must not keep uuid
  duplicate(deleteUuid = false) {
    const newNode = new Node(
      this.componentName,
      cloneDeep(this.props),
      null,
      this.children.map((node) => node.duplicate()),
      this.rules,
      cloneDeep(this.addition),
    )

    if(deleteUuid){
      delete newNode.uuid
    }

    newNode.children.forEach(node => {
      node.parent = newNode
    })

    return newNode
  }
}

Node.unserialize = (editor, nodeData, parent = null) => {
  const node = new Node();
  Object.assign(node, nodeData);

  const craftConfig = editor.getCraftConfig(node);

  if (craftConfig.rules) {
    node.rules = craftConfig.rules;
  }


  node.parent = parent;
  node.children = nodeData.children.map((data) => Node.unserialize(editor, data, node));

  return node;
};

export default Node;
