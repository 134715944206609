<template>
  <div class="element-settings" ref="draggableContainer">
      <div class="element-settings-content">

        <div class="row align-items-center my-3 grabbable" @mousedown="dragMouseDown">
          <div class="col-10">
            <h6 class="settings-section-title">{{ translate(elementName, 'page-builder')}}</h6>
          </div>
          <div class="col-2 text-right">
            <button class="btn-close-settings-popup" @click.stop="$emit('closeSettingsPopup')">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>

        <component
            :is="elementSettingsComponent"
            :key="key"
            :node="this.editor.selectedNode"
        />

      </div>
  </div>
</template>

<script>

// component settings editors
import builderComponents from '../components/settings';

import {mapState, mapActions} from "vuex";
import PagesMixin from "../../mixins/PagesMixin";
import uniqid from "uniqid";
import CraftElementDataMixin from "@/modules/pagebuilder/mixins/CraftElementDataMixin";

export default {
  name: "ElementSettings",
  inject: ['editor', 'node'],
  mixins: [PagesMixin, CraftElementDataMixin],
  props: {
    value: {
      required: false,
    },
    element: {
      required: true
    },
    elementName: {
      required: true,
    },
    settingsComponent: {
      required: false
    },
  },
  async mounted() {
    // setTimeout(() => {
    //     if (!this.$route.path.includes('/create')) {
    //         this.$tours['builder'].start()
    //     }
    // }, 700)
    window.localStorage.setItem('builderTour', '1')
  },
  components:{
    ...builderComponents,
  },
  data() {
    return {
      key: uniqid(), // todo test without this and remove it if it is not needed anymore,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  computed: {
    ...mapState('builder', ['entity']),

    elementSettingsComponent() {
      if(this.settingsComponent){
        return this.settingsComponent
      } // else
      return this.element.replace('Element', 'Settings')
    },
    hasPage() {
      if (this.entity)
        return !!this.entity
      return true
    },
    hasWebsite() {
      return !!this.entity
    },
    cmOptions() {
      return {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        autoCloseTags: true,
        line: true,
        mode: 'text/html',
        theme: 'oceanic-next',
        lineWrapping: true
      }
    },
    cmOptionsCss() {
      return {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        autoCloseTags: true,
        line: true,
        mode: 'text/css',
        theme: 'oceanic-next',
        search: true,
        lineWrapping: true
      }
    },
    tour() {
      if (!window.localStorage.getItem('builderTour'))
        return [
          {
            target: '.add-new-page', // We're using document.querySelector() under the hood
            header: {
              title: 'Create new page',
            },
            content: `Add pages and discover templates!`,
            params: {
              placement: 'right',
            },
          },
          {
            target: '.pages-sidebar--header',
            content: 'Select page',
            params: {
              placement: 'right',
            },
          },
          {
            target: '.btn-toggle-status',
            content: 'Publish your website or unpublish it',
          },
        ]
      return [
        {
          target: '.pages-sidebar--header',
          content: 'Select page',
          params: {
            placement: 'right',
          },
        },
      ]
    },
  },
  methods: {
    ...mapActions('builder', ['saveWebsite']),
    dragMouseDown: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement () {
      document.onmouseup = null
      document.onmousemove = null
    }

  },
}
</script>