<template>
  <div class="accordion mobile-filters d-lg-flex flex-wrap">
    <div class="card-header py-2 d-flex justify-content-between align-items-center d-lg-none">
      <h3>{{ translate('Filters', 'entities') }}</h3>
      <button class="btn-link" @click="$emit('onFiltersClose')">
        <i class="far fa-circle-xmark fa-2x"></i>
      </button>
    </div>

      <div class="filters-wrapper nav-scrollbar my-2">
        <div v-for="(filter, idx) in filters" :key="idx">
          <div class="card-header py-2 d-flex justify-content-between align-items-center d-lg-none" @click="filter.isActive = !filter.isActive">
            <span>{{ entityLabel(filter.label, true) }}</span>
            <i class="fa fa-chevron-right" :class="{'active': filter.isActive }"></i>
          </div>

          <div class="d-lg-flex collapse" :class="{ 'show' : filter.isActive }">
            <div class="card-body-mobile">
              <field class="d-lg-flex flex-wrap filter-field"
                     @input="$emit('input', filters)"
                     v-model="filter.value"
                     :field-props="filter"
                     :show-label="false"
                     :placeholder="filter.label"
                     :apply-default-val="false"/>
            </div>
          </div>
        </div>
      </div>

      <div class="button-bottom d-flex justify-content-center d-lg-none">
          <button class="btn btn-primary w-100 py-3 mx-3" @click="$emit('onFiltersClose')">{{ translate('Close', 'entities') }}</button>
      </div>
  </div>
</template>

<script>
import field from "@/modules/erp_entities/components/page/form/InputEntityProps.vue"

export default {
  name: "FiltersAccordion",
  components: {
    field,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filters: null,
    }
  },
  created() {
    this.filters = this.value.map(filter => ({
      ...filter,
      isActive: false, // NEW property
      // overrides
      permission: 'w',
      data_filters: [],
      rules: [], // remove "required" message, the field is not required here like in create/edit entity
    }))
  },
}
</script>

<style lang="scss">
@media all and (max-width: 991px) {
  .mobile-filters {
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    background-color: #fff;
    height: calc(100% - 51px);
  }

  .filters-wrapper {
    height: calc(100% - 152px);

    .card-body-mobile {
      padding: 1.25rem;
      border-bottom: 1px solid #e5edf5;
    }
  }

  .fa-chevron-right.active {
    transform: rotate(90deg);
  }

  .button-bottom {
    position: fixed;
    bottom: 1rem;
    width: 100%;
    z-index: 4;
  }
}

@media all and (min-width: 992px) {
  .filters-wrapper {
    display: flex;
    flex-wrap: wrap;
    &.nav-scrollbar {
      overflow: unset;
    }
  }
}
</style>