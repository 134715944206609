<template>
    <div v-if="dropdowns" class="mt-3">
        <div class="card bg-white">
            <div class="card-header actions-toolbar border-0">
                <div class="row justify-content-between align-items-center">
                    <div class="col">
                        <h6 class="d-inline-block mb-0 system-text-primary">{{ translate('Dropdowns', 'entities') }}</h6>
                    </div>
                    <div class="col text-right">
                        <div class="d-flex justify-content-end">
                            <input type="text" class="form-control w-50 mr-3" :placeholder="translate('Search by name', 'global')"
                                   @input="e => setFilter({prop:'name', compare: 'like', value: `%${e.target.value}%`})">
                            <v-select class="select2-form input-group-sm w-25" :placeholder="translate('Search by model', 'global')"
                                      :options="modules"
                                      :reduce="option => option.key"
                                      @input="e => setFilter({prop:'model', compare: '=', value: e})"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table align-items-center system-text-primary">
                    <thead>
                    <tr>
                        <th scope="col">{{ translate('ID', 'entities')}}</th>
                        <th scope="col">{{ translate('Name', 'entities')}}</th>
                        <th scope="col">{{ translate('Model', 'entities')}}</th>
                        <th scope="col">{{ translate('Company ID', 'entities')}}</th>
                        <th scope="col">{{ translate('Created at', 'global')}}</th>
                        <th scope="col" class="text-right">{{ translate('Actions', 'global') }}</th>
                    </tr>
                    </thead>
                    <tbody class="list">
                    <tr v-for="(dropdown, dropdownIndex) in dropdowns.data">
                        <td>{{dropdown.id}}</td>
                        <td>
                            <router-link :to="`/dropdowns/${dropdown.id}`" @click="edit(dropdown)" class="name mb-0 h6 text-sm system-text-primary">{{ entityLabel(dropdown.name) }}</router-link>
                        </td>
                        <td>{{ dropdown.model }}</td>
                        <td>{{ dropdown.company_id }}</td>
                        <td>{{ formatDate(dropdown.created_at, null, 'DD MMMM YYYY hh:mm') }}</td>
                        <td class="text-right">
                            <cta>
                                <action-button v-tooltip="translate('Edit', 'entities')"
                                               class-icon="fa-pen" @click.native="$router.push(`/dropdowns/${dropdown.id}`)"></action-button>
                                <action-button v-tooltip="translate('Delete', 'entities')"
                                               class-icon="fa-trash-alt" @click.native="deleteRow(dropdown)">
                                  <!-- TODO NEW permissions v-if="dropdown.actions.delete" -->
                                </action-button>
                            </cta>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <pagination :disabled="loading" @goToPage="v => setProp('page', v)"
                            :container-class="'d-flex justify-content-between'"
                            v-if="dropdowns.meta" :value="dropdowns.meta"></pagination>
            </div>
        </div>
    </div>

</template>


<script>
    import { db, routes } from "../../utilities/database";
    import { debounce } from 'debounce'
    import {urlEncoder} from "@/utilities/helper";
    import {environment} from "../../utilities/helper";
    import CTA from '../../components/page/table/CTA'
    import ActionButton from '../../components/page/table/ActionButtons'

    export default {
        name: 'DropdownsTable',
        components: {
            CTA, ActionButton
        },
        data() {
            return {
                dropdowns: null,
                loading: false,
                modules: this.$store.state.system.moduleNames,
                filters: {},
                filterProps: {}
            }
        },
        methods: {
          setFilter(filter){
              if(filter.value == null || filter.value == '%%'){
                  delete this.filters[filter.prop]
                  return this.loadDropdowns()
              }
              this.filters[filter.prop] = filter
              this.loadDropdowns()
          },
          setProp(key, v){
              this.filterProps[key] = v
              this.loadDropdowns()
          },
          loadDropdowns: debounce(async function () {
              let filters = this.erp.ext.query()
              Object.values(this.filters).forEach(filter => {
                  filters.where(filter.prop, filter.compare, filter.value, true)
              })
              Object.entries(this.filterProps).forEach(([key, value]) => {
                  filters.set(key, value)
              })
              this.loading = true;
              this.$store.state.system.isLoading = true;

              let response = await this.erp.ext.request.axiosInstance.get(`${routes.dropdown.filter}?${filters.toString()}`);
              this.dropdowns = response.data
              if(response.data) {
                  this.$emit('getData', response.data)
              }
              this.$emit('input', response.data);

              this.loading = false;
              this.$store.state.system.isLoading = false
          }, 700),
          edit(user) {
              this.$emit('triggerFrom', user)
          },
          async deleteRow(row) {
            const confirmDelete = await this.$alert.confirmDelete()
            if(!confirmDelete.isConfirmed) return

            this.performAction('delete', row.id)
          },

          performAction(action, id){
            // Delete
            if(action === 'delete'){
              environment.request.delete(routes.dropdown.delete.replace(':id', id), {}).then(res => {
                this.loadDropdowns()
                this.$toast.requestSuccess('delete', 'Dropdown')
              }).catch(error => {
                this.loadDropdowns()
                this.$toast.error(error.message)
              })
            }
          },
        },
        mounted() {
          this.loadDropdowns()
        },
    }
</script>

