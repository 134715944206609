<template>
  <div>
    <!-- <h5 class="modal-title">Entities</h5> -->
    <div class="row">
      <div class="col-12 col-lg-12">
        <div class="card bg-white">
          <!-- ENTITY DEFAULT DATA -->
          <div class="card-header bg-primary">
            <h2 class="text-white">
              {{ translate('Dropdown', 'entities') }} -
              {{ entityLabel(data.name) }}
            </h2>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4 col-xs-12">
                <ee-form-group
                  :label="translate('Name', 'entities')"
                >
                  <ee-form-input
                    v-model="data.name"
                    :field-props="{translatable: 1}"
                    autocomplete="off"
                    @input="e => generateSlug(e)"
                  />
                </ee-form-group>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label class="form-control-label">
                    {{ translate('Model', 'entities') }}
                  </label>
                  <v-select
                    v-model="data.model"
                    class="select2-form"
                    :options="modules"
                    :reduce="option => option.key"
                  />
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label class="form-control-label">
                    {{ translate('Slug', 'entities') }}
                  </label>
                  <input
                    v-model="data.slug"
                    type="text"
                    class="form-control"
                    autocomplete="off"
                  >
                </div>
              </div>
            </div>

            <!-- ENTITY DEFAULT DATA -->

            <!-- PROPERTIES -->
            <div class="row mt-3">
              <div class="form-group col-12 col-md-12">
                <div>
                  <div class="d-flex align-items-center">
                    <label class="form-control-label mb-0">
                      {{ translate('Dropdown options', 'entities') }}
                    </label>
                    <a
                      href="javascript:;"
                      class="icon btn-primary rounded-circle icon-sm icon-shape ml-2"
                      @click="addToData"
                    >
                      <i class="fas fa-plus" />
                    </a>
                  </div>
                  <div class="position-relative mt-3">
                    <div class="row">
                      <div class="col-sm-3">
                        {{ translate('Key', 'entities') }}
                      </div>
                      <div class="col-sm-3">
                        {{ translate('Value', 'entities') }}
                      </div>
                      <div class="col-sm-3 text-center">
                        {{ translate('Default value', 'entities') }}
                      </div>
                      <div class="col-sm-3 text-center">
                        {{ translate('Delete', 'global') }}
                      </div>
                    </div>
                    <draggable
                      v-model="data.select_option_options"
                      v-bind="dragOptions"
                      draggable=".draggable-item"
                      @start="drag = true"
                      @end="drag = false"
                    >
                      <transition-group
                        type="transition"
                        :name="!drag ? 'flip-list' : null"
                        tag="div"
                      >
                        <div
                          v-for="(propData, pdi) in data.select_option_options"
                          :key="pdi + 1"
                          class="row my-2 draggable-item"
                        >
                          <div class="col-sm-3">
                            <input
                              v-model="data.select_option_options[pdi].key"
                              type="text"
                              class="form-control form-control-sm"
                            >
                          </div>
                          <div class="col-sm-3">
                            <ee-form-input
                              v-model="data.select_option_options[pdi].value"
                              :field-props="{translatable: 1, class: 'form-control-sm'}"
                            />
                          </div>
                          <div class="col-sm-3 text-center">
                            <div class="custom-control custom-switch">
                              <input
                                :id="pdi"
                                v-model="data.select_option_options[pdi].is_default"
                                type="checkbox"
                                class="custom-control-input"
                                name="default_value"
                                :true-value="1"
                                :false-value="0"
                                @click="e => setDefaultProp(e.target, pdi)"
                              >
                              <label
                                class="custom-control-label"
                                :for="pdi"
                              />
                            </div>
                          </div>
                          <div class="col-sm-3 text-center">
                            <a
                              href="javascript:;"
                              class="btn btn-primary icon rounded-circle icon-sm icon-shape"
                              @click="removeDataProp(pdi)"
                            >
                              <i class="fas fa-minus" />
                            </a>
                          </div>
                        </div>
                      </transition-group>
                    </draggable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- PROPERTIES END -->
          <div class="card-footer d-flex justify-content-end b-t-pr">
            <router-link to="/dropdowns" class="btn btn-white rounded-pill">
              {{ translate('Go back', 'global') }}
            </router-link>
            <button type="submit" class="btn btn-primary rounded-pill" @click="save">
              {{ translate('Save changes', 'global') }}
            </button>
          </div>
          <v-infinite-progressbar v-if="loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db, routes } from "../../utilities/database"
import draggable from 'vuedraggable'
import {humanReadable, setPageTitle, slug} from "@/utilities/helper"
import { environment } from "../../utilities/helper"
import { EeFormGroup, EeFormInput } from "@/modules/erp_entities/components/forms"


export default {
  name: "DropdownsForm",
  components: {
    EeFormGroup, EeFormInput,
    draggable,
  },
  data() {
    return {
      drag: false,
      loading: false,
      modules: null,
      data: {
        locale: 'en',
        name: '',
        model: null,
        select_option_options: null,
      },
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      }
    },
  },
  created() {
    this.modules = this.$store.state.system.moduleNames
    if (this.$route.params.id) {
      environment.request.get(`select-options/${this.$route.params.id}`).then(res => {
        this.data = res.data.data
      })
    }

    setPageTitle(`dropdowns `)
  },
  methods: {
    save() {
      let data = {
        options: {
          ...this.data,
        },
      }
      this.loading = true
      environment.request[this.$route.params.id ? 'put' : 'post'](routes.dropdown[this.$route.params.id ? 'update' : 'create'].replace(':id', this.$route.params.id), data)
        .then(res => {
          this.$router.push('/dropdowns')
          this.$toast.requestSuccess()
          this.loading = false
        }).catch(err => {
          if (err.response && err.response.status == 422) {
            this.loading = false
            this.$toast.error(err.response.data.messages[0])
          } else {
            this.$toast.error(err.response.statusText)
          }
          this.loading = false
        })
    },
    generateSlug(v) {
      this.data.slug = slug(v, '-')
    },
    addToData() {
      if (!this.data.select_option_options) {
        this.$set(this.data, 'select_option_options', [])
      }
      this.data.select_option_options.push({
        key: '',
        value: '',
      })
    },
    removeDataProp(pdi) {
      this.data.select_option_options.splice(pdi, 1)
    },
    setDefaultProp(e, i) {
      if(this.data.select_option_options[i].is_default == '0') {
        this.data.select_option_options.forEach(option => {
          option.is_default = 0
        })
      }
    },
  },
}
</script>

<style>
.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
    cursor: grabbing;
}
</style>
