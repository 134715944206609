<template>
  <div class="cms-block" :class="'cms-block-'+blockName" :data-component="blockName">
    <template v-if="editor.enabled">
      <div v-if="resData" v-html="resData.output"/>
      <vs-skeleton v-else primary-color="#ebebeb" secondary-color="#f2f2f2" class="cms-placeholder" data-placeholder="Portfolio">
        <vs-line height="250" class="mt-1" b-radius='0'></vs-line>
      </vs-skeleton>
    </template>
  </div>
</template>

<script>
import DynamicComponentMixin from '../../../../mixins/DynamicComponentMixin'
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import { responsiveColumns } from "@/modules/pagebuilder/utilities/helpers"

export default {
  mixins: [DynamicComponentMixin, CraftSettingsMixin],
  inject: ['editor', 'node'],
  craft: {
    defaultProps: {
      settings: {
        categories: null,
        perpage: 4,
        enable_filters: 0,
        layout: 'list',
        navBulletsEnabled: 0,
        navArrowsEnabled: 0,
        navArrowsVerticalAlign: 'end',
        autoplay: 0,
        carouselSpeed: 100,
        loop: 0,
        columns: {
          desktop: '4', // 3 columns
          tablet_landscape: '4', // 3 columns
          tablet_portrait: '6', // 2 columns
          mobile: '12', // 1 column
        },
      },
    },
  },
  data() {
    return {
      blockName: 'portfolio',
      renderUrl: null,
    }
  },
  // todo refactor #1937178
  watch: {
    resData: function (val) {

      if(this.getSetting('layout') === 'carousel') {

        this.$nextTick(() => {
          const columns = this.getSetting('columns')

          // swiper element
          const swiperEl = document.querySelector(`div[data-uuid="${this.node.uuid}"] swiper-container`)
          if (!swiperEl) return

          const swiperParams = {
            // slidesPerView: responsiveColumns[columns['mobile']], // can be here or in the html as attribute slides-per-view
            // slidesPerGroup: responsiveColumns[columns['mobile']], // can be here or in the html as attribute slides-per-group
            breakpoints: {
              767: {
                slidesPerView: responsiveColumns[columns['tablet_portrait']],
                slidesPerGroup: responsiveColumns[columns['tablet_portrait']],
              },
              991: {
                slidesPerView: responsiveColumns[columns['tablet_landscape']],
                slidesPerGroup: responsiveColumns[columns['tablet_landscape']],
              },
              1200: {
                slidesPerView: responsiveColumns[columns['desktop']],
                slidesPerGroup: responsiveColumns[columns['desktop']],
              },
            },
          }

          //const swiperEl = document.querySelector('swiper-container')
          Object.assign(swiperEl, swiperParams)
          swiperEl.initialize()
        })
      }
    },
  },
}
</script>