<template>
  <div class="cms-block cms-block-content-slider">
    <swiper-container
      :key="renderKeyCraft"
      :slides-per-view="getSetting('slides_per_view')['mobile']"
      :direction="getSetting('direction')"
      :pagination="getSetting('pagination')"
      :pagination-clickable="getSetting('pagination')"
      :navigation="getSetting('navigation')"
      :style="containerStyle"

      :css-mode="editor.enabled"
    >
<!--      allow-touch-move="false"-->
      <!-- todo make allow-touch-move work with editor.enabled, somehow it is not disabled -->
      <slot />
    </swiper-container>

<!--    <button v-if="editor.enabled && !this.editor.draggedNode" @click="addSlide" class="add_snippets_to_col"><i class="fa fa-plus mr-2"></i>-->
<!--      {{ translate('Add slide', 'page-builder') }}-->
<!--    </button>-->
  </div>
</template>

<script>
import busEvent from "@/utilities/eventBus";
import CraftSettingsMixin from "../../../mixins/CraftSettingsMixin";
import Node from '../../../craft/core/Node';
import {createNodeFromComponentName} from "@/modules/pagebuilder/craft/utils/createNodeFromVNode";
import BuilderElements from "@/modules/pagebuilder/components/components/elements/index"
import {Canvas} from "@/modules/pagebuilder/craft"

export default {
  mixins: [CraftSettingsMixin],
  inject: ['editor', 'node'],
  craft: {
    defaultProps: {
      settings: {
        container_height: '',
        slides_per_view: {
          mobile: 1,
          tablet_portrait: 1,
          tablet_landscape: 1,
          desktop: 1,
        },
        pagination: true,
        navigation: true,
        direction: 'horizontal',
        // navArrowsVerticalAlign: 'end',
      },
      elementStyle: {
        "padding-top": '30px',
        "padding-bottom": '30px',
      },
    },
    rules: {

      //Only allow RowElement to be placed in Section
      canMoveIn(incommingNode, thisNode) {

        if(incommingNode.componentName === 'ContentSliderElement'){
          return false
        }

        console.log('todo canMoveIn ContentSliderElement')
        return true

        // // todo check which of the 2 is needed, we dont have option to drop rows from the sidebar like RowElement
        // if(incommingNode.componentName === 'ContentSliderSlideElement' || (incommingNode.componentName === 'Canvas' && incommingNode.props.component === 'ContentSliderSlideElement')){
        //   return true
        // }
        return false
      },
    },
  },
  data() {
    return {
      renderKey: 0,
    }
  },
  computed: {
    renderKeyCraft(){
      return 'craft-'+this.renderKey
    },
    containerStyle(){
      let height = ''

      if(this.getSetting('container_height')){
        height = this.getSetting('container_height')
      }

      return {
        height: height,
      }
    },
  },

  methods: {
    addSlide() {
      const row = createNodeFromComponentName(this.editor, 'RowElement')
      row.insertAfter(this.node)

      setTimeout(() => { // does not work without timeout
        this.editor.selectNode(row)

        setTimeout(() => { // does not work without timeout
          busEvent.$emit('rowToolsToggleSettings')
        }, 10)

      }, 10)
    },
  },
  watch: {
    // Re-render on some settings changes, for now navigation, maybe and pagination
    'node.props.settings': {
      deep: true,
      handler(value, oldvalue) {

        // // console.log('awesome')
        // const swiperEl = document.querySelector(`div[data-uuid="${this.node.uuid}"] swiper-container`)
        //
        // console.log('swiperEl')
        // console.log(swiperEl)
        //
        // console.log('this.getSetting(\'pagination\')')
        // console.log(this.getSetting('pagination'))
        //
        // swiperEl.setAttribute('slides-per-view', this.getSetting('slides_per_view'))
        // swiperEl.setAttribute('pagination', this.getSetting('pagination'))
        // swiperEl.setAttribute('navigation', this.getSetting('navigation'))
        // swiperEl.initialize()
        //

        if(value.navigation !== oldvalue.navigation) {
          this.renderKey++
        }

      },
    },
    'node.children': {
      deep: false,
      handler(value) {
        this.renderKey++
      },
    },
  },
}
</script>