<template>
  <div class="d-flex mb-5 ml-3 w-100">
    <div class="d-flex flex-column w-100">
      <h6 class="font-weight-bold py-2">{{ label }}</h6>
      <div class="d-flex h-100">
        <ul class="list-group border w-auto h-100 p-2 mt-2 flex-grow-1 nav-scrollbar">
          <li v-for="(column, key) in columns" :key="key"
              class="list-group-item d-flex border-0 align-items-center justify-content-between p-0 mb-2"
              :class="{'active': column.name === selectedColumn}"
          >
            <button class="btn-sm border-0 bg-transparent w-100 text-left rounded" @click="$emit('selectColumn', column.name)">
              {{ entityLabel(column.label, true) }}
            </button>
          </li>
        </ul>

        <div class="dual-list-box-actions ml-3">
          <button v-if="actions.includes('addColumn')" class="btn btn-primary my-1 p-3" @click="$emit('addColumn')" :disabled="!canPerformAction.canAdd">
            <i class="fa fa-long-arrow-alt-right"></i>
          </button>
          <button v-if="actions.includes('removeColumn')" class="btn btn-primary my-1 p-3" @click="$emit('removeColumn')" :disabled="!canPerformAction.canRemove">
            <i class="fa fa-long-arrow-alt-left"></i>
          </button>

          <button v-if="actions.includes('moveColumnUp')" class="btn btn-primary my-1 p-3" @click="$emit('moveColumnUp')" :disabled="!canPerformAction.canMoveUp">
            <i class="fa fa-long-arrow-alt-up"></i>
          </button>
          <button v-if="actions.includes('moveColumnDown')" class="btn btn-primary my-1 p-3" @click="$emit('moveColumnDown')" :disabled="!canPerformAction.canMoveDown">
            <i class="fa fa-long-arrow-alt-down"></i>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DualListBoxItem",
  props: {
    label: {
      required: true,
    },
    columns: {
      required: true,
    },
    selectedColumn: {
      required: null,
    },
    actions: {
      required: true,
      default: [],
    },
  },
  computed: {
    canPerformAction() {
      if (!this.selectedColumn) return false

      const hasSelectedColumn = this.columns.some(item => item.name === this.selectedColumn)

      return {
        canAdd: this.actions.includes('addColumn') && hasSelectedColumn,
        canRemove: this.actions.includes('removeColumn') && !hasSelectedColumn,
        canMoveUp: this.actions.includes('moveColumnUp')  && hasSelectedColumn, // todo check if it is not first
        canMoveDown: this.actions.includes('moveColumnDown') && hasSelectedColumn, // todo check if it is not last
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.list-group-item {
  background-color: #fff;
  &.active {
    background-color: $secondary;
  }
}

.dual-list-box-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .btn {
    line-height: 0;
    i {
      font-size: 18px;
      width: 18px;
    }
  }

  .btn + .btn {
    margin-left: 0;
  }
}
</style>