<template>
  <div :class="{'text-limit': limitTextByType, 'field-avatar': fieldProps.name === 'avatar'}">

    <!-- Image -->
    <img v-if="fieldProps.type == 'image' && value" class="table-image" :src="value" alt="image"/>
    <img v-else-if="fieldProps.type == 'image' && !value && fieldProps.name === 'avatar'" class="table-image" :src="`/img/themes/${$store.state.system.theme}/avatar_default.jpg`"/>
    <span v-else-if="fieldProps.type == 'image' && !value" class="table-image"></span>

    <!-- Status -->
    <status-badge
      v-else-if="['status', 'stock_status', 'email_status', 'recovery_status', 'type'].includes(fieldProps.name)"
      :key="idx"
      :value="status"
      :raw-value="rawValue"
      :field-props="fieldProps"
    />

    <!-- Link field -->
    <component
      v-else-if="['title', 'name', 'first_name', 'last_name', 'order_number', 'number'].includes(fieldProps.name) || primaryFields.includes(fieldProps.name)"
      :is="fieldEntityRoute || primaryFields.includes(fieldProps.name) ? 'router-link' : 'span'"
      :to="fieldEntityRoute"
      class="system-text-primary">

      <!-- Title/name with link -->
      <template v-if="['title', 'name'].includes(fieldProps.name) && fieldProps.translatable">
        <title-with-shortcodes :value="entityLabel(value, false, true)"/>
      </template>
      <template v-else>
        {{ value }}
      </template>
    </component>

    <!-- Percentage -->
    <percentage-bar
      v-else-if="fieldProps.type === 'percentage'"
      :value="value"
    />

    <!-- todo remove me and pre-format in modifyData -->
    <span v-else-if="fieldProps.type == 'datetime_iso'">
      <template v-if="value">{{ formatDate(value, null, 'DD MMMM YYYY HH:mm') }}</template>
    </span>
    <span v-else-if="fieldProps.name == 'url'">

      <button
        @click="download"
        class="btn-link font-weight-500"
      >
        {{ value }}
      </button>
    </span>

    <!-- Product image -->
    <img v-else-if="fieldProps.name === 'images'" :src="getFirstImage(value)" class="table-image contain">

    <!-- Order Vendor -->
    <span v-else-if="fieldProps.name === 'vendor' && entityType === 'orders'">
      <img v-if="getOrderVendorImage()" :src="getOrderVendorImage()" class="table-image contain">
    </span>

    <!-- Price fields -->
    <span v-else-if="['price', 'total_amount'].includes(fieldProps.name)" v-html="value" />

    <!-- Default -->
    <span v-else>
      <template v-if="Array.isArray(value)">
        <div v-for="itemValue in value">{{ entityLabel(itemValue) }}</div>
      </template>
      <template v-else>
        {{ value }}
      </template>
    </span>
  </div>

  <!-- Just for info, on orders we had following format for created_at {{ formatDate(order.created_at, null, 'DD MMM YY') }} -->

</template>

<script>
import TitleWithShortcodes from "@/modules/erp_entities/components/utilities/TitleWithShortcodes.vue"
import StatusBadge from "@/modules/erp_entities/components/page/table/components/StatusBadge.vue"
import PercentageBar from "@/modules/erp_entities/components/page/table/components/PercentageBar.vue"
import { can, downloadFile } from "@/utilities/helper"
import { entityRoute } from "@/modules/erp_entities/utilities/helper"

export default {
  name: "DisplayProperty",
  components: { TitleWithShortcodes, StatusBadge, PercentageBar },
  props: {
    value: {
      required: true,
    },
    rawValue: {
      required: false,
    },
    id: {
      required: true,
    },
    fieldProps: {
      required: true,
    },
    entityType: {
      required: true,
    },
    entitySlug: {
      required: true,
    },
    isLocked: {
      required: false,
    },
    primaryFields: {
      required: false,
    },
  },
  filters: {
    formatValue(value){
      if(Array.isArray(value)){
        return value.map(item => this.entityLabel(item)).join(', ')
      }
      return value
    },
  },
  computed: {
    limitTextByType() {
      return [
        'textarea',
        'simple_textarea',
        'html_editor',
        'cms-navigation',
        'erp-navigation',
        'page_builder',
        '_page_builder',
      ].includes(this.fieldProps.type)
    },
    fieldEntityRoute() {
      // Editable entity
      if(can('update', this.entitySlug)) {
        return !this.isLocked ? entityRoute(this.entityType, 'put', this.id) : ''
      // Viewable entity
      } else if(can('access', this.entitySlug)) {
        return entityRoute(this.entityType, 'put', this.id) // view route is the same as edit route
      }
      return ''
    },
    status() {
      if (Array.isArray(this.value)) {
        return this.value[0]
      }

      return this.value
    },
  },
  methods: {
    can, entityRoute,
    async download(){
      try {
        const response = await this.erp.ext.request.axiosInstance.get(this.value, {
          responseType: 'blob',
        })

        downloadFile(response.data, this.value.split('/').pop())
      } catch (error) {
        console.error(error)
      }
    },
    getFirstImage(images) {
      let image_url = ''
      if(images){
        const parsedImages = (typeof images == 'string' ? JSON.parse(images) : images) || []

        if (parsedImages.length > 0) {
          if (parsedImages[0].type === 'embed-video') {
            image_url = parsedImages[0].thumbnail
          } else {
            image_url = parsedImages[0].url
          }
        }
      }

      return image_url ?? '/img/fallback.png'
    },

    getOrderVendorImage(){
      const availableImages = ['amazon', 'bol', 'decathlon', 'sitecart']

      let vendor = false
      if(this.value && Array.isArray(this.value) && this.value.length){
        vendor = Object.values(this.value[0])[0] // first value in non translated format
      }

      if(vendor && availableImages.includes(vendor)) {
        return `/img/table/order-vendor/${vendor}.png`
      }

      return false
    },
  },
}
</script>

<style lang="scss">
.field-avatar {
  img.table-image {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
}
</style>
