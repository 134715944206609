<template>
  <div class="cms-block cms-block-embed-video">
    <div v-if="getSetting('link')" class="cms-block-embed-video-wrapper" :style="wrapperStyle">
      <div class="ar-16-9">
        <iframe
          @click.stop.prevent.self
          :src="generateEmbeddedSrc(getSetting('link'), embeddedSrcOptions)"
          allow="autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameborder="0"
          allowfullscreen=""
          :width="getSetting('width')"
          height="100%"
          position="absolute"
          :loading="getSetting('lazy_load') === 1 ? 'lazy' : 'eager'"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { generateEmbeddedSrc } from "@/utilities/helper"
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin";

export default {
  mixins: [CraftSettingsMixin],
  inject: ['editor', 'node'],
  craft: {
    defaultProps: {
      settings: {
        link: '', // old - style -> embedVideo
        width: '100%', // old - style -> child-width
        alignment: 'left', // old - style -> child-alignment
        // Settings 10.2023
        start_time: null,
        end_time: null,
        autoplay: 0,
        //start_following_video: 0, not needed
        hide_controls: 0,
        loop: 0,
        mute: 0,
        // lazy_load: 0, todo lazy load
        // END Settings 10.2023
      },
    },
  },
  computed: {
    wrapperStyle(){
      let margin, width = ''

      if(this.getSetting('alignment') === 'left'){
        margin = "0 auto 0 0"
      }else if(this.getSetting('alignment') === 'center'){
        margin = "0 auto"
      } else if(this.getSetting('alignment') === 'right'){
        margin = "0 0 0 auto"
      }

      width = this.getSetting('width')
      return {
        width: width,
        margin: margin,
      }
    },
    embeddedSrcOptions() {
      // disable autoplay in editor
      if (this.editor.enabled) {
        return { ...this.elementProps.settings, 'autoplay': 0 }
      } else {
        return this.elementProps.settings
      }
    },
  },
  created(){
    // required because of the new settings "Settings 10.2023"
    this.normalizeComponentSettings()
  },
  methods: {
    generateEmbeddedSrc,
  },
}
</script>