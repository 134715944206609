<template>
  <div class="row mb-2">
    <label class="mx-3"><b>{{ translate('Hide elements on', 'page-builder') }}</b></label>

    <div class="col-md-12">
      <craft-toggle
          :label="'Desktop'"
          :id="'desktop'"
          :value="getClass('desktop')"
          :value-on="'frontside-d-lg-none'"
          :value-of="''"
          @input="setClass('desktop', $event);"
      />
    </div>

    <div class="col-md-12">
      <craft-toggle
          :label="'Tablet'"
          :id="'tablet'"
          :value="getClass('tablet')"
          :value-on="'frontside-d-md-none'"
          :value-of="''"
          @input="setClass('tablet', $event)"
      />
    </div>

    <div class="col-md-12">
      <craft-toggle
          :label="'Mobile'"
          :id="'mobile'"
          :value="getClass('mobile')"
          :value-on="'frontside-d-xs-none'"
          :value-of="''"
          @input="setClass('mobile', $event)"
      />
    </div>
  </div>
</template>

<script>

import { Chrome } from 'vue-color'
import TransitionExpand from '../../../TransitionExpand.vue'
import CraftClassSettingsMixin from "@/modules/pagebuilder/mixins/CraftClassSettingsMixin";
import {CraftToggle} from "@/modules/pagebuilder/components/craft-inputs";

export default {
  name: "VisibilitySettings",
  mixins: [CraftClassSettingsMixin],
  inject: ['editor'],
  components: {
    CraftToggle,
    color: Chrome,
    TransitionExpand,
  },
};
</script>

<style lang="scss" scoped>
</style>
