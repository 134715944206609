<template>
  <div class="form-group">
    <div class="row">
      <div class="col-md-10">
        <label class="label">{{ translate(label, 'page-builder') }}</label>
      </div>
      <div class="custom-control custom-switch col-md-1">
        <input type="checkbox"
               class="custom-control-input"
               :id="id"
               :checked="value === valueOn"
               :disabled="disabled"
               @click.stop="toggleChecked"
        >
        <label class="custom-control-label" :for="id"></label>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "CraftToggle",
  inject: ['editor'],
  mixins: [],
  props: {
    id: {
      required: true,
    },
    label: {
      required: true
    },
    value:{
      required: true
    },
    valueOn: {
      required: false,
      default: 1
    },
    valueOff: {
      required: false,
      default: 0
    },
    cssClass: {
      required: false
    },
    disabled: {
      required: false,
    },
  },
  methods: {
    toggleChecked() {
      if(this.value === this.valueOn){
        this.$emit('input', this.valueOff)
      } else {
        this.$emit('input', this.valueOn)
      }
    },
  }
}
</script>

<style scoped>
.custom-switch label:focus {
  outline: none !important;
  box-shadow: none;
}

.custom-control-label::before {
  color: #F5F8FB;
  background-color: #e4e4e4;
  border: none;
}

.custom-switch {
  padding-left: 3.5rem;
}
</style>
