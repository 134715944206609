<template>
  <div class="cms-block cms-block-banner" :data-href="dataHref" :data-target="dataTarget">

    <component-style :value="componentStyle"/>

    <div :id="bannerId" class="cms-block-banner-wrapper" :class="bannerWrapperClasses">

      <div v-if="this.getSetting('animation') === '2'" class="cms-block-banner-border">
        <div class="cms-block-banner-border-lt"></div>
        <div class="cms-block-banner-border-rb"></div>
        <div class="cms-block-banner-border-tr"></div>
        <div class="cms-block-banner-border-bl"></div>
      </div>

      <div class="cms-block-banner-image-wrapper">
        <picture>
          <source type="image/webp" :srcset="replaceExtension(getSetting('image'), 'webp')">
          <img :src="src" loading="lazy">
        </picture>
        <div v-if="this.getSetting('background_overlay_color')" class="cms-block-banner-background-overlay" :class="backgroundOverlayClasses"></div>
      </div>


      <div class="cms-block-banner-content" :class="contentWrapperClasses">
        <div v-if="hasContent(getSetting('title'))"
             class="cms-block-banner-title"
             v-html="getSetting('title')"></div>
        <div v-if="hasContent(getSetting('description'))"
             class="cms-block-banner-description"
             v-html="getSetting('description')"></div>
        <div v-if="getSetting('button_text')"
             class="cms-block-banner-button">
          <a
            :href="getSetting('button_link')"
            :target="linkTarget"
            class="btn btn-link"
          >{{ getSetting('button_text') }}</a>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import ComponentStyle from "@/modules/pagebuilder/components/components/elements/components/ComponentStyle.vue"
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import SettingMixin from "@/modules/pagebuilder/craft/components/settingMixin"
import Background from "@/modules/pagebuilder/components/components/settings/sections/design/Background.vue"
import { replaceExtension } from "@/utilities/formatters"

export default {
  name: "BannerElement",
  components: {
    Background,
    ComponentStyle,
  },
  mixins: [
    CraftSettingsMixin, SettingMixin,
  ],
  inject: ['editor', 'node'],
  craft: {
    defaultProps: {
      settings: {
        link: '',
        link_target: '_self',

        image: '',
        height: '200px',
        image_filter: 'none',
        image_filter_hover: 'none',

        title: '<h3>Lorem ipsum.</h3>',
        description: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
        background_overlay_color: '',

        button_text: 'Button',
        button_background_color: '',
        button_background_hover_color: '',
        button_text_color: '',
        button_hover_text_color: '',
        button_border_color: '',
        button_border_style: '',
        button_border_width: '',
        border_hover_color: '',
        border_radius: '.25rem',
        button_font_size: '',
        button_width: '25%',
        button_margin: '0 auto 0 0',
        button_link: '',
        button_link_target: '_self',

        content_justify_content: 'center',
        show_content: 'always',
        show_background_overlay: 'always',
        animation: '',
      },
    },
  },
  computed: {
    dataHref() {
      return this.getSetting('link') ? this.getSetting('link') : null
    },
    dataTarget() {
      return (this.getSetting('link_target') && this.getSetting('link_target') !== '_self') ? this.getSetting('link_target') : null
    },
    linkTarget() {
      return (this.getSetting('button_link_target') && this.getSetting('button_link_target') !== '_self') ? this.getSetting('button_link_target') : null
    },
    bannerId() {
      return 'banner-' + this.node.uuid
    },
    bannerWrapperClasses() {
      let classes = []

      // Image filter
      if (this.getSetting('image_filter') !== 'none') {
        classes.push('image-filter-'+this.getSetting('image_filter'))
      }
      classes.push('image-filter-hover-'+this.getSetting('image_filter_hover'))


      // Animation
      if (this.getSetting('animation')) {
        classes.push('cms-block-banner-animation-'+this.getSetting('animation'))
      }

      return classes
    },
    contentWrapperClasses(){
      let classes = []

      if (this.getSetting('show_content') === 'show_on_hover') {
        classes.push('show-on-hover')
      } else if (this.getSetting('show_content') === 'hide_on_hover') {
        classes.push('hide-on-hover')
      }
      return classes
    },
    backgroundOverlayClasses(){
      let classes = []

      if (this.getSetting('show_background_overlay') === 'show_on_hover') {
        classes.push('show-on-hover')
      } else if (this.getSetting('show_background_overlay') === 'hide_on_hover') {
        classes.push('hide-on-hover')
      }
      return classes
    },

    componentStyle() {
      let styles = ''

      // overlay
      if (this.getSetting('background_overlay_color')) {
        styles += `#${this.bannerId} .cms-block-banner-background-overlay { background: ${this.getSetting('background_overlay_color')} }`
      }

      // height
      if (this.getSetting('height')) {
        styles += `#${this.bannerId} { height: ${this.getSetting('height')} }`
      }

      // Content vertical alignment
      styles += `#${this.bannerId} .cms-block-banner-content { justify-content: ${this.getSetting('content_justify_content')} }`

      // Button styles
      if (this.getSetting('button_background_color')) {
        styles += `#${this.bannerId} a { background-color: ${this.getSetting('button_background_color')} !important }`
      }
      if (this.getSetting('button_background_hover_color')) {
        styles += `#${this.bannerId} a:hover { background-color: ${this.getSetting('button_background_hover_color')} !important }`
      }
      if (this.getSetting('border_radius')) {
        styles += `#${this.bannerId} a { border-radius: ${this.getSetting('border_radius')} }`
      }
      if (this.getSetting('button_width')) {
        styles += `#${this.bannerId} a { width: ${this.getSetting('button_width')} }`
      }
      if (this.getSetting('button_margin')) {
        styles += `#${this.bannerId} a { margin: ${this.getSetting('button_margin')} }`
      }
      if (this.getSetting('button_border_color')) {
        styles += `#${this.bannerId} a { border-color: ${this.getSetting('button_border_color')} } `
      }
      if (this.getSetting('button_text_color')) {
        styles += `#${this.bannerId} a { color: ${this.getSetting('button_text_color')} }`
      }
      if (this.getSetting('button_hover_text_color')) {
        styles += `#${this.bannerId} a:hover { color: ${this.getSetting('button_hover_text_color')} }`
      }
      if (this.getSetting('button_font_size')) {
        styles += `#${this.bannerId} a { font-size: ${this.getSetting('button_font_size')}px }`
      }
      if (this.getSetting('border_hover_color')) {
        styles += `#${this.bannerId} a:hover { border-color: ${this.getSetting('border_hover_color')} }`
      }
      if (this.getSetting('button_border_style')) {
        styles += `#${this.bannerId} a { border-style: ${this.getSetting('button_border_style')} }`
      }
      if (this.getSetting('button_border_width')) {
        styles += `#${this.bannerId} a { border-width: ${this.getSetting('button_border_width')}px }`
      }

      return styles
    },
  },
  methods: {
    replaceExtension,
    hasContent(content){
      return content && content !== '<p></p>'
    },
  },
}
</script>