import router from '@/router'
import {routes} from './database'

const builderTypeEntities = [
  'cms-pages',
  'cms-sections',
  'blogs',
  'portfolios',
  'testimonials',
  'sliders',
  'cms-templates',
]
export let environment = {
    store: null,
    request: null,
    router: null,
    routes: null
}

/*
*   Random helpers
*/

export const exportTypes = ['txt', 'json', 'csv']

export const widthTypes = [
    {key: 1, value: 'Width 1 part'},
    {key: 2, value: 'Width 2 part'},
    {key: 3, value: 'Width 3 part'},
    {key: 4, value: 'Width 4 part'},
    {key: 5, value: 'Width 5 part'},
    {key: 6, value: 'Width 6 part'},
    {key: 7, value: 'Width 7 part'},
    {key: 8, value: 'Width 8 part'},
    {key: 9, value: 'Width 9 part'},
    {key: 10, value: 'Width 10 part'},
    {key: 11, value: 'Width 11 part'},
    {key: 12, value: 'Width 12 part'},
]

// only allow filtering on columns that have correct input/select in the template
// only allow translatable on columns that can be translatable
export const inputTypes = {
  image: {
    label: 'Image',
    component: "logo",
  },
  image_advanced: {
    label: 'Image advanced',
    translatable: true,
  },
  icon: {
    label: 'Icon',
    component: "icon_picker",
  },
  text: {
    label: 'Text field',
    filterable: true,
    translatable: true,
    component: "input",
  },
  text_responsive: {
    label: 'Text responsive field',
    translatable: true,
    component: "input-responsive",
  },
  hidden: {
    label: 'Hidden',
    component: "hidden",
  },
  select: {
    label: 'Select',
    filterable: true,
    translatable: true,
    component: "select",
  },
  multiselect: {
    label: 'Multiselect',
    filterable: true,
    translatable: true,
    component: "select",
  },
  treeselect: {
    label: 'Treeselect',
    filterable: true,
    component: "treeselect",
  },
  table_multiselect: {
    label: 'Table multiselect',
    component: 'table-select',
  },
  checkbox: {
    label: 'Checkbox',
    component: "checkbox",
  },
  radio: {
    label: 'Radio',
    component: "radio",
  },
  textarea: {
    label: 'Textarea',
    translatable: true,
    component: "textarea",
  },
  simple_textarea: {
    label: 'Simple textarea',
    translatable: true,
    component: "textarea-simple",
  },
  html_editor: {
    label: 'HTML Editor',
    translatable: true,
    component: "html-editor",
  },
  color_picker: {
    label: 'Color picker',
    component: "color-picker",
  },
  cms: {
    label: 'CMS',
    subInputTypes: {
      'cms-navigation': { label: 'CMS Navigation (no render)' },
    },
  },
  erp: {
    label: 'ERP',
    subInputTypes: {
      'erp-navigation': { label: 'erp-navigation (no render)' },
    },
  },
  page_builder: {
    label: 'Page builder (no render)',
    component: "page-builder",
  },
  pickers: {
    label: 'Pickers',
    subInputTypes: {
      date: { label: 'Date' },
      month: { label: 'Month' },
      year: { label: 'Year' },
      datetime: { label: 'Date & Time' },
      datetime_iso: { label: 'Date & Time ISO' },
      time: { label: 'Time' },
      week: { label: 'Week' },
      // range: { label: 'range' }
      range_date: { label: 'Range date' },
      range_month: { label: 'Range month' },
      range_year: { label: 'Range year' },
      range_datetime: { label: 'Range date & time' },
      range_time: { label: 'Range time' },
      range_week: { label: 'Range week' },
    },
  },
  percentage: {
    label: 'Percentage',
    component: "range",
  },
  range: {
    label: 'Range',
    component: "range",
  },
  number: {
    label: 'Number',
    component: "input",
  },
  number_responsive: {
    label: 'Number responsive field',
    component: "input-responsive",
  },
  int: {
    label: 'Integer',
    component: "input",
  },
  int_responsive: {
    label: 'Integer responsive field',
    component: "input-responsive",
  },
  font_settings: {
    label: 'Font settings',
    component: "font-settings",
  },
  font_and_media_settings: {
    label: 'Font and media settings',
    component: "font-and-media-settings",
  },
  background_settings: {
    label: 'Background settings',
    component: "background-settings",
  },
  file_picker: {
    label: 'File picker (Library)',
    translatable: true, // alt & title can be translatable
    component: "file-picker",
  },
  file_upload: {
    label: 'File upload',
    component: 'file-upload',
  },
  url_files: {
    label: 'URL Files',
    component: "url-files",
  },
  usps_settings: {
    label: 'USPs settings',
    component: "usps-settings",
  },
  text_with_caution: {
    label: 'Text with caution',
    component: "text-with-caution",
  },
  datatable: {
    label: 'Data Тable',
    component: "data-table",
  },
  datatable_editable: {
    label: 'Data Table - Editable',
    component: "data-table-editable",
  },
  // rock hot fix for citizen -> clients, todo remove me once the field type is changed to datatable_editable
  // editable_datatable: {
  //   label: 'Data Table - Editable OLD',
  //   component: "data-table-editable",
  // },
  // END rock hot fix for citizen -> clients
  gallery: {
    label: 'Gallery',
    component: 'gallery',
    translatable: true, // alt & title can be translatable
  },
  json: {
    label: 'JSON',
    component: "json",
  },
}
export const getComponentName = type =>  inputTypes[type].component + '-field'

export const validation = {

}

export function validateEntity(e){
    if(!e.model || !e.name || e.model.length == 0 || e.name.length == 0){
        return 'Name and model are required'
    }
    if(e.default_properties.length == 0){
        return 'You must add atleast 1 property'
    }
    let [defaultval, visible, filterable, translatable] = [false, false, false, false]
    for (const prop of e.default_properties) {
        if(!prop.name || prop.name.length == '0'){
            return 'All properties must have name'
        }
        // defaultval = prop.default || defaultval
        // visible = prop.visibleInTable || visible
        // filterable = prop.filterable || filterable
        //
        // if(!defaultval || !visible || !filterable){
        //     return 'You must have atleast one default, visible in table and filterable property'
        // }
    }

    return false
}

export function checkIfFormIsValid(form){
    for (const tab in form) {
        for (const field of form[tab]) {
            if(field.errors && Object.values(field.errors).length > 0){
                return false
            }
        }
    }
    return true
}

// todo rename to checkIfFormIsValid and remove the function above
export function checkIfFormIsValidNoTabs(form){

    for (const field of form) {
        if(field.errors && Object.values(field.errors).length > 0){
            return false
        }
    }

    return true
}

export function groupForm(properties){
    let groupedForm = {}
    properties.forEach(prop => {
        if(!groupedForm[prop['group_by']]){
            groupedForm[prop['group_by']] = []
        }
        groupedForm[prop['group_by']].push(prop)
    });

    return groupedForm
}

export function groupEntityForm(properties){
  let groupedForm = {
    '__default__': { // default tab
      '__default__': [], // default accordion
    },
  }

  properties.forEach(prop => {
    // tab
    let tab = '__default__'
    if(prop['tab']) {
      tab = prop['tab']
    }
    if(!groupedForm[tab]){
      groupedForm[tab] = {
        '__default__': [],
      }
    }

    // accordion
    let accordion = '__default__'
    if(prop['group_by']) {
      accordion = prop['group_by']
    }
    if(!groupedForm[tab][accordion]){
      groupedForm[tab][accordion] = []
    }

    groupedForm[tab][accordion].push(prop)
  })

  return groupedForm
}

export function groupFormFieldsByName(properties){
    let groupedFields = {}
    properties.forEach(prop => {
        groupedFields[prop['name']] = prop
    });

    return groupedFields
}

export const noDataTableFilters = [
    {name: 'name', type: 'text', value: null},
    {name: 'email', type: 'text', value: null},
    {name: 'role', type: 'text', value: null},
]

export function navigationObjectFromApiData (req){
    let mappedData = []
    req.data.forEach(record => {
        let recordParsed = createEntityFormObjectNoTabs(record.options, 'key')
        mappedData.push({title: recordParsed.title, url: recordParsed.url_key, nav: []})

    })
    return mappedData
}

export function navigationObjectFromModules (modules){
    let mappedData = []
    Object.values(modules).forEach(record => mappedData.push({
        title: record.key,
        url: '',
        nav: []
    }))
    return mappedData
}

export function createBackendModelFromSlug(slug){
    let modelParts = slug.split('-')
    let model = ''
    for (let part of modelParts) {
        model += part.charAt(0).toUpperCase() + part.slice(1);
    }
    return model
}

export function entityRoute(entityType, method = null, entityId = null){
  let route = '/'

  // Builder
  if(builderTypeEntities.includes(entityType)) {
    route += `builder/${entityType}`
  // Products
  } else if (entityType === 'inventory') {
    route += 'products'
  // Modules without real entity
  } else if (['webshop-customers', 'users'].includes(entityType) ) {
    route += entityType
  // custom routes
  } else if (['orders'].includes(entityType) ) {
    route += 'webshop-orders'
  // Default entity screen
  } else {
    route += `entity/${entityType}`
  }

  // Create or Edit or Index
  if(method === 'post'){
    route += '/create'
  } else if(method === 'put') {
    route += `/${entityId}`
  } // else index

  // Examples: /entity/cms-blocks/4, /builder/cms-pages/create, /users/create

  return route
}

export function cleanseInput(input, data, type) {
  // cleanse values that does not exist in options
  if (data !== null){ // check if data is loaded first
    if (['multiselect', 'table_multiselect'].includes(type)) {
      // Filter values based on the existence of the "key" in data
      input = input.filter(item => data.some(obj => obj.key === item))

    } else if (['select', 'table_select', 'visual_swatch'].includes(type)) {
      // remove value if does not exists in data
      if (typeof data.find(item => item.key === input) === 'undefined') {
        input = null
      }
    }
  }

  return input
}