<template>
  <div class="form-group">
    <div class="row align-items-center">
      <div class="col-sm-5">
        <slot name="input-first"/>
      </div>

      <div class="col-sm-2 text-center align-self-end">
        <div class="form-group">
          <button
              class="btn btn-chain"
              :class="chaining ? 'active' : ''"
              @click="chaining = !chaining"
          >
            <i class="icon-erp-link"></i>
          </button>
        </div>
      </div>

      <div class="col-sm-5">
        <slot name="input-second"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  inject: ["editor"],
  name: "CraftChainedInputs",
  props:{
    firstValue: {
      required: true,
    },
    secondValue: {
      required: true,
    },
  },
  data() {
    return {
      chaining: false,
    };
  },
  watch: {
    'firstValue'(newVal) {
      if (this.chaining && this.firstValue !== this.secondValue) {
        this.$emit('inputSecondValue', this.firstValue)
      }
    },
    'secondValue'(newVal) {
      if (this.chaining && this.firstValue !== this.secondValue) {
        this.$emit('inputFirstValue', this.secondValue)
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-chain {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 0;
}

.btn-chain.active {
  /*background: #f1f4f8;*/
  i {
    font-weight: bold;
  }
}
</style>
