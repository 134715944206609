<template>
  <div class="row">
    <template v-for="(field, fieldI) in attrSetGroup">
      <div v-if="typeof field.permission === 'undefined' || field.permission !== 'd'"
           :key="fieldI"
           :class="['col-md-'+ (field.width || 6), { 'd-none' : field.type === 'hidden' || field?.v_hide }]"
      >
        <div>
          <field
            @input="v => $emit('setValue', field, v)"
            @createOptions="v => $emit('createOptions', v)"
            :value="options[field.name]"
            :field-props="field"
            :data-filters="dataFilters"
            :entity="$route.params.entityType"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import field from "./InputEntityProps.vue"

export default {
  name: "FormRender",
  components: {
    field,
  },
  props: {
    attrSetGroup: {
      required: true,
      type: Object,
    },
    options: {
      required: true,
      type: Object,
    },
    dataFilters: {
      required: true,
      type: Object,
    },

  },
}
</script>