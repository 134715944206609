import kebabCase from 'lodash/kebabCase';
import Indicator from './Indicator';
import Node from './Node';

class Editor {
  constructor(nodes = [], resolverMap = {}) {
    this.nodeMap = {};
    this.selectedNode = null;
    this.draggedNode = null;
    this.indicator = new Indicator(3); // blockzapps added 3px barSize
    this.enabled = true;
    this.bindAllAttributes = false; // blockzapps added, uuid is required when exporting as preview image
    this.renderKey = 0; // blockzapps added, used to fix reactivity when switching between pages/contents
    this.hasTexteditorActive = false; // blockzapps added, used to disable dragging
    this.showSettingsPopup = null // blockzapps added, used to disable dragging - string (setting name) | null

    this.setTopLevelNodes(nodes);
    this.setResolverMap(resolverMap);
  }

  enable() {
    this.enabled = true;
  }

  // Blockzapps added bindAllAtributes
  disable(bindAllAttributes=false) {
    this.selectNode(null)
    this.bindAllAttributes = bindAllAttributes
    this.enabled = false
  }

  setResolverMap(resolverMap) {
    this.resolverMap = {};

    Object.entries(resolverMap).forEach(([key, value]) => {
      this.resolverMap[kebabCase(key)] = value;
    });
  }

  initializeNodeMap(nodes) {
    nodes.forEach((node) => {
      this.nodeMap[node.uuid] = node;
      this.initializeNodeMap(node.children);
    });
  }

  setTopLevelNodes(nodes) {
    this.nodes = nodes;
    this.initializeNodeMap(nodes);
  }

  findNode(uuid) {
    return this.nodeMap[uuid];
  }

  // Blockzapps added
  replaceNode(node) {
    node.setParent(this.selectedNode.parent)
    this.removeNode(this.selectedNode)
  }
  // End Blockzapps added

  selectNode(node) {
    this.resetTempProps(this.selectedNode) // blockzapps added
    this.selectedNode = node;
  }

  dragNode(node) {
    this.draggedNode = node;
  }

  findResolver(name) {
    return this.resolverMap[kebabCase(name)];
  }

  removeNode(node) {
    node.makeOrphan();

    if (node === this.selectedNode) {
      this.selectNode(null);
    }
  }

  getCraftConfig(node) {
    let resolver;
    if (node.isCanvas()) {
      resolver = this.findResolver(node.props.component);
    } else {
      resolver = this.findResolver(node.componentName);
    }

    return resolver.craft || {};
  }

  getSettings(node) {
    return this.getCraftConfig(node).settings || {};
  }

  export() {
    const nodesData = this.nodes.map((node) => node.serialize());

    return JSON.stringify(nodesData);
  }

  import(plainNodesData) {
    try {
      const nodesData = JSON.parse(plainNodesData);
      this.nodes = nodesData.map((data) => Node.unserialize(this, data));
    } catch {
      throw new Error('The input is not valid.');
    }
  }

  // blockzapps added
  resetTempProps(node){
    if(node && node.componentName === 'TextElement'){
      node.props.showActions = false;
    }
  }

  async exportAsHtml(){
    // when editor is disabled, not needed things such as add new section/row buttons are hidden
    await this.disable()
    return document.getElementById("app").innerHTML
      .replaceAll('<!---->', '') // get rid of hidden elements (<!---->) and put the whole html
      .replaceAll(' draggable="false"', '') // hot-fix #1093617
  }

  handleDragEnd() {
    this.dragNode(null)
    this.indicator.hide()
  }

  // END blockzapps added
}

export default Editor;
