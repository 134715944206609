<template>
  <component is="style" v-if="stylesComputed" type="text/css">
    {{ stylesComputed }}
  </component>
</template>

<script>
import { prefixCss } from "@/modules/pagebuilder/utilities/helpers"

export default {
  name: "BuilderStyles",
  inject: ['editor'],
  computed: {
    stylesComputed() {
      const componentStyles = (node, r = []) => {
        if (node) {
          r.push(this.nodeBackgroundImageOverlay(node))

          if (node.children && node.children.length) {
            node.children.forEach(c => r.push(...componentStyles(c)))
          }
        }

        return r
      }

      const styles = componentStyles(this.editor.nodes[0])

      return styles.filter(Boolean).map(item => this.editor.enabled ? prefixCss(item) : item).join(" ")
    },
  },
  methods: {
    nodeBackgroundImageOverlay(node) {
      const settings = node.props?.settings
      const hasBackgroundImage = node.props?.elementStyle && node.props?.elementStyle['background-image'] && node.props?.elementStyle['background-image'] !== 'none'

      let styles = ''

      if (settings && hasBackgroundImage) {
        const { background_image_overlay_color, background_image_overlay_opacity } = settings

        let overlay = `[data-uuid="${node.uuid}"]:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          ${background_image_overlay_color ? `background-color: ${background_image_overlay_color};` : ''}
          ${background_image_overlay_opacity ? `opacity: ${parseFloat(background_image_overlay_opacity) / 100};` : ''}
        }`

        styles += overlay
      }

      return styles
    },
  },
}
</script>

<style scoped lang="scss">

</style>