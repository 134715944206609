<template>
	<div :class="{'position-relative vh-100': !isEditable}" class="container">
    <import-export-entity
      v-if="data"
      :value="data"
      @import="importData"
    />
		<!-- <h5 class="modal-title">Entities</h5> -->
<!--		<div class="row" v-if="isEditable && data">-->
		<div class="row" v-if="isEditable && data">
			<div class="col-12">

				<div class="card bg-white">
					<!-- ENTITY DEFAULT DATA -->
					<div class="card-header bg-primary b-b-pr">
						<h3 class="text-white">{{ translate('Entity', 'entities') }} {{data.name}} </h3>
					</div>

					<div class="card-body">
						<form action="" method="post" @submit.prevent="save">
							<input type="hidden" name="id" v-model="data.id">

							<div class="row">
								<div class="col-md-6 col-xs-12">
									<div class="form-group">
										<label class="form-control-label">
											{{ translate('Name', 'entities') }}
										</label>
										<input type="text" class="form-control" autocomplete="off" placeholder="enter name"
											   v-model="data.name">
									</div>
								</div>
                <div class="col-md-6 col-xs-12">
                  <div class="form-group">
                    <label class="form-control-label">
                      {{ translate('Slug', 'entities') }}
                    </label>
                    <input type="text" class="form-control" v-model="data.slug">
                  </div>
                </div>
								<div class="col-md-6 col-xs-12">
									<div class="form-group">
										<label class="form-control-label">
											{{ translate('Model', 'entities') }}
										</label>
										<input type="text" class="form-control" v-if="isAdmin"
											   v-model="data.model">
										<span class="form-control" v-else v-html="data.model"></span>
									</div>
								</div>
                                <div class="col-md-6 col-xs-12" v-if="isAdmin">
									<div class="form-group">
										<label class="form-control-label">
											{{ translate('Type', 'entities') }}
										</label>
										<input type="text" class="form-control" v-model="data.type">
									</div>
								</div>
                <div class="col-md-6 col-xs-12">
                  <div class="form-group">
                    <label class="form-control-label">
                      {{ translate('Filter', 'entities') }}
                    </label>
                    <input type="text" class="form-control" v-model="data.filter">
                  </div>
                </div>

                <div class="col-md-6 col-xs-12">
                  <div class="form-group">
                    <label class="control-label">
                      {{ translate('Primary fields', 'entities') }}
                    </label>
                    <v-select
                      v-model="primaryFields"
                      :options="textFields"
                      :reduce="option => option.key"
                      multiple
                      class="select2-form"
                    />
                  </div>
                </div>

                                <div class="col-md-6 col-xs-12" v-if="isAdmin">
									<div class="form-group">
										<label class="form-control-label">
											{{ translate('Is locked', 'entities') }}
										</label>
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox"
                                                   class="custom-control-input"
                                                   id="locked"
                                                   :value="false"
                                                   @click="data.locked = !data.locked"
                                                   v-model="data.locked">
                                            <label class="custom-control-label" for="locked"></label>
                                        </div>
                                        <small class="text-muted">
                                            {{ translate('This property lock the entire entity form builder and only admin will be able to edit this entity and change this property.', 'entities') }}
                                        </small>
									</div>
								</div>
							</div>
						</form>
					</div>
					<!-- ENTITY DEFAULT DATA -->

        </div>

        <div class="card">

					<!-- PROPERTIES -->
					<div class="card-header d-flex bg-primary justify-content-between align-items-center b-b-pr">
						<h3 class="text-white">
							{{ translate('Form properties', 'entities') }}
						</h3>
            <button class="btn btn-white" @click="openPropertyModal">
              {{ translate('Add new', 'entities') }}
            </button>
					</div>
					<div class="card-body px-0">
						<form-fields
              ref="formFields"
              :key="formFieldsRenderKey"
              v-model="data.default_properties"
              :currently-edited="selectedProperty"
              @edit="edit"
            />
					</div>
					<!-- PROPERTIES END -->
					<div class="card-footer d-flex justify-content-end b-t-pr">
						<button class="btn btn-white rounded-pill" @click="goBack">{{ translate('Go back', 'global') }}</button>
            <button type="submit" class="btn btn-primary rounded-pill"
								:disabled="data.default_properties.length == 0 || data.name == '' || data.model == ''"
								@click="save(true)">
							{{ translate('Save & Go back', 'global') }}
						</button>
            <button type="submit" class="btn btn-primary rounded-pill"
                    :disabled="data.default_properties.length == 0 || data.name == '' || data.model == ''"
                    @click="save()">
              {{ translate('Save', 'global') }}
            </button>
					</div>
					<v-infinite-progressbar v-if="loading"></v-infinite-progressbar>
				</div>
			</div>

<!--			<div class="col-12 col-lg-5">-->
<!--				<div class="card right-sticky">-->
<!--					<property-edit-->
<!--                        v-if="selectedProperty"-->
<!--                        :key="renderKey"-->
<!--						            :entity-model="data.model"-->
<!--						            @delete="deleteProp"-->
<!--						            @close="selectedProperty = null"-->
<!--                        v-model="selectedProperty"-->
<!--						            :all-properties="data.default_properties"-->
<!--                        :data-types="dataTypes"-->
<!--          />-->
<!--          <input-types v-else @addProp="addProperty"/>-->
<!--				</div>-->
				<!--                <div class="card">-->
				<!--                    <widgets @add="addWidget"></widgets>-->
				<!--                </div>-->
<!--			</div>-->
		</div>
        <div class="locked-entity" v-if="!isEditable">
            <i class="fa fa-lock"></i>
            <span>
                {{ translate('This entity is locked and only Admin can edit it!', 'entities') }}
            </span>
        </div>

    <modal name="edit_property"
           :pivot-x="1"
           :pivot-y="0"
           transition="slide-fade"
           overlayTransition="wait"
           height="100%"
           width="85%"
           @before-close="selectedProperty = null"
           :adaptive="false"
           class="modal-height-100"
    >
      <property-edit
        v-if="selectedProperty"
        :key="renderKey"
        :entity-model="data.model"
        @delete="deleteProp(selectedProperty)"
        @close="closeEditPropertyModal"
        v-model="selectedProperty"
        :all-properties="data.default_properties"
        :data-types="dataTypes"
      />
      <input-types v-else @addProp="addProperty"/>
    </modal>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import vSelect from 'vue-select'
import { db, routes } from "../../utilities/database"
import { environment } from "../../utilities/helper"

// components
import InputTypes from "../../components/entities/InputTypes.vue"
import PropertyEdit from "../../components/entities/PropertyEdit.vue"
import FormFields from './components/FormFields.vue'
import Widgets from "../../components/entities/Widgets.vue"

import { createPropertyRestrictionsObject, entityLabel, parseValue } from "@/utilities/helper"
import { validateEntity } from "../../utilities/helper"

import icons from '../../utilities/icons'
import ImportExportEntity from "@/modules/erp_entities/views/entities/components/ImportExportEntity.vue"

export default {
  name: "entities-form",
  components: {
    InputTypes, vSelect, FormFields, PropertyEdit, Widgets, ImportExportEntity,
  },
  data(){
    return {
      data: null,
      renderKey: 0,
      formFieldsRenderKey: 0,
      selectedProperty: null,
      loading: false,
      dataTypes: {
        'custom': {
          label: 'Custom Dropdown',
          optionsLabel: 'Select Dropdown',
          options: [],
        },
        'moduleNames': {
          label: 'Module',
          optionsLabel: 'Select Module',
          options: [],
        },
        'treeselect': {
          label: 'Treeselect',
          optionsLabel: 'Select Module',
          options: [],
        },
        'treeselectCategory': {
          label: 'Treeselect Category',
          optionsLabel: 'Select Module',
          options: [],
        },
      },

    }
  },
  computed: {
    ...mapState('system', ['locales']),
    icons: ()=> icons,
    isAdmin() {
      return environment.store.getters['system/isAdmin']
    },
    isEditable() {
      return this.isAdmin || !this.data.locked
    },
    textFields() {
      return this.data.default_properties.reduce((textFields, field) => {
        if (field.type === 'text') {
          textFields.push({
            key: field.name,
            label: this.entityLabel(field.label),
          })
        }
        return textFields
      }, [])
    },
    primaryFields: {
      get() {
        if (typeof this.data?.primary_fields === 'string') {
          return JSON.parse(this.data.primary_fields)
        }

        return this.data?.primary_fields || []
      },
      set(newValue) {
        this.$set(this.data, 'primary_fields', JSON.stringify(newValue))
      },
    },
  },
  methods: {
    ...mapMutations('system', ['setEntitiesAndModuleNames']),
    ...mapActions('entities', ['fetchValidationRules']),
    setDataTypeOptions() {

      // Custom dropdowns
      if(this.data.model) {
        environment.request.get(routes.dropdown.filterByModel.replace(':model', this.data.model)).then(res => {
          this.dataTypes['custom']['options'] = res.data.data.reduce((acc, data) => {
            acc.push({ key: data.id.toString(), label: entityLabel(data.name, true) })
            return acc
          }, [])
        })
      }

      // Modules names, Treeselects
      this.dataTypes['moduleNames']['options'] = this.$store.state.system.moduleNames
      this.dataTypes['treeselect']['options'] = this.$store.state.system.moduleNames // same as modules

      // Category treeselects
      this.dataTypes['treeselectCategory']['options'] = [
        { key: 'inventories', label: 'Inventory category' },
        { key: 'blogs', label: 'Blog category' },
        { key: 'portfolios', label: 'Portfolio category' },
        { key: 'testimonials', label: 'Testimonial category' },
      ]
    },

    edit(property){
      this.selectedProperty = property
      this.$modal.show('edit_property')
      this.renderKey++
    },
    openPropertyModal(){
      this.$modal.show('edit_property')
    },
    closeEditPropertyModal(){
      this.$modal.hide('edit_property')
    },
    async deleteProp(property) {
      const confirmDelete = await this.$alert.confirmDelete()
      if(!confirmDelete.isConfirmed) return

      let propIndex = this.data.default_properties.indexOf(property)
      this.data.default_properties.splice(propIndex, 1)
      this.$refs.formFields.deleteProperty(property)
      this.selectedProperty = null
      this.closeEditPropertyModal()
    },
    save(redirect=false) {
      let error = validateEntity(this.data)
      if(error){
        return this.$toast.error(error)
      }

      this.loading = true
      environment.request[this.$route.params.id ? 'put' : 'post'](routes.entities[this.$route.params.id ? 'update' : 'create'].replace(':id', this.$route.params.id), this.data)
        .then(res => {
          this.setEntitiesAndModuleNames()
          if(redirect) {
            this.$router.push(`/entities`)
          }
          this.$toast.requestSuccess()
          this.loading = false
        }).catch(error => {
          this.$alert.formattedError(error)
          this.loading = false
        })

    },
    addProperty(type) {
      let property = {
        type: type,
        label: {},
        width: 6,
        restrictions: createPropertyRestrictionsObject(this.$store.state.system.roles),
        rules: [],
        conditional: { property: null },

        // select fields only
        data_type: '',
        data_module: '',
        data_filters: [],
      }

      this.$refs.formFields.addProperty(property)
      this.closeEditPropertyModal()
    },
    addWidget(widget) {
      console.log(widget)
      if(!this.data.widgets){
        this.data.widgets = []
      }
      this.data.widgets.push(widget)
      this.$forceUpdate()
    },
    removeWidget(i){
      this.data.widgets.splice(i, 1)
      this.$forceUpdate()
      this.$toast.success('Widget removed from form!')
    },
    goBack(){
      this.$router.push(`/entities`)
    },
    importData(data) {
      this.data = JSON.parse(data)
      this.formFieldsRenderKey++
    },
  },
  async created(){
    if (this.$route.params.id) {
      await environment.request.get(`entities/${this.$route.params.id}`).then(res => {
        this.data = res.data.data
      })
    } else {
      this.data = {
        default_properties: [],
      }
    }

    this.setDataTypeOptions()
    this.fetchValidationRules()
  },
}
</script>

<style lang="scss">
.right-sticky {
    position: sticky !important;
    top: 61px;
    z-index: 2;
    height: calc(100vh - 150px);
    overflow-y: auto;
}
.select-icons .vs__dropdown-option {
	display: flex;
	align-items: center;
}
.select-icons .vs__dropdown-option i {
	font-size: 50px;
}

.locked-entity {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #00000020;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #fff;
    font-size: 40px;

    i {
        margin-top: 200px;
    }
}
</style>
