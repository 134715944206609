<template>
  <div>
    <page-title :page-title="translate('Agenda', 'entities')">
      <template #actions>
        <button class="btn btn-primary rounded-pil" @click="createEvent">
          {{ translate('Add new event', 'entities' ) }}
        </button>
        <button class="btn btn-primary rounded-pill" @click="save">
          {{ translate('Save changes', 'global') }}
        </button>
      </template>
    </page-title>

    <full-calendar
      v-if="events"
      ref="calendar"
      :options="calendarOptions"
    />

    <modal
      name="create_new_entity"
      :pivot-x="0.5"
      :pivot-y="0.5"
      height="90%"
      width="80%"
      transition="slide-top"
      overlayTransition="wait"
      classes="remove-modal-height"
    >
      <create-new-option
        :value="createOptions"
        @save="updateEvents"
      />
    </modal>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue"
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { createEntityFormObjectNoTabs } from "@/utilities/helper";
import CreateNewOption from "@/modules/erp_entities/components/entities/CreateNewOption.vue"
import moment from "moment"

export default {
  name: "Agenda",
  components: {
    CreateNewOption, FullCalendar,
  },
  data() {
    return {
      createOptions: null,
      events: null,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        slotDuration: '00:05:00',
        events: [],
        initialView: 'timeGridWeek',
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        select: this.handleEventSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
      },
      currentEvents: [],
    }
  },
  async created() {
    this.$store.state.system.isLoading = true
    await this.updateEvents()
    this.$store.state.system.isLoading = false
  },
  methods: {
    async getEvents() {
      let events = await this.erp.ext.request.axiosInstance.get('modules/events')

      return events.data.data.reduce((curr, prev) => {
        let event = createEntityFormObjectNoTabs(prev.options, 'key')

        curr.push({
          ...prev,
          title: event['title'],
          start: event['start_at'],
          end: event['finished_at'],
          allDay: event['allDay'] || false,
        })

        return curr
      }, [])
    },

    async updateEvents() {
      this.events = await this.getEvents()
      this.calendarOptions.events = this.events
    },

    createEvent() {
      this.createOptions = {
        data_type: 'moduleNames',
        data_module: 'event',
      }

      this.$modal.show('create_new_entity')
    },

    handleEventClick(clickInfo) {
      this.createOptions = {
        data: {
          id: clickInfo.event.id,
        },
        data_type: 'moduleNames',
        data_module: 'events',
      }

      this.$modal.show('create_new_entity')
    },

    handleEvents(events) {
      this.currentEvents = events
    },

    toDateTimeString(string) {
      return moment(string).format('YYYY-MM-DD HH:mm:ss')
    },

    handleEventSelect(event) {
      this.createOptions = {
        data: {
          start_at: this.toDateTimeString(event.startStr),
          finished_at: this.toDateTimeString(event.endStr),
          allDay: Number(event.allDay),
        },
        data_type: 'moduleNames',
        data_module: 'event',
      }

      this.$modal.show('create_new_entity')
    },

    save() {
      console.log('save')
    },
  },
}
</script>

<style scoped>

</style>