<template>
  <div>
    <!-- <transition name="slide">-->
    <filters v-if="hasFilters && records"
             @onFiltersClose="$emit('onFiltersClose')"
             @filter="e => setFilters(e)"
             v-show="filtersActive"
             :key="filtersKey"
             :data="columnsFilterable"/>
    <!-- </transition>-->

    <div class="data-table-holder">

      <div class="d-flex justify-content-between align-items-center table-header pl-4" v-if="records && records.objectData">
        <div class="input-group w-auto h-100 input-group-sm page-builder-modal-input d-flex align-items-center">
          <div class="search-icon">
            <span><i class="fa fa-search"></i></span>
          </div>
          <!-- todo refactor -->
          <input
            type="text"
            class="form-control h-100 search-field"
            :placeholder="searchPlaceholder"
            :value="userPreferences.search"
            @input="e => setSearch(e.target.value)"
          >
        </div>
        <div class="d-flex justify-content-end align-items-center h-100 py-2">
          <div class="col text-right">
            <div class="actions d-flex align-items-center justify-content-end" style="gap: 1rem">
              <div class="d-flex justify-content-end align-items-center h-100 py-2" style="min-width: 150px">
                <div class="nav-item dropdown dropdown-styled dropdown-animate text-nowrap" data-toggle="hover">
                  <a class="nav-link pr-lg-0 py-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{ translate('{count} per page', 'global', { count: records.meta.per_page }) }}
                    <i class="fa fa-chevron-down"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                    <a href="#" class="dropdown-item" v-for="(per_page, pi) in perPage" :key="pi" @click="setProp('perpage', per_page.key)">
                      {{ per_page.value }}
                    </a>
                  </div>
                </div>
              </div>
              <div class="nav-item dropdown dropdown-animate actions-dropdown" v-show="$store.state.system.interface != 'rock'" data-toggle="hover">
                <a class="nav-link dropdown-toggle system-text-primary" href="#" role="button"
                   data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false">
                    {{ translate('Bulk actions', 'global') }}
                </a>
                <div class="dropdown-menu dropdown-menu-arrow p-lg-0">
                  <div class="p-lg-4">
                    <div class="pb-1 pt-1 delimiter-bottom">
                      <a href="#" class="d-flex align-items-center dropdown-item" @click="bulkDelete">
                        {{ translate('Delete', 'global') }}
                      </a>
                      <a href="#" class="d-flex align-items-center dropdown-item" @click="$modal.show('bulk_edit')">
                        {{ translate('Edit', 'global') }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

  <!--                      no ugly tooltip -->
  <!--                        <button class="btn" v-tooltip="'Set column visibility'" @click="openEditFields" style="line-height: 0">-->
              <button v-if="hasFilters" class="btn d-none d-md-inline-block" @click="openEditFields" style="line-height: 0">
                <i class="icon-rock-columns" style="font-size: 1.5rem"></i>
              </button>
            </div>
          </div>

          <button
            v-if="createNewOption"
            @click="e => $emit('createOptions')"
            class="btn btn-primary rounded-pill w-100 mr-4"
          >
            {{ translate('Add new {entity}', 'entities', { entity: entityName }) }}
          </button>
        </div>
      </div>

      <div class="data-table page-table position-relative">

        <loading-circles v-if="loading"/>

        <!-- Show only after initial data load -->
        <template v-if="records">
          <div class="table-responsive">
            <table class="table align-items-center">
              <thead>
              <tr>
                <th width="10px">
                  <div class="custom-control custom-checkbox">
                    <input id="select-all" type="checkbox" class="custom-control-input" v-model="areAllRowsChecked" @click="selectAll">
                    <label class="custom-control-label" for="select-all"></label>
                  </div>
                </th>
                <!-- <th class="pl-2 pr-0">#</th> -->
                <th scope="col" :class="{'sortable' : isSortableColumn(column)}" data-sort="name" v-for="(column, ci) in addedColumns" :key="ci" @click="isSortableColumn(column) && sortByColumn(column.name)">
                  {{ entityLabel(column.label, true) }}
                  <i v-if="isSortableColumn(column)" class="fa" :class="getSortIcon(column.name)" aria-hidden="true"></i>
                </th>
                <th scope="col">{{ translate('Created at', 'global') }}</th>
                <th scope="col" class="text-right">{{ translate('Actions', 'global') }}</th>
              </tr>
              </thead>
              <tbody class="list" v-if="records.columns">
              <tr v-for="(row, i) in records.objectData" :key="i" :class="isEntityLocked(row.options) ? 'row-locked' : ''">
                <td v-if="!isEntityLocked(row.options)" width="10px">
                  <div class="custom-control custom-checkbox">
                    <input :id="'row-' + i" v-model="rowsSelected" type="checkbox" class="custom-control-input" :value="row.id">
                    <label class="custom-control-label" :for="'row-' + i"></label>
                  </div>
                </td>
                <td v-else width="10px">
                  <button @click="can('update', entitySlug) ? unlockRow(row) : null"
                          class="btn-link"
                          v-tooltip="lockMessage(row)"
                  ><i class="fa-solid fa-lock" /></button>
                </td>
                <td v-for="(column, ti) in addedColumns" :key="ti" width="20px">
                  <display-property
                    :value="displayValue(row, column.name)"
                    :raw-value="displayValue(records.data[i], column.name)"
                    :id="row['id']"
                    :field-props="column"
                    :is-locked="isEntityLocked(row.options)"
                    :entityType="entityType"
                    :entity-slug="entitySlug"
                    :primary-fields="records?.primary_fields || []"
                  />
                </td>
                <td>{{ formatDate(row.created_at, null, 'DD MMMM YYYY HH:mm') }}</td>

                <td class="text-right" v-if="canAny(['create', 'update', 'delete', 'access'], entitySlug)">
                  <cta>
                    <action-button :key="`cta-show-${row.id}`" v-tooltip="translate('Show {entity_title}', 'entities', { entity_title: getRowName(row) })"
                                   class-icon="fa-eye"
                                   :to="entityRoute(entityType, 'put', row.id)"
                                   v-if="can('access', entitySlug) && !can('update', entitySlug) && !isDownloadableEntity"
                    ></action-button>
                    <action-button :key="`cta-settings-${row.id}`" v-tooltip="translate('Settings for {entity_title}', 'entities', { entity_title: getRowName(row) })"
                                   class-icon="fa-cogs" @click.native="openSettings(row)" v-if="settings.menu && settings.menu.length > 0">
                    </action-button>
                    <action-button :key="`cta-edit-${row.id}`" v-tooltip="translate('Edit {entity_title}', 'entities', { entity_title: getRowName(row) })"
                                   class-icon="fa-pen"
                                   :to="entityRoute(entityType, 'put', row.id)"
                                   v-if="can('update', entitySlug) && !isEntityLocked(row.options)"
                    >

                    </action-button>
                    <action-button :key="`cta-copy-${row.id}`" v-tooltip="translate('Copy {entity_title}', 'entities', { entity_title: getRowName(row) })"
                                   class-icon="fa-copy" @click.native="duplicate(row)"
                                   v-if="can('create', entitySlug)"
                    >
                    </action-button>

                    <!-- Custom actions -->
                    <action-button :key="`cta-download-${row.id}`" v-tooltip="translate('Download {entity_title}', 'entities', { entity_title: getRowName(row) })"
                                   class-icon="fa-file-pdf" @click.native="downloadRow(row)"
                                   v-if="can('access', entitySlug) && isDownloadableEntity"
                    />
                    <action-button :key="`cta-terminate-${row.id}`" v-tooltip="translate('Terminate {entity_title}', 'entities', { entity_title: getRowName(row) })"
                                   class-icon="fa-ban"
                                   @click.native="terminateRow(row)"
                                   v-if="can('update', entitySlug) && row.actions && row.actions.terminate"
                    />
                    <!-- END Custom actions -->

                    <action-button :key="`cta-delete-${row.id}`" v-tooltip="translate('Delete {entity_title}', 'entities', { entity_title: getRowName(row) })"
                                   class-icon="fa-trash-alt" @click.native="deleteRow(row)"
                                   v-if="can('delete', entitySlug)"
                    >
                    </action-button>
                  </cta>
                </td>
              </tr>
              <tr v-if="!records.objectData?.length || !addedColumns" class="text-center">
                <td colspan="100">{{ translate('No data', 'global') }}</td>
              </tr>
              </tbody>
              <tfoot v-if="hasAggregations">
                <tr>
                  <th></th>
                  <th v-for="(column, ti) in addedColumns" :key="ti" width="20px">
                    <span v-if="records.aggregations[column.name]">
                      {{ translate(aggregationName(column.aggregation), 'entities') }}:
                      {{ records.aggregations[column.name]}}
                    </span>
                  </th>
                  <th></th>
                  <th></th>
                </tr>

              </tfoot>
            </table>
          </div>

          <pagination :disabled="loading" :container-class="'d-flex justify-content-between'"
                      :value="records.meta" v-if="records.meta"
                      @goToPage="v => setProp('page', v)"
          />
        </template>

      </div> <!-- END data-table -->
    </div> <!-- END data-table-holder -->

    <!-- Show only after initial data load -->
    <template v-if="records">
      <modal name="bulk_edit"
             :pivot-x="0.5"
             :pivot-y="0.5"
             height="500px"
             transition="slide-top"
             overlayTransition="wait"
             classes="remove-modal-height">
        <bulk-edit :columns="records" @editAll="bulkEditSelected"></bulk-edit>
      </modal>

      <modal name="settings"
             :pivot-x="1"
             :pivot-y="0"
             transition="slide-fade"
             overlayTransition="wait"
             height="100%"
             width="85%"
             :adaptive="true">
        <entity-settings :value="settings" :show-cancel-button="true"></entity-settings>
      </modal>

      <modal name="duplicate_content"
             :pivot-x="0.5"
             :pivot-y="0.5"
             height="auto"
             width="500px"
             transition="slide-top"
             overlayTransition="wait"
             classes="remove-modal-height">
        <duplicate-content
          v-model="duplicatedContent"
          @save="e => saveDuplicatedContent(e, loadData)"
          :entity="entityType"
          :entity-name-translated="entityNameTranslated"
        />
      </modal>

      <modal name="column-fields-edit"
             :pivot-x="0.5"
             :pivot-y="0.5"
             height="auto"
             width="800px"
             transition="fade-in"
             overlayTransition="wait"
             classes="remove-modal-height">
        <dual-list-box :allAvailableColumns="allAvailableColumns" :selectedColumnNames="selectedColumnNames" @onColumnsChange="onColumnsChange($event)" @closeModal="$modal.hide('column-fields-edit')"/>
      </modal>
    </template>

  </div>
</template>

<script>
import DisplayProperty from './DisplayProperty.vue'

import {
  isVoidValue,
  can,
  canAny,
  getEntityName,
  isEntityLocked,
  downloadFile
} from "@/utilities/helper"
import { environment } from "../../../utilities/helper"
import DuplicateContentMixin from "@/mixins/DuplicateContentMixin"
import EntitySettings from "./../../../components/page/form/EntitySettings.vue"
import { mapGetters, mapState } from 'vuex'
import BulkEdit from "../BulkEdit.vue"
import cta from "./CTA.vue"
import ActionButton from "./ActionButtons.vue"
import LoadingCircles from "./../../../components/utilities/LoadingCircles.vue"
import PageTitle from "./../../../components/page/PageTitle.vue"
import DuplicateContent from "../../../../pagebuilder/components/DuplicateContent.vue"
import DualListBox from "./DualListBox.vue"
import { EeFormTreeselect } from "@/modules/erp_entities/components/forms"
import { entityRoute } from "@/modules/erp_entities/utilities/helper"
import EntityIndexMixin from "@/mixins/EntityIndexMixin"
import Filters from "../../Filters.vue"
import router from "@/router"

export default {
  name: "DataTable",
  components: {
    Filters, DisplayProperty, EntitySettings, BulkEdit, cta, ActionButton, LoadingCircles, PageTitle, DuplicateContent, DualListBox, EeFormTreeselect
  },
  mixins: [EntityIndexMixin, DuplicateContentMixin],
  props: {
    entityType: {
      required: true,
      type: String,
    },
    hasFilters: {
      required: false,
      type: Boolean,
      default: false,
    },
    filtersActive: {
      required: false,
      type: Boolean,
      default: false,
    },
    dtFilters: {
      required: false,
    },
    dtFilterProps: {
      required: false,
    },
    customFilters: {
      required: false,
      type: Array,
      default: () => [],
    },
    isDatatableField: {
      type: Boolean,
      required: false,
      default: false,
    },
    createNewOption: {
      required: false,
      type: Number,
      default: 0,
    },
    dataFilters: {
      type: Object,
      required: false,
    },
    fieldProps: {
      required: false,
    },
  },
  data() {
    return {
      settings: {},
      deleteName: null,
      selectedFilter: null,
      duplicatedContent: {},
      entityName: null,
      filtersKey: 0,
      rowsSelected: [],
    }
  },
  computed: {
    ...mapState('system', ['scope']),
    ...mapGetters('system', ['isAdmin']),
    allAvailableColumns(){
      return this.records.columns.filter(item => {
        return parseInt(item.visible_in_table) === 1 && item.permission !== 'd'
      })
    },
    addedColumns() {
      if(!this.records){
        return []
      }

      if (this.selectedColumnNames) {
        let cols = []
        this.selectedColumnNames.forEach((col, idx) => {
          let item = this.allAvailableColumns.find(item => item.name == col)
          item.order = idx
          cols.push(item)
        })

        return cols.sort((a, b) => a.order - b.order)
      }
      return []
    },
    selectedColumnNames() {
      if (this.userPreferences['columns'].length > 0) {

        // const selected_available_columns = [...new Set(this.records.user_preferences['columns'])] // alex
        const selected_available_columns = this.userPreferences['columns'].filter(item => {
          return this.allAvailableColumns.some(el => el.name == item)
        })

        if(selected_available_columns.length){
          return selected_available_columns
        }
      }

      // all columns marked as visible in table by default
      return this.allAvailableColumns.filter(item => {
        return parseInt(item.visible_in_table_default) === 1
      }).reduce((acc, obj) => {
        acc.push(obj.name)
        return acc
      }, [])
    },
    columnsFilterable(){
      const typesAvailable = ['text', 'textarea', 'select', 'multiselect', 'treeselect']

      return [...this.records.columns, ...this.customFilters].reduce((acc, curr) => {
        // skip non filterable
        if(!typesAvailable.includes(curr.type) || curr.filterable == '0') return acc

        // skip "search" field
        if(curr.name === 'name' || curr.name === 'title') return acc // skip name/title because we already have such filter in the table

        // skip custom fields
        if(curr.name === 'websites' && this.$store.state.system.scope.data.length <= 1) return acc

        if (this.userPreferences['filters'][curr.name]) {
          curr.value = this.userPreferences['filters'][curr.name].value // gets the value from filter in user preferences
        } else {
          curr.value = null
        }
        acc.push(curr)
        return acc
      }, [])
    },
    entityNameTranslated() {
      return this.translate(this.entityName, 'entities')
    },

    entitySlug() {
      if (this.entityType === 'inventory') {
        return 'product'
      }

      return this.records.entity_slug
    },

    hasAggregations() {
      return this.records.aggregations
    },

    filtersCount() {
      return Object.keys(this.userPreferences.filters).length
    },
    isDownloadableEntity() {
      return ['invoices'].includes(this.entityType)
    },
    areAllRowsChecked() {
      const recordIds = this.records.objectData.map(row => row.id)
      return recordIds.length && recordIds.every(id => this.rowsSelected.includes(id))
    },
    searchPlaceholder() {
      if (this.entityName === 'FormEntry') {
        return this.translate('Search by email', 'global')
      } else if (this.entityType === 'invoices' || this.entityType === 'orders') {
        return this.translate('Search', 'global')
      } else {
        return this.translate('Search by name', 'global')
      }
    },
  },
  methods: {
    can,
    canAny,
    isEntityLocked,
    entityRoute,
    displayValue(row, column_name) {
      // Products
      if (this.entityType === 'inventory' && column_name === 'price') {
        return row[column_name].priceFormatted

      // Orders
      } else if (this.entityType === 'orders' && column_name === 'total_amount') {
        return row['formatted_price_amounts'].total_amount
      }

      return row[column_name]
    },
    isSortableColumn(column){
      const nonSortableColumns = ['images']

      if(this.entityType === 'invoices') {
        nonSortableColumns.push('billing_client_full_name')
      } else if(this.entityType === 'orders') {
        nonSortableColumns.push('billing_client_full_name', 'total_amount', 'payment_method')
      }

      return !nonSortableColumns.includes(column.name)
    },
    openSettings(row) {
      this.settings.id = row.id
      this.settings.entity = this.entityType
      this.$modal.show('settings')
    },
    getRowName(row) {
      if(typeof row.title !== 'undefined'){
        return this.entityLabel(row.title)
      } else if(typeof row.name !== 'undefined'){
        return this.entityLabel(row.name)
      } else if(typeof row.category_name !== 'undefined'){
        return row.category_name
      } else if(typeof row.subject !== 'undefined'){
        return row.subject
      }
      return ''
    },
    // todo change to link instead, it should be possible to open in new tab
    edit(row) {
      if (this.isEntityLocked(row.options)) {
        this.$toast.error({
          title: 'This {entity} is already being edited by another user.',
          translateArgs: { entity: this.entityNameTranslated },
        })
      } else {
        router.push(entityRoute(this.entityType, row.id))
      }
    },
    lockMessage(row) {
      // If locked_by is some id instead of user, it means admin from different company locked it
      const userLocked = Array.isArray(row.locked_by) ? row.locked_by[0] : 'Admin'

      return this.translate('Locked by {user}', 'global', { user: userLocked })
    },

    async unlockRow(row) {
      if (this.isAdmin) {

        const confirmUnlock = await this.$alert.confirm({
          title: 'Are you sure? {entity} will be unlocked for editing!',
          translateArgs: { entity: this.entityNameTranslated },
        })

        if(!confirmUnlock.isConfirmed) return

        const options = { locked_by: null, locked_until: null }

        this.erp.ext.request.axiosInstance
          .put(`${this.baseRoute}/${row.id}`, { options })
          .then(() => this.loadData())
      } else {
        this.$toast.error({
          title: 'This {entity} is already being edited by another user.',
          translateArgs: { entity: this.entityNameTranslated },
        })
      }
    },

    async deleteRow(row) {
      const confirmDelete = await this.$alert.confirmDelete()
      if(!confirmDelete.isConfirmed) return

      this.performAction('delete', row.id)
    },

    performAction(action, id) {
      // Delete
      if(action === 'delete'){
        // Remove cms-website from headers and local storage if we are on the same website
        if (this.baseRoute.includes('cms-websites') && environment.request.defaults.headers.common.cmswebsite === id) {
          delete environment.request.defaults.headers.common.cmswebsite
          localStorage.removeItem('website')
        }

        environment.request.delete(`${this.baseRoute}/${id}`, {}).then(res => {
          this.loadData()
          this.$toast.requestSuccess('delete', this.entityType)
        }).catch(error => {
          this.loadData()
          this.$toast.error(error.message)
        })
      }
    },

    async duplicate(row) {
      await this.duplicateContent(this.entityType === 'inventory' ? row : row.options)
    },
    downloadRow(row) {
      let filename = 'download'
      if(this.entityType === 'invoices') {
        filename = row.invoice_number
      }

      // ex. modules/sales/invoices/download/1
      this.erp.ext.request.axiosInstance.get(`${this.baseRoute}/download/${row.id}`, {
        responseType: 'blob',
        headers: {
          'Accept': 'application/pdf',
        },
      }).then(res => {
        downloadFile(res.data, `${filename}.pdf`)
      })
    },
    async terminateRow(row) {

      const inputValue = await this.$alert.confirmWithInput(row.actions.terminate.confirmation, this.$store.state.builder.entity.objectData.name)
      if(!inputValue.value){
        return
      }

      const method = row.actions.terminate.method.toLowerCase()
      const url = row.actions.terminate.url
      let data = {
        [row.actions.terminate.payload]: inputValue.value,
      }

      await this.erp.ext.request.axiosInstance[method](url, data).then(res => {
        this.$toast.requestSuccess('put', this.entityNameTranslated)
      }).catch(err => {
        if(err.response.data.message) {
          this.$toast.error(err.response.data.message)
        } else {
          this.$toast.errorGeneral()
        }
      })

    },
    selectAll() {
      this.rowsSelected = this.areAllRowsChecked ? [] : this.records.objectData.map(row => row.id)
    },
    bulkEditSelected(v) {
      environment.request.put(`/modules/${this.entityType}/update`, {
        value: v.value,
        filed: v.name,
        ids: this.rowsSelected,
      }).then(res => {
        this.$modal.hide('bulk_edit')
        this.loadData()
      }).catch(res => {
        this.$toast.error(res.message)
      })
    },
    async bulkDelete() {
      const confirmDelete = await this.$alert.confirmDelete()
      if(!confirmDelete.isConfirmed) return

      let itemsToBeDeleted = this.rowsSelected.length
      let itemsDeletedSuccessfully = 0

      for(let i=0; i < this.rowsSelected.length; i++){
        await environment.request.delete(`modules/${this.entityType}/${this.rowsSelected[i]}`, {}).then(res => {
          // this.loadData()
          itemsDeletedSuccessfully += 1
        })
        // .catch(error => {
        // this.loadData()
        // this.$toast.error(error.message)
        // })
      }

      // todo dynamic plural translations
      if(itemsDeletedSuccessfully === itemsToBeDeleted){
        this.$toast.success(this.entityType + ' were deleted successfully!')
      } else if (itemsDeletedSuccessfully === 0) {
        this.$toast.error(this.entityType + ' were NOT deleted successfully!')
      } else if (itemsDeletedSuccessfully > 0){
        this.$toast.error(itemsDeletedSuccessfully + ' ' + this.entityType + ' were deleted successfully! ' + (itemsToBeDeleted - itemsDeletedSuccessfully) + " were NOT deleted successfully")
      }
      this.loadData()
    },
    openEditFields() {
      this.$modal.show('column-fields-edit')
    },

    async onColumnsChange(updatedColumns) {
      this.userPreferences.columns = updatedColumns
      await this.updateUserPreferences()
    },

    clearFilters() {
      this.userPreferences.filters = {}
      this.userPreferences.filterProps = {}
      this.userPreferences.search = ''
      this.updateUserPreferences()

      this.filtersKey++
      this.loadDataDebounced()
    },
    getSortIcon(column) {
      if (this.userPreferences.filterProps.hasOwnProperty('sort')) {
        let col = this.userPreferences.filterProps.sort.filter(item => item.column == column)
        if (isVoidValue(col) || col.length == 0 || isVoidValue(col[0].direction)) return 'fa-sort'
        return col[0].direction === 'asc' ? 'fa-sort-asc align-text-bottom' : 'fa-sort-desc align-text-top'
      }
      return 'fa-sort'
    },
    setEntityName() {
      // Products hot fix - because entity_slug is missing
      if(this.entityType === 'inventory'){
        this.entityName = 'Product'
      } else {
        this.entityName = getEntityName(this.entitySlug)
      }

      this.$emit('updateEntityName', this.entityName)
    },
    aggregationName(aggregation){
      if(aggregation === 'sum'){
        return 'Sum'
      } else if (aggregation === 'avg'){
        return 'Average'
      }
      return ''
    },
    watchFields(fieldsToWatch) {
      fieldsToWatch.forEach(el => {
        this.$watch(
          `dataFilters.${el}.value`, val => {
            this.loadDataDebounced()
          },
        )
      })
    },
  },
  async created(){
    // todo skip loadUserPreferences in datatable-field (it uses dtFilters & dtFilterProps), it is skipped now but needs refactor

    if (this.isDatatableField) {
      this.useUserPreferences = false
    }

    this.$on('dataLoaded', () => {
      this.$emit('updateTotalRecordsCount', this.records.meta.total)
      if(this.columnsFilterable.length > 0){
        this.$emit('showFiltersButton')
      }
    })

    // datatable field
    if(this.isDatatableField) {
      if (this.fieldProps.data_filters && this.fieldProps.data_filters.length) {
        this.watchFields(this.fieldProps.data_filters)
      }
    // else index
    } else {
      await this.loadUserPreferences()
    }

    if(this.dtFilters){
      this.userPreferences.filters = this.dtFilters
    }
    if(this.dtFilterProps){
      this.userPreferences.filterProps = this.dtFilterProps
    }

    await this.loadData()
    this.setEntityName()
    this.$emit('updateEntitySlug', this.entitySlug)
  },
  watch: {
    'entityType': async function() {
      this.filtersKey++
      this.resetData()
      // clearing filters when switch to another entity
      await this.loadUserPreferences()
      await this.loadData()
      this.setEntityName()
      this.$emit('updateEntitySlug', this.entitySlug)
    },
    '$store.state.system.translation_locale': function() {
      if(this.shouldScopeByTranslationLocale){
        this.loadData()
      }
    },
    'userPreferences': {
      deep: true,
      handler(new_value, old_value){
        this.$emit('updateFiltersCount', this.filtersCount)
      },
    },
  },
}
</script>

<style scoped lang="scss">
.data-table-holder {

	.table-header {
		height: 60px;

		.actions .action-item {
			color: $primary;
		}

		.search-field {
			border: none;
			font-size: 16px;

			&:focus {
				background: transparent;
				box-shadow: none;
			}
		}
	}

	.data-table.user-table, .data-table.page-table {

		thead {
			tr {

				th {
					border-bottom: 0;
					color: #000;
					line-height: 1.8rem;
					text-transform: uppercase;
					font-size: .75rem;

					i {
						color: #95aac9;
					}
				}

				td {
					background-color: #fff;

					.avatar {
						vertical-align: middle;
						width: 30px;
						height: 30px;
						border-radius: 50%;
					}
				}
			}
		}

    .row-locked > td {
      background-color: $secondary;
    }

		.pagination {
			.page-item.active {
				.page-link {
					background: $primary-panel-offset-color;
					color: $body-color;
				}
			}

			.page-item {
				.page-link {
					background: $primary-offset-color;
					color: $body-color;
					transition: all 0.2s ease;
					cursor: pointer;
					border: unset;

					&:hover {
						border: unset;
						background: $primary-panel-offset-color;
						transition: all 0.2s ease;
					}
					&:focus, &:active {
						background: $primary-panel-offset-color;
					}
					&:disabled {
						cursor: not-allowed;
					}
				}
			}
		}
	}
}
</style>