<template>
  <div class="cms-block cms-block-search-bar">
    <component-style :value="componentStyle"/>

    <div :id="searchBarId" :style="wrapperStyle">
      <div class="input-group">
        <input
          type="text"
          class="form-control js_auto-suggest"
          :placeholder="getSetting('placeholder')"
          @mousedown.prevent
          @keydown.prevent
        >
        <div class="input-group-append">
          <button class="input-group-text"><i class="icon-erp-search js-filter-icon"></i></button>
        </div>
      </div>

      <div class="js-auto-suggest-area">
        <div class="auto-suggest-container" style="display: none;"></div>
        <div class="as-loader" style="display: none">
          <div class="loader-lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="text-center auto-suggest-no-results" style="display:none;">
          <span>{{ getSetting('no_results_text') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CraftSettingsMixin from "@/modules/pagebuilder/mixins/CraftSettingsMixin"
import ComponentStyle from "@/modules/pagebuilder/components/components/elements/components/ComponentStyle.vue"
import CraftStyleSettingsMixin from "@/modules/pagebuilder/mixins/CraftStyleSettingsMixin"

export default {
  name: "SearchBarElement",
  components: { ComponentStyle },
  mixins: [CraftSettingsMixin, CraftStyleSettingsMixin],
  inject: ['editor', 'node'],
  craft: {
    defaultProps: {
      settings: {
        // General
        placeholder: 'Search...',
        no_results_text: 'No results found',

        // Layout
        type: 'rounded',
        width: '50%',
        alignment: 'left',

        // Styling
        background_color: '',
        button_background_color: '',

        text_color: '',
        icon_color: '',
        placeholder_color: '',

        border_style: '',
        border_color: '',
        border_width: '',
      },
    },
  },
  computed: {
    searchBarId() {
      return `search-${this.node.uuid}`
    },
    wrapperStyle() {
      let margin

      if (this.getSetting('alignment') === 'left') {
        margin = "0 auto 0 0"
      } else if (this.getSetting('alignment') === 'center') {
        margin = "0 auto"
      } else if (this.getSetting('alignment') === 'right') {
        margin = "0 0 0 auto"
      }

      return {
        margin: margin,
      }
    },
    componentStyle() {
      let styles = ''

      // Layout

      if (this.getSetting('width')) {
        styles += `#${this.searchBarId} { width: ${this.getSetting('width')} }`
      }

      // Round type
      if(this.getSetting('type') === 'rounded'){
        styles += `#${this.searchBarId} .input-group {border-radius: 0.25rem;}`
      } else if(this.getSetting('type') === 'square'){
        styles += `#${this.searchBarId} .input-group {border-radius: 0!important}`
      } else if(this.getSetting('type') === 'round'){
        styles += `#${this.searchBarId} .input-group {border-radius: 50rem!important}`
      }

      // Styling

      // Background and colors

      if (this.getSetting('background_color')) {
        styles += `#${this.searchBarId} .input-group > input { background-color: ${this.getSetting('background_color')} }`
      }

      if (this.getSetting('button_background_color')) {
        styles += `#${this.searchBarId} .input-group button { background-color: ${this.getSetting('button_background_color')} }`
      }

      if (this.getSetting('text_color')) {
        styles += `#${this.searchBarId} .input-group > input { color: ${this.getSetting('text_color')} }`
      }

      if (this.getSetting('icon_color')) {
        styles += `#${this.searchBarId} .input-group button > i { color: ${this.getSetting('icon_color')} }`
      }

      if (this.getSetting('placeholder_color')) {
        styles += `#${this.searchBarId} .input-group > input::placeholder { color: ${this.getSetting('placeholder_color')} }`
      }

      // Border styles

      if (this.getSetting('border_style')) {
        styles += `#${this.searchBarId} .input-group { border-style: ${this.getSetting('border_style')} }`
      }

      if (this.getSetting('border_color')) {
        styles += `#${this.searchBarId} .input-group { border-color: ${this.getSetting('border_color')} }`
      }

      if (this.getSetting('border_width')) {
        styles += `#${this.searchBarId} .input-group { border-width: ${this.getSetting('border_width')} }`
      }

      return styles
    },
  },
}
</script>

<style lang="scss">
// Page-builder only, disable input hover behavior
.cms-block-search-bar {
  .input-group {
    cursor: default;
    pointer-events: none;
  }
}
</style>