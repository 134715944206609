<template>
  <div class="form-group">
    <label class="label">{{ translate(label, 'page-builder') }}</label>
    <select-dynamic-field
      v-if="dynamic"
      :field-props="fieldProps"
      :data-filters="dataFilters"
      :value="value"
      @input="e => $emit('input', e)"
    />
    <v-select v-else
              :class="cssClass"
              :options="optionsComputed"
              :clearable="clearable"
              :multiple="multiple"
              :taggable="taggable"
              :value="value"
              :reduce="reducer"
              @input="e => $emit('input', e)"
    >
      <template v-for="(_, name) in $scopedSlots" #[name]="slotData">
        <slot :name="name" v-bind="slotData"/>
      </template>
    </v-select>
  </div>
</template>

<script>

import CraftStyleSettingsMixin from "@/modules/pagebuilder/mixins/CraftStyleSettingsMixin"
import SelectDynamicField from "@/modules/erp_entities/components/page/form/fields/components/SelectDynamicField.vue"

export default {
  name: "CraftSelect",
  inject: ['editor'],
  mixins:[CraftStyleSettingsMixin],
  components: {
    SelectDynamicField,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    cssClass: {
      required: false
    },
    value: {
      required: true
    },
    options: {
      required: true
    },
    clearable: {
      required: false,
      default: false
    },
    multiple: {
      required: false,
      default: false
    },
    taggable: {
      required: false
    },
    reduceData: {
      required: false,
      default: true
    },
    dynamic: {
      required: false,
      default: false,
    },
    fieldProps: {
      required: false,
      default: {},
    },
    dataFilters: {
      type: Object,
      required: false,
    },
  },
  computed: {
    optionsComputed() {
      return this.options.map(obj => {
        return {
          key: obj.key,
          label: this.translate(obj.label, 'page-builder'),
        }
      })
    },
  },
  methods: {
    reducer(value) {
      if(this.reduceData){
        return value.key
      }
      return value
    },
  }
}
</script>

