import Agenda from '@/modules/rock/views/Agenda/Agenda.vue'
import DataManagement from '@/modules/rock/views/dataManagement/DataManagement.vue'
import Invoices from '@/modules/rock/views/invoices/Invoices.vue'

export default [
  {
    path: "/rock/agenda",
    name: "agenda",
    component: Agenda,
    meta: {
      interface: "rock",
    },
  },
  {
    path: "/data-management",
    name: "data-management",
    component: DataManagement,
    meta: {
      interface: "rock",
    },
  },
  {
    path: "/rock-invoices",
    name: "rock-invoices",
    component: Invoices,
    meta: {
      interface: "rock",
    },
  },
]