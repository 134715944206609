<template>
  <div class="form-group">
    <label class="label">{{ translate(label, 'page-builder') }}</label>
    <ee-form-color-picker
      :value="value"
      @input="color => setColor(color)"
    />
  </div>
</template>

<script>
import CraftStyleSettingsMixin from "@/modules/pagebuilder/mixins/CraftStyleSettingsMixin";
import EeFormColorPicker from "@/modules/erp_entities/components/forms/FormColorPicker";

export default {
  name: "CraftColorPicker",
  mixins: [CraftStyleSettingsMixin],
  inject: ["editor"],
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      default: "Color Picker",
    },
    selector: {
      type: String,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EeFormColorPicker,
  },
  methods: {
    setColor(style){
      this.value = style
      this.$emit('input', style)
    }
  },
};
</script>